import * as React from 'react'
import autobind from 'autobind-decorator'
import {Col, Row, FormGroup, Label, Input} from 'reactstrap'
import {observer, inject} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from "../../state/ui"


@inject('appState')
@inject('uiState')
@observer
export class FlightDetailsFilters extends React.Component<{appState?: AppState, uiState?: UIState}> {
    @autobind
    onClaimActivityChange () {
        let isActiveOnly = this.props.appState.flightDetailsStore.filters.$.is_active_only.value
        this.props.appState.flightDetailsStore.filters.$.is_active_only.onChange(!isActiveOnly)
        this.props.appState.flightDetailsStore.load(this.props.appState.flightDetailsStore.flight.id)
    }

    render () {
        let form = this.props.appState.flightDetailsStore.filters.$

        return <div>
            <Row>
                <Col>
                    <FormGroup className="mb-3" check>
                        <Label check>
                            <Input 
                                type="checkbox"
                                name="is_active_only"
                                checked={form.is_active_only.value}
                                onChange={this.onClaimActivityChange}
                            />{' '}
                            Active only
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    }
}

import autobind from 'autobind-decorator'
import {observable, when} from "mobx"
import {ApiClient} from "../api/client";
import {Entity} from "./entities/Entity";
import AuthStore from './auth'
import VoiceCallStore from './voice-call'
import {PaymentListStore, PaymentStatusCountStore} from './payment'
import {TemplateStore} from './templates'
import {FlightDetailsStore, FlightListStore} from './flights'
import {EmailComposerStore, MessageListStore} from './email'
import {ClaimDetailsStore} from './claim-details'
import {ClaimListStore} from './claim-list'
import {AirlineListStore} from './airline-list'
import {AirlineDetailsStore} from './airline-details'
import {SearchState} from './search'
import {User} from './entities/User'


export class Document extends Entity {
    content_type: string
    name: string
    size: number
    content_url: string
}


export enum ActionType {
    REVIEW_NEW_CASE = 0,
    PREPARE_DEMAND_LETTER = 1,
    SEND_DEMAND_LETTER = 2,
    WAIT_DEMAND_LETTER_RESPONSE = 3,
    PREPARE_CLIENT_AGREEMENT = 4,
    SEND_CLIENT_AGREEMENT = 5,
    WAIT_CLIENT_AGREEMENT_SIGNATURE = 6,
    PROCESS_AIRLINE_EMAIL = 7,
    CHECK_FLIGHT_DATA = 8,
    SEND_EMAIL_TO_AIRLINE = 9,
    COMMENT = 10,
    PAYMENT_DETAILS_REQUEST = 12,
    REQUEST_CLAIM_DOCUMENT_SIGNATURE = 13,
    MANUAL_STATUS_CHANGE = 14,
    MANUAL_COMPENSATION_CHANGE = 16,
    CLOSE_CLAIM = 100,
}

export enum TaskStatus {
    PENDING = 0,
    IN_PROGRESS = 1,
    DONE = 2,
    CANCELLED = 3,
}


export enum AirlineEmailContentClass {
    IRRELEVANT = 'irrelevant',
    CLAIM_ACCEPTED = 'claim_accepted',
    CLAIM_REJECTED = 'claim_rejected',
    CLAIM_INVALID = 'claim_invalid',
    DIRECT_PAYOUT = 'direct_payout',
}


@autobind
class ClaimStatusCountStore {
    apiClient: ApiClient
    parent: AppState

    delayedLoadPending = false

    @observable flagged: number = 0
    @observable active: number = 0
    @observable userActive: number = 0
    @observable onHold: number = 0
    @observable partialClaim: number = 0

    @observable unsigned: number = 0
    @observable toReview: number = 0
    @observable reviewAccepted: number = 0
    @observable prepareComplete: number = 0
    @observable claimSubmitted: number = 0
    @observable decisionDue: number = 0
    @observable claimRejected: number = 0
    @observable claimAccepted: number = 0
    @observable paymentDue: number = 0
    @observable compReceived: number = 0
    @observable directPayout: number = 0

    @observable prepareAbandoned: number = 0
    @observable reviewRejected: number = 0
    @observable claimInvalid: number = 0
    @observable compPaid: number = 0
    @observable manuallyClosed: number = 0
    
    @observable nebNew: number = 0
    @observable nebSubmitted: number = 0

    @observable courtQualified: number = 0
    @observable courtNew: number = 0
    @observable courtSubmitted: number = 0

    @observable unreadClient: number = 0
    @observable unreadClientGroup1: number = 0
    @observable unreadClientGroup2: number = 0
    @observable unreadClientGroup3: number = 0
    @observable unreadAirline: number = 0
    @observable unreadRegulator: number = 0

    constructor (parent: AppState, apiClient: ApiClient) {
        this.parent = parent
        this.apiClient = apiClient
    }

    async reload() {
        if (!this.parent.auth.statusKnown || !this.parent.auth.status) {
            if (this.delayedLoadPending) {
                return
            } else {
                console.log("Delaying status count loading")
                when(
                    () => this.parent.auth.statusKnown && this.parent.auth.status,
                    () => {this.delayedLoadPending = false; return this.reload()}
                )
            }
        }

        let data = (
            await this.apiClient.makeCall('GET', `/claims/status_counts`)
        ).data

        this.flagged = data['flagged']
        this.onHold = data['on_hold']
        this.active = data['active']
        this.userActive = data['user_active']
        this.unsigned = data['unsigned']
        this.partialClaim = data['partial_claim']

        this.toReview = data['to_review']
        this.reviewAccepted = data['review_accepted']
        this.prepareComplete = data['prepare_complete']
        this.claimSubmitted = data['claim_submitted']
        this.decisionDue = data['decision_due']
        this.claimRejected = data['claim_rejected']
        this.claimAccepted = data['claim_accepted']
        this.paymentDue = data['payment_due']
        this.compReceived = data['comp_received']
        this.directPayout = data['direct_payout']

        this.prepareAbandoned = data['prepare_abandoned']
        this.reviewRejected = data['review_rejected']
        this.claimInvalid = data['claim_invalid']
        this.compPaid = data['comp_paid']
        this.manuallyClosed = data['manually_closed']
        
        this.nebNew = data['neb_new']
        this.nebSubmitted = data['neb_submitted']

        this.courtQualified = data['court_qualified']
        this.courtNew = data['court_new']
        this.courtSubmitted = data['court_submitted']

        this.unreadClient = data['unread_client']
        this.unreadClientGroup1 = data['unread_client_group_1']
        this.unreadClientGroup2 = data['unread_client_group_2']
        this.unreadClientGroup3 = data['unread_client_group_3']
        this.unreadAirline = data['unread_airline']
        this.unreadRegulator = data['unread_regulator']
    }
}


export interface TaskAction {
    action: string
    label: string
}

type TaskNotice = [string, string]

export class Action extends Entity {
    created_at: Date
    updated_at: Date
    finished_at: Date
    due_at: Date
    assignee: User | null
    type: ActionType
    status: TaskStatus
    case: number
    actions: TaskAction[]
    notices: TaskNotice[]

    constructor (data) {
        super(data)

        this.parseDates(data, ['created_at', 'updated_at', 'finished_at', 'due_at'])
    }
}


export enum ReviewNewClaimResult {
    PENDING = 0,
    VALID = 1,
    INVALID = 2,
}

export class ReviewNewClaimDetailsTask extends Action {
    result: ReviewNewClaimResult
}


export class CloseClaimTask extends Action {
    comment: string
}


export class PrepareClaimLetterTask extends Action {
    document: Document
    printable_document: Document
    severity: number
}

export class DeliverClaimNoticeTask extends Action {
    severity: number
    method: string
}


export class ProcessAirlineEmailTask extends Action {
    view_url: string
    content_class: AirlineEmailContentClass|null
}


export class SendEmailToAirline extends Action {
    view_url: string
}


export class FlightCheck {
    schedule_check_done: boolean
    get_flight_id_done: boolean
    get_flight_details_done: boolean
    schedule_found: boolean
    flight_found: boolean
    cancelled: boolean
    departure_delay: number
    arrival_delay: number
}


export class FlightDataCheckTask extends Action {
    check: FlightCheck
}


class TaskStore {
    apiClient
    parent: AppState
    @observable tasks: Action[]

    constructor (parent: AppState, apiClient: ApiClient) {
        this.tasks = []
        this.apiClient = apiClient
        this.parent = parent
    }

    async load () {
        console.log("Loading tasks")
        this.tasks = (await this.apiClient.makeCall('GET', '/tasks')).data
        console.log("Loaded tasks")
    }

    async perform (task, action, params=null) {
        console.log("Performing task", task.id)
        let response = await this.apiClient.makeCall(
            'POST',
            `/tasks/${task.id}/perform`,
            {data: {action, params}}
        )
        task.update(response.data)
        this.parent.claimStatusCounts.reload()

        console.log("Performed action", action, "on task", task.id, "with params", params)
    }
}


export class AppState {
    apiClient: ApiClient
    auth: AuthStore
    claimListStore: ClaimListStore
    claimDetailsStore: ClaimDetailsStore
    claimStatusCounts: ClaimStatusCountStore
    taskStore: TaskStore
    messageListStore: MessageListStore
    emailComposerStore: EmailComposerStore
    voiceCallStore: VoiceCallStore
    paymentListStore: PaymentListStore
    paymentStatusCounts: PaymentStatusCountStore
    flightListStore: FlightListStore
    flightDetailsStore: FlightDetailsStore
    templateStore: TemplateStore
    airlineListStore: AirlineListStore
    airlineDetailsStore: AirlineDetailsStore
    searchState: SearchState

    constructor(apiClient) {
        this.apiClient = apiClient
        this.auth = new AuthStore(apiClient)
        this.claimListStore = new ClaimListStore(this, apiClient)
        this.taskStore = new TaskStore(this, apiClient)
        this.claimDetailsStore = new ClaimDetailsStore(this, apiClient)
        this.claimStatusCounts = new ClaimStatusCountStore(this, apiClient)
        this.messageListStore = new MessageListStore(this, apiClient)
        this.emailComposerStore = new EmailComposerStore(apiClient)
        this.voiceCallStore = new VoiceCallStore(apiClient)
        this.paymentListStore = new PaymentListStore(this, apiClient)
        this.paymentStatusCounts = new PaymentStatusCountStore(this.apiClient)
        this.flightListStore = new FlightListStore(this.apiClient)
        this.flightDetailsStore = new FlightDetailsStore(this.apiClient)
        this.templateStore = new TemplateStore(this.apiClient)
        this.airlineListStore = new AirlineListStore(this, apiClient)
        this.airlineDetailsStore = new AirlineDetailsStore(this, apiClient)
        this.searchState = new SearchState(this, apiClient)

        this.init()
    }

    init () {
        this.auth.init()
    }
}

import {Entity} from './Entity'
import {Document, Action} from "../app";
import {Airline} from "./Airline";
import {observable, transaction} from "mobx";
import {Payment, PaymentDetailsRequest} from './Payment'
import {User} from './User'
import {Airport} from './Airport'
import {Flight} from './Flight'
import {Party} from './Common'
import {Passenger} from './Passenger'
import {TripSegment} from './TripSegment'


class ClaimAction {
    action: string
    label: string
}


export enum ClaimTasks {
    MY_ACTIVE = 'user_active',
    ALL_ACTIVE = 'active',
    UPCOMING = 'on_hold',
    PARTIAL_CLAIM = 'partial_claim',
}

export const CLAIM_TASKS_LABELS = {
    [ClaimTasks.MY_ACTIVE]: "My Active",
    [ClaimTasks.ALL_ACTIVE]: "All Active",
    [ClaimTasks.UPCOMING]: "Upcoming",
    [ClaimTasks.PARTIAL_CLAIM]: "Partial"
}


export enum NewClaims {
    UNSIGNED = 'unsigned',
    TO_REVIEW = 'to_review',
    ACCEPTED = 'review_accepted'
}

export const NEW_CLAIMS_LABELS = {
    [NewClaims.UNSIGNED]: "Unsigned",
    [NewClaims.TO_REVIEW]: "To Review",
    [NewClaims.ACCEPTED]: "Accepted"
}


export enum FilledClaims {
    SUBMITTED = 'claim_submitted',
    DECISION_DUE = 'decision_due',
    REJECTED = 'claim_rejected',
    APPROVED = 'claim_accepted',
    PAYMENT_DUE = 'payment_due',
    COMP_RECEIVED = 'comp_received',
}

export const FILLED_CLAIMS_LABELS = {
    [FilledClaims.SUBMITTED]: "Submitted",
    [FilledClaims.DECISION_DUE]: "Decision due",
    [FilledClaims.REJECTED]: "Rejected",
    [FilledClaims.APPROVED]: "Approved",
    [FilledClaims.PAYMENT_DUE]: "Payment due",
    [FilledClaims.COMP_RECEIVED]: "Comp received",
}


export enum MessageClaims {
    UNREAD_CLIENT = 'unread_client',
    UNREAD_CLIENT_GROUP_1 = 'unread_client_group_1',
    UNREAD_CLIENT_GROUP_2 = 'unread_client_group_2',
    UNREAD_CLIENT_GROUP_3 = 'unread_client_group_3',
    UNREAD_AIRLINE = 'unread_airline',
    UNREAD_REGULATOR = 'unread_regulator'
}

export const MESSAGE_CLAIMS_LABELS = {
    [MessageClaims.UNREAD_CLIENT]: "Clients",
    [MessageClaims.UNREAD_CLIENT_GROUP_1]: "Clients - Group #1 (Approved, Payment due, Comp received, Direct payout)",
    [MessageClaims.UNREAD_CLIENT_GROUP_2]: "Clients - Group #2 (Submitted, Decision due, Rejected)",
    [MessageClaims.UNREAD_CLIENT_GROUP_3]: "Clients - Group #3 (Unsigned, To Review, Partial)",
    [MessageClaims.UNREAD_AIRLINE]: "Airlines",
    [MessageClaims.UNREAD_REGULATOR]: "NEBs"
}


export enum ClaimStatus {
    PARTIAL_CLAIM = 'partial_claim',

    PREPARE_UNSIGNED = 'prepare_unsigned',
    PREPARE_NEW = 'prepare_new',
    PREPARE_ACCEPTED = 'prepare_accepted',
    PREPARE_REJECTED = 'prepare_rejected',
    PREPARE_ABANDONED = 'prepare_abandoned',
    PREPARE_COMPLETE = 'prepare_complete',

    CLAIM_SUBMITTED = 'claim_submitted',
    CLAIM_ACCEPTED = 'claim_accepted',
    CLAIM_REJECTED = 'claim_rejected',
    CLAIM_INVALID = 'claim_invalid',

    NEB_NEW = 'neb_new',
    NEB_SUBMITTED = 'neb_submitted',

    COURT_NEW = 'court_new',
    COURT_SUBMITTED = 'court_submitted',

    SETTLE_PAYMENT_RECEIVED = 'settle_payment_received',
    SETTLE_PAYOUT_READY = 'settle_payout_ready',
    SETTLE_PAID_OUT = 'settle_paid_out',
    SETTLE_DIRECT_PAYOUT = 'settle_direct_payout',
    SETTLE_FEE_REQUESTED = 'settle_fee_requested',
    SETTLE_FEE_RECEIVED = 'settle_fee_received',

    MISC_MANUALLY_CLOSED = 'manually_closed'
}

export const STATUS_LABELS = {
    [ClaimStatus.PARTIAL_CLAIM]: "Partial claim",

    [ClaimStatus.PREPARE_UNSIGNED]: "Unsigned",
    [ClaimStatus.PREPARE_NEW]: "To Review",
    [ClaimStatus.PREPARE_ACCEPTED]: "Accepted",
    [ClaimStatus.PREPARE_REJECTED]: "Rejected",
    [ClaimStatus.PREPARE_ABANDONED]: "Abandoned",
    [ClaimStatus.PREPARE_COMPLETE]: "Ready to submit",

    [ClaimStatus.CLAIM_SUBMITTED]: "Submitted",
    [ClaimStatus.CLAIM_ACCEPTED]: "Approved",
    [ClaimStatus.CLAIM_REJECTED]: "Rejected",
    [ClaimStatus.CLAIM_INVALID]: "Invalid",

    [ClaimStatus.NEB_NEW]: "Submit to NEB",
    [ClaimStatus.NEB_SUBMITTED]: "Submitted to NEB",

    [ClaimStatus.COURT_NEW]: "Submit to court",
    [ClaimStatus.COURT_SUBMITTED]: "Submitted to court",

    [ClaimStatus.SETTLE_PAYMENT_RECEIVED]: "Comp received",
    [ClaimStatus.SETTLE_PAYOUT_READY]: "Comp payout ready",
    [ClaimStatus.SETTLE_PAID_OUT]: "Comp paid",
    [ClaimStatus.SETTLE_DIRECT_PAYOUT]: 'Direct airline payout',
    [ClaimStatus.SETTLE_FEE_REQUESTED]: 'Service fee requested',
    [ClaimStatus.SETTLE_FEE_RECEIVED]: 'Service fee received',

    [ClaimStatus.MISC_MANUALLY_CLOSED]: "Manual close"
}


export enum ClaimDocumentType {
    UNSPECIFIED = 'unspecified',
    CLIENT_AGREEMENT_ORIGINAL = 'client_agreement_original',
    CLIENT_AGREEMENT_SIGNED = 'client_agreement_signed',
    POA_ORIGINAL = 'poa_original',
    POA_SIGNED = 'poa_signed',
    ASSIGNMENT_DEED_ORIGINAL = 'assignment_deed_original',
    ASSIGNMENT_DEED_SIGNED = 'assignment_deed_signed',
    HS_SIGNATURE_PAGE = 'hs_signature_page',
    HS_AUDIT_TRAIL = 'hs_audit_trail',
    CLAIM_NOTICE = 'claim_notice',
    CLAIM_NOTICE_PRINT = 'claim_notice_print',
}

export const CLAIM_DOCUMENT_TYPE_LABELS = {
    [ClaimDocumentType.UNSPECIFIED] : 'Unspecified',
    [ClaimDocumentType.CLIENT_AGREEMENT_ORIGINAL] : 'Client agreement original',
    [ClaimDocumentType.CLIENT_AGREEMENT_SIGNED] : 'Client agreement signed',
    [ClaimDocumentType.POA_ORIGINAL] : 'Original PoA',
    [ClaimDocumentType.POA_SIGNED] : 'Signed PoA',
    [ClaimDocumentType.ASSIGNMENT_DEED_ORIGINAL] : 'Original assignment deed',
    [ClaimDocumentType.ASSIGNMENT_DEED_SIGNED] : 'Signed assignment deed',
    [ClaimDocumentType.HS_SIGNATURE_PAGE] : 'HelloSign signature page',
    [ClaimDocumentType.HS_AUDIT_TRAIL] : 'HelloSign audit trail',
    [ClaimDocumentType.CLAIM_NOTICE] : 'Claim notice, electronic delivery',
    [ClaimDocumentType.CLAIM_NOTICE_PRINT] : 'Claim notice, paper delivery',
}


export class ClaimDocument extends Entity {
    document: Document
    @observable type: string
    @observable attach: boolean
}


export class PassengerAddress extends Entity {
    address1: string
    address2?: string
    city: string
    postal_code: string
    state?: string
    country: string
}


export class Claim extends Entity {
    case_number: string
    case_id_human: string
    created_at: Date
    @observable status_detail: ClaimStatus
    @observable activate_at: Date | null
    @observable activate_memo: string | null

    @observable assignee: User | null

    @observable submitter: Passenger
    actions: ClaimAction[]

    email: string
    phone_number: string | null

    @observable flagged: boolean
    flagged_comment: string

    @observable trip_segments: TripSegment[]
    @observable airline: Airline
    @observable flight_number: string
    @observable departure_time: Date
    @observable booking_number: string
    comment: string

    flight_issue_label: string
    stated_cause_label: string
    problem_flight: number
    flight_cancellation_notification_label: string | null
    arrival_delay: number | null

    distance: number
    passenger_claim_amount: number
    total_claim_amount: number
    fee_fraction: number
    service_charge: number
    pending_task_count: number

    pending_tasks: Action[]
    done_tasks: Action[]

    passenger_address?: PassengerAddress

    @observable passengers: Passenger[]
    @observable documents: ClaimDocument[]
    @observable payments: Payment[]
    @observable payment_details_requests: PaymentDetailsRequest[]

    is_signed: boolean
    is_accepted: boolean
    is_delivered: boolean
    has_unread_messages: boolean
    has_payment_details: boolean
    is_airline_comp_received: boolean
    is_client_comp_transferred: boolean
    is_auto_amount: boolean
    
    language: string

    @observable unread_counts: {
        [Party.AIRLINE]: 0,
        [Party.CLIENT]: 0,
        [Party.REGULATOR]: 0,
    }

    constructor (data) {
        super(data)

        this.parseDates(data, ['created_at', 'departure_time', 'activate_at'])

        if (this.pending_tasks) {
            this.pending_tasks = this.pending_tasks.map((t) => new Action(t))
        }

        if (this.done_tasks) {
            this.done_tasks = this.done_tasks.map((t) => new Action(t))
        }

        this.trip_segments = (this.trip_segments || []).map((ts) => new TripSegment(ts))

        this.passengers = (this.passengers || []).map((p) => new Passenger(p))
        this.connectGuardiansWards()
    }

    get statusLabel () {
        return STATUS_LABELS[this.status_detail]
    }

    get departureAirport () : Airport {
        return this.trip_segments[0].flight.departure_airport
    }

    get arrivalAirport () : Airport {
        return this.trip_segments[this.trip_segments.length-1].flight.arrival_airport
    }

    connectGuardiansWards () {
        let paxMap = {}
        for (let p of this.passengers) {
            paxMap[p.id] = p
            p.wards = []
        }

        for (let p of this.passengers) {
            if (p.guardian) {
                paxMap[p.guardian].wards.push(p)
            }
        }
    }
}

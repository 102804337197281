import * as React from 'react'
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import * as moment from 'moment'
import {Row, Col} from 'reactstrap'
import {NavLink} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperclip, faRobot} from '@fortawesome/free-solid-svg-icons'

import {AppState} from "../../state/app";
import {UIState} from "../../state/ui";
import {EmailMessage} from "../../state/entities/EmailMessage"
import {ComposeEmailModal} from "./ComposeEmail"

import './Messaging.scss'
import {ClassifyEmailContentModal} from './ClassifyEmailContentModal'
import {Party} from '../../state/entities/Common'
import {EmailMessageView} from './EmailMessageView'


@inject('appState')
@observer
class MessageListEntry extends React.Component
<{appState?: AppState, message: EmailMessage, baseUrl: string, active:boolean}>
{
    render () {
        let m = this.props.message
        let store = this.props.appState.messageListStore
        let classNames = ['message-list-entry']
        if (this.props.active) {
            classNames.push('active')
        }
        if (!m.read || m.draft) {
            classNames.push('unread')
        }

        return <NavLink
            to={`${this.props.baseUrl}/${this.props.message.id}`}
            className={classNames.join(' ')}
        >
            {this.directionIndicator()} {" "}
            {moment.utc(m.created_at).format('DD.MM.YYYY HH:mm')}
            {" "} {this.autoIndicator()} {" "}
            {" "} {this.attachmentIndicator()} {" "}
            {m.draft ? " [draft]" : null}
        </NavLink>
    }

    directionIndicator () {
        if (this.props.message.from_party == Party.SERVICE) {
            return "→"
        } else if (this.props.message.to_party == Party.SERVICE) {
            return "←"
        } else {
            return "?"
        }
    }

    autoIndicator () {
        if (this.props.message.auto_sent) {
            return <FontAwesomeIcon icon={faRobot}/>
        }

        return null
    }

    attachmentIndicator () {
        if (this.props.message.attachments && this.props.message.attachments.length) {
            return <FontAwesomeIcon icon={faPaperclip}/>
        }

        return null
    }

}


@inject('appState', 'uiState')
@observer
class MessageDetails extends React.Component
<{appState?: AppState, uiState?: UIState, message: EmailMessage, claimId: string}>
{
    render () {
        let message = this.props.message
        let claimId = this.props.claimId

        return <div className="message-details">
            <div className="actions">
                { message.to_party == Party.SERVICE ?
                    <>
                        <a href="#" onClick={this.onMarkReadClick}>
                            {message.read ? "Mark unread" : "Mark read"}
                        </a> {" "}

                        { this.renderClassifyLink() }

                        <a href="#" onClick={this.onReplyClick}>Reply</a>
                    </>
                    :
                    null
                }

                { message.draft ?
                    <>
                        <a href="#" onClick={this.onEditDraftClick}>Edit draft</a> {" "}
                        {/*<a href="#" onClick={this.onDiscardDraftClick}>Discard draft</a> {" "}*/}
                        <a href="#" onClick={this.onSendDraftClick}>Send</a>
                    </>
                    :
                    null
                }

                <a
                    href={`/admin-api/claims/${claimId}/messages/${message.to_party}/${message.id}?format=pdf`}
                    target="_blank"
                >
                    PDF export
                </a>

                { message.to_party == Party.SERVICE
                    ? <a href="#" onClick={this.onForwardClick}>Forward</a>
                    : null
                }
            </div>

            <EmailMessageView message={message}/>
        </div>
    }

    renderClassifyLink () {
        let message = this.props.message

        if (message.from_party == Party.AIRLINE) {
            if (message.content_class) {
                return <a>Classify</a>
            } else {
                return <a href="#" onClick={this.onClassifyClick}>Classify</a>
            }
        }
    }

    @autobind
    onClassifyClick (e) {
        e.preventDefault()

        this.props.uiState.showModal(
            <ClassifyEmailContentModal
                close={this.props.uiState.closeModal}
                message={this.props.message}
            />
        )
    }

    @autobind
    async onMarkReadClick (e) {
        e.preventDefault()
        await this.props.appState.messageListStore.toggleRead(this.props.message)
    }

    @autobind
    onReplyClick (e) {
        e.preventDefault()

        this.props.appState.templateStore.load()

        this.props.appState.emailComposerStore.initReply(
            this.props.appState.claimDetailsStore.claim,
            this.props.message
        )

        this.props.uiState.showModal(<ComposeEmailModal/>)
    }

    @autobind
    onForwardClick (e) {
        e.preventDefault()

        this.props.appState.templateStore.load()

        this.props.appState.emailComposerStore.initForward(
            this.props.appState.claimDetailsStore.claim,
            this.props.message
        )

        this.props.uiState.showModal(<ComposeEmailModal/>)
    }

    @autobind
    async onEditDraftClick (e) {
        e.preventDefault()

        this.props.appState.templateStore.load()

        // TODO: The store -> Draft JS editor sync is not very good, so we need to wait for the
        // content to be available before letting the editor mount. This will probably
        // cause a noticable delay when clicking "edit draft", might want to fix the sync sometime.
        await this.props.appState.emailComposerStore.loadDraft(
            this.props.appState.claimDetailsStore.claim,
            this.props.message.id
        )

        this.props.uiState.showModal(<ComposeEmailModal/>)
    }

    @autobind
    async onSendDraftClick (e) {
        e.preventDefault()

        await this.props.appState.emailComposerStore.send(this.props.message.id)
        await this.props.appState.messageListStore.reload()
    }

    @autobind
    onDiscardDraftClick (e) {
        e.preventDefault()
    }
}


@inject('appState', 'uiState')
@observer
export class Messaging extends React.Component
<{appState?: AppState, uiState?: UIState, claimId: string, party: Party, messageId: string}>
{
    componentDidMount () {
        this.reloadList()
    }

    componentDidUpdate (prevProps) {
        if (prevProps.claimId != this.props.claimId || prevProps.party != this.props.party) {
            this.reloadList()
        }
    }

    @autobind
    reloadList () {
        this.props.appState.messageListStore.load(this.props.claimId, this.props.party)
    }

    render () {
        const msgListStore = this.props.appState.messageListStore
        let messages = msgListStore.messages
        let activeMessage = null
        if (this.props.messageId) {
            activeMessage = msgListStore.getMessageById(this.props.messageId)
        }

        return <div>
            <Row className="messaging">
                <Col xs="3" className="message-list">
                    <a href="#" onClick={this.onComposeClick}>+ New message</a>
                    {" "}
                    <a
                        href={`/admin-api/claims/${this.props.claimId}/messages/${this.props.party}?format=pdf`}
                        target="_blank"
                    >
                        ⌄ PDF export
                    </a>

                    {
                        messages.map(
                            (m) => <MessageListEntry
                                key={m.id}
                                message={m}
                                active={m.id == Number(this.props.messageId)}
                                baseUrl={`/claims/${this.props.claimId}/${this.props.party}-email`}
                            />
                        )
                    }
                </Col>

                <Col xs="9" className="message-pane">
                    {
                       activeMessage ?
                           <MessageDetails
                                message={activeMessage}
                                claimId={this.props.claimId}
                            /> : "Select a message"
                    }
                </Col>
            </Row>
        </div>
    }

    @autobind
    onComposeClick (e) {
        e.preventDefault()

        this.props.appState.templateStore.load()

        this.props.appState.emailComposerStore.newMessage(
            this.props.appState.claimDetailsStore.claim,
            this.props.party
        )

        this.props.uiState.showModal(<ComposeEmailModal/>)
    }
}
import autobind from 'autobind-decorator'
import {inject, observer} from 'mobx-react'
import * as React from 'react'
import * as moment from "moment";
import {UIState} from "../../state/ui";
import {AppState} from "../../state/app";

import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap'

import {CompositeDecorator, convertFromRaw, convertToRaw, Editor, EditorState,} from 'draft-js'

import DocumentLink from "../DocumentLink";

import './ComposeEmail.scss'
import {EmailAttachment} from "../../state/entities/EmailMessage";
import {Template} from '../../state/entities/Template'
import {generateBlockKey, TEMPLATE_VAR_REGEX} from '../templates/TemplateEditor'
import {Party} from '../../state/entities/Common'


function extractTemplateVars (text:string) {
    let vars = []
    let matchArr, start
    while ((matchArr = TEMPLATE_VAR_REGEX.exec(text)) !== null) {
        start = matchArr.index
        vars.push(
            // get var name, strip curly braces
            text.substr(matchArr.index+1, matchArr[0].length-2)
        )
    }

    return Array.from(new Set(vars))
}

function textToEditorState (text: string, decorator) {
    let blocks = text.split('\n').map(
        (blockText) => {
            return {
                text: blockText,
                key: generateBlockKey(),
                type: 'paragraph' as 'paragraph',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: []
            }
        }
    )

    return EditorState.createWithContent(
        convertFromRaw({blocks, entityMap: {}}),
        decorator
    )
}

@inject('appState', 'uiState')
@observer
export class ComposeEmail extends React.Component
<{appState?: AppState, uiState?: UIState}>
{
    state = {
        showClaimDocuments: false,
        bodyEditorState: EditorState.createEmpty(),
        selectedTemplate: null as Template,
        templateValues: null,
        editorKey: 0,
    }

    constructor (props) {
        super(props)

        // Required for draft content to show
        this.state.bodyEditorState = textToEditorState(
            props.appState.emailComposerStore.formState.$.body.value,
            null
        )
    }

    render () {
        let store = this.props.appState.emailComposerStore
        let templateStore = this.props.appState.templateStore
        let fields = store.formState.$

        return <Form className="compose-email">
            <FormGroup>
                <InputGroup>
                    <Input
                        type="select"
                        disabled={templateStore.templates.length == 0}
                        onChange={this.onTemplateSelectionChange}
                    >
                        { !this.state.selectedTemplate ?
                            <option>Select a template ...</option>
                            :
                            null
                        }
                        { templateStore.templates.map(
                            t => <option value={t.id} key={t.id}>{t.name}</option>)
                        }
                    </Input>

                    <Button className="ml-3" onClick={this.onLoadTemplate} disabled={!this.state.selectedTemplate}>
                        Use template
                    </Button>
                </InputGroup>
            </FormGroup>

            { this.state.templateValues && Object.keys(this.state.templateValues).length > 0 ?
                <>
                    <h3>Template values</h3>
                    {
                        Object.keys(this.state.templateValues).map(i =>
                            <FormGroup key={i}>
                                <Label>{i}</Label>
                                <Input
                                    value={this.state.templateValues[i]}
                                    onChange={(e) => this.onTemplateValueChange(i, e.target.value)}
                                />
                            </FormGroup>
                        )
                    }
                </>
                :
                null
            }

            <FormGroup row>
                <Label sm={1}>To:</Label> {" "}
                <Col sm={11}>
                    <Input
                        value={fields.toAddress.value || ''}
                        onChange={(e) => fields.toAddress.onChange(e.target.value)}
                        onBlur={fields.toAddress.validate}
                        invalid={!!fields.toAddress.error}
                    />
                    <FormFeedback>{fields.toAddress.error}</FormFeedback>
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={1}>Cc:</Label> {" "}
                <Col sm={11}>
                    <Input
                        value={fields.ccAddress.value || ''}
                        onChange={(e) => fields.ccAddress.onChange(e.target.value)}
                        onBlur={fields.ccAddress.validate}
                        invalid={!!fields.ccAddress.error}
                    />
                    <FormFeedback>{fields.ccAddress.error}</FormFeedback>
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={1}>Subject:</Label>
                <Col sm={11}>
                    <Input
                        value={fields.subject.value}
                        onChange={(e) => fields.subject.onChange(e.target.value)}
                        onBlur={fields.subject.validate}
                        invalid={!!fields.subject.error}
                    />
                    <FormFeedback>{fields.subject.error}</FormFeedback>
                </Col>
            </FormGroup>

            <FormGroup>
                <Label>Message:</Label>
                <div className={`form-control content-input ${fields.body.error ? 'is-invalid' : ''}`}>
                    <Editor
                        key={this.state.editorKey}
                        editorState={this.state.bodyEditorState}
                        onChange={this.onBodyChange}
                        onBlur={fields.body.validate}
                    />
                </div>
                <FormFeedback>{fields.subject.error}</FormFeedback>
            </FormGroup>

            { store.replyToMessage ?
                <FormGroup>
                    <Label>Previous message:</Label>
                    <Input
                        type="textarea"
                        rows={5}
                        disabled={true}
                        value={store.replyToMessage.last_reply_content}
                    />
                </FormGroup>
                :
                null
            }

            <div>
                <Label>Attachments:</Label>
                <Card>
                    <CardBody>
                        {store.attachments.length > 0 ? <AttachmentList/> : "No attachments"}
                    </CardBody>
                    <CardBody className="border-top">
                        <a href="#" onClick={this.onToggleClaimDocuments}>
                            {this.state.showClaimDocuments ? "Hide" : "Show" } claim documents
                        </a>
                        { this.state.showClaimDocuments ? <ClaimDocumentList/> : null }
                    </CardBody>
                </Card>

            </div>

            <FormGroup className="float-right mt-3">
                <Button color="secondary" onClick={this.onSaveDraft}>Save draft</Button>
                <Button
                    color="primary"
                    className="ml-1"
                    onClick={this.onSend}
                    disabled={store.formState.hasError || store.loading}
                >Send</Button>
            </FormGroup>

        </Form>
    }

    @autobind
    onSaveDraft (e) {
        e.preventDefault()

        this.props.appState.emailComposerStore.saveDraft()
        this.props.appState.messageListStore.reload()
    }

    @autobind
    async onSend (e) {
        e.preventDefault()

        let success = await this.props.appState.emailComposerStore.send()
        this.props.appState.messageListStore.reload()

        if (success) {
            this.props.uiState.showNotification("Message sent")
            this.props.uiState.closeModal()
        }
    }

    @autobind
    onBodyChange (bodyEditorState: EditorState) {
        this.setState({bodyEditorState})
        this.updateStoreBody(bodyEditorState, this.state.templateValues)
    }

    @autobind
    onTemplateValueChange (key:string, value:string) {
        let templateValues = {...this.state.templateValues, [key]: value}
        this.setState({
            templateValues,
            // Change editor key prop value to force a re-render, otherwise the changed
            // template value will not get picked up.
            editorKey: this.state.editorKey+1
        })

        this.updateStoreBody(this.state.bodyEditorState, templateValues)
    }

    @autobind
    onToggleClaimDocuments (e) {
        e.preventDefault()

        this.setState({showClaimDocuments: !this.state.showClaimDocuments})
    }

    @autobind
    onLoadTemplate (e) {
        e.preventDefault()

        let templateVars = extractTemplateVars(this.state.selectedTemplate.content)
        let templateValues = this.initTemplateValues(templateVars)

        let bodyEditorState = textToEditorState(
            this.state.selectedTemplate.content,
            new CompositeDecorator(
                [{strategy: this.processTemplateVars, component: this.renderTemplateVar}]
            )
        )

        this.setState({
            templateValues,
            bodyEditorState,
        })

        this.updateStoreBody(bodyEditorState, templateValues)
    }

    @autobind
    onTemplateSelectionChange (e) {
        e.preventDefault()

        let store = this.props.appState.templateStore

        for (let t of store.templates) {
            if (t.id == e.target.value) {
                this.setState({selectedTemplate: t})
                break
            }
        }
    }

    @autobind
    processTemplateVars (contentBlock, callback, currentState) {
        let text = contentBlock.getText()

        let matchArr, start;
        while ((matchArr = TEMPLATE_VAR_REGEX.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    }

    @autobind
    renderTemplateVar (props) {
        const vals = this.state.templateValues
        let varName:string = props.decoratedText.substr(1, props.decoratedText.length-2)
        let output = props.decoratedText
        let value = null

        if (vals[varName] && vals[varName].length) {
            return <span className="filled">{vals[varName]}</span>
        } else {
            return <span className="unfilled">{props.decoratedText}</span>
        }
    }

    @autobind
    updateStoreBody (bodyEditorState, templateValues) {
        let content = convertToRaw(bodyEditorState.getCurrentContent())

        let text = ''
        for (let block of content.blocks) {
            text += block.text + '\n'
        }

        if (templateValues) {
            for (let k of Object.keys(templateValues)) {
                text = text.replace(RegExp(`{${k}}`, 'g'), templateValues[k])
            }
        }

        this.props.appState.emailComposerStore.formState.$.body.onChange(text)
    }

    initTemplateValues (templateVars) {
        let templateValues = {}
        let claim = this.props.appState.claimDetailsStore.claim
        let agent = this.props.appState.auth.user
        for (let varName of templateVars) {
            let value = ''

            switch (varName) {
                case 'client_first_name':
                    value = claim.submitter.name.split(' ')[0]
                    break

                case 'client_full_name':
                    value = claim.submitter.name
                    break

                case 'agent_first_name':
                    value = agent.first_name
                    break

                case 'agent_full_name':
                    value = `${agent.first_name} ${agent.last_name}`
                    break

                case 'claim_number':
                    value = claim.case_id_human
                    break

                case 'claim_airline':
                    value = claim.airline.name
                    break

                case 'claim_flight_number':
                    value = claim.flight_number
                    break

                case 'claim_departure_date':
                    value = moment.utc(claim.departure_time).format('DD.MM.YYYY')
                    break
            }
            templateValues[varName] = value
        }

        return templateValues
    }
}


@inject('appState', 'uiState')
@observer
export class ComposeEmailModal extends React.Component
<{appState?: AppState, uiState?: UIState}>
{
    render () {
        return <Modal isOpen={true} size="lg">
            <ModalHeader toggle={this.props.uiState.closeModal}>
                New message to { this.props.appState.emailComposerStore.toParty }
            </ModalHeader>

            <ModalBody>
                <ComposeEmail/>
            </ModalBody>
        </Modal>
    }
}


@inject('appState')
@observer
class ClaimDocumentList extends React.Component<{appState?: AppState}> {
    render () {
        let store = this.props.appState.emailComposerStore

        return <div className="claim-documents">
            <b>Claim attachments</b>
            {
                store.claim.documents.map(
                    (i) => <div>
                        <DocumentLink key={`claim-attachment-${i.id}`} document={i.document}/>
                        {" "}
                        <a href="#" onClick={(e) => this.attachDocument(e, i.document)}>Attach</a>
                    </div>
                )
            }

            <b>Claim documents</b>
            {
                store.claim.documents.map(
                    (i) => <div>
                        <DocumentLink key={`claim-doc-${i.id}`} document={i.document}/>
                        {" "}
                        <a href="#" onClick={(e) => this.attachDocument(e, i.document)}>Attach</a>
                    </div>
                )
            }
        </div>
    }

    attachDocument (e, document) {
        e.preventDefault()
        this.props.appState.emailComposerStore.attachments.push(new EmailAttachment({document}))
    }
}


@inject('appState')
@observer
class AttachmentList extends React.Component<{appState?: AppState}> {
    render () {
        let store = this.props.appState.emailComposerStore

        return <div className="attachments">
            {
                store.attachments.map(
                    (i) => <div>
                        <DocumentLink key={`msg-attachment-${i.document.id}`} document={i.document}/>
                        {" "}
                        <a href="#" onClick={(e) => this.removeAttachment(e, i)}>Remove</a>
                    </div>
                )
            }
        </div>
    }

    removeAttachment (e, attachment: EmailAttachment) {
        e.preventDefault()
        let store = this.props.appState.emailComposerStore
        store.attachments.splice(store.attachments.indexOf(attachment), 1)
    }
}

import * as React from 'react'
import * as qs  from 'qs'
import autobind from 'autobind-decorator'
import {inject, observer} from 'mobx-react'
import {Form, Input} from 'reactstrap'
import {AppState} from "../state/app";
import {UIState} from '../state/ui'


@inject('appState', 'uiState')
@observer
export class SearchBox extends React.Component<{appState?: AppState, uiState?: UIState}> {
    searchInputRef = null

    constructor (props) {
        super(props)

        this.searchInputRef = React.createRef()
    }

    @autobind
    onKeyUp (e) {
        if (e.keyCode == 27) {  // ESC
            e.preventDefault()

            this.searchInputRef.current.value = ''
        }
        else if (e.keyCode == 13) {  // Enter
            e.preventDefault()

            this.setQuery(this.searchInputRef.current.value)
        }
    }

    @autobind
    onSubmit (e) {
        e.preventDefault()
    }

    setQuery (q) {
        if (q.trim() == '') {
            return
        }

        let urlQuery = qs.stringify({q})

        this.props.uiState.routing.push(`/search?${urlQuery}`)
        this.props.appState.searchState.setQuery(q)
    }

    render () {
        return <Form inline onSubmit={this.onSubmit}>
            <Input
                className="search-box-input"
                innerRef={this.searchInputRef}
                placeholder="Search..."
                onKeyUp={this.onKeyUp}
            />
        </Form>
    }
}
import {User} from '../../state/entities/User'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRobot} from '@fortawesome/free-solid-svg-icons'
import * as React from 'react'

import './ActionAssigneeIcon.scss'

function getUserInitials (user: User) {
    let initials = ''

    if (user.first_name && user.first_name.length) {
        initials += user.first_name[0].toUpperCase()
    } else {
        initials += 'X'
    }

    if (user.last_name && user.last_name.length) {
        initials += user.last_name[0].toUpperCase()
    } else {
        initials += 'X'
    }

    return initials
}


export function ActionAssigneeIcon ({user}: {user: User | null}) {
    let content = <FontAwesomeIcon icon={faRobot}/>
    if (user) {
        content = <span>{getUserInitials(user)}</span>
    }
    return <span className="action-assignee-icon">
        {content}
    </span>
}

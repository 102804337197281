import * as React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import {Airline} from '../../state/entities/Airline'


interface OnChangeFunc {
    (value: [Airline]): void
}

interface AirlineAutocompleteProps {
    id?: string
    onChange: OnChangeFunc
    onBlur?: (e: Event) => void
    defaultValue?: string
    selected?: Airline
}

export default
class AirlineAutocomplete extends React.Component<AirlineAutocompleteProps> {
    state = {
        requestCount: 0,
        airlines: [],
        defaultValue: null,
    }

    componentDidMount () {
        if (this.props.defaultValue) {
            this.loadDefault()
        }
    }

    async loadDefault () {
        let data = await this.loadAirlines(this.props.defaultValue, true)
        this.setState({defaultValue: data})
        this.props.onChange(data)
    }

    onSearch = async (query) => {
        this.loadAirlines(query)
    }

    async loadAirlines (query, searchByCode = false) {
        this.setState({
            requestCount: this.state.requestCount + 1
        })

        let url = `/api/airlines?search=${query}&random=${Math.random()}`
        if (searchByCode) {
            url = `/api/airlines?iata_code=${query}&random=${Math.random()}`
        }
        let result = await (await fetch(url)).json()

        this.setState({requestCount: this.state.requestCount - 1, airlines: result})

        return result
    }

    render () {
        if (this.props.defaultValue && !this.state.defaultValue) {
            return null
        }

        return <AsyncTypeahead
            id={this.props.id ? this.props.id : null}
            options={this.state.airlines}
            labelKey="name"
            onSearch={this.onSearch}
            isLoading={this.state.requestCount > 0}
            minLength={2}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            defaultSelected={this.state.defaultValue || []}
            selected={this.props.selected ? [this.props.selected] : null}
        />
    }
}



import * as React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Country } from '../../state/entities/Country'


export interface OnChangeFunc {
    (value: Country[]): void
}

interface CountryAutocompleteProps {
    id?: string
    placeholder?: string
    onChange: OnChangeFunc
    onBlur?: () => any | null
    defaultValue?: string
    selected?: Country
}


export default
class CountryAutocomplete extends React.Component<CountryAutocompleteProps> {
    state = {
        requestCount: 0,
        countries: [],
        defaultValue: null,
    }

    componentDidMount () {
        if (this.props.defaultValue) {
            this.loadDefault()
        }
    }

    async loadDefault () {
        let data = await this.loadCountries(this.props.defaultValue, true)
        this.setState({defaultValue: data})
        this.props.onChange(data)
    }

    getLabel = (item) => {
        return `${item.name} (${item.code})`
    }

    onSearch = async (query) => {
        this.loadCountries(query)
    }

    async loadCountries (query, searchByCode = false) {
        this.setState({
            requestCount: this.state.requestCount + 1
        })

        let url = `/admin-api/countries?search=${query}&random=${Math.random()}`
        if (searchByCode) {
            url = `/admin-api/countries?code=${query}&random=${Math.random()}`
        }
        let result = await (await fetch(url)).json()

        this.setState({requestCount: this.state.requestCount - 1, countries: result})

        return result
    }

    renderMenuItemChildren (option, props, index) {
        return <React.Fragment key={option.code}>
            <div>{option.name} ({option.code})</div>
        </React.Fragment>
    }

    render () {
        if (this.props.defaultValue && !this.state.defaultValue) {
            return null
        }

        return <AsyncTypeahead
            id={this.props.id ? this.props.id : null}
            options={this.state.countries}
            labelKey={this.getLabel}
            onSearch={this.onSearch}
            isLoading={this.state.requestCount > 0}
            minLength={2}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            placeholder={this.props.placeholder}
            defaultSelected={this.state.defaultValue || []}
            selected={this.props.selected ? [this.props.selected] : null}
            renderMenuItemChildren={this.renderMenuItemChildren}
        />
    }
}

import * as React from "react"
import {inject, observer} from "mobx-react"
import {AppState} from "../state/app"
import {
    Button, Collapse, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap"
import ENVELOPE_IMAGE from "../assets/img/mail.svg"
import './MessageDropdown.scss'
import {NavLink} from './NavLink'

@inject('appState')
@observer
export class MessageDropdown extends React.Component<{appState?: AppState}> {
    state = {
        isOpen: false,
    }

    render () {
        let counts = this.props.appState.claimStatusCounts
        let hasCounts = (counts.unreadClient || counts.unreadAirline || counts.unreadRegulator)
        ? 'message-button-has-counts' : ''

        return <>
            <UncontrolledDropdown className="message-dropdown">
                <DropdownToggle tag="span" className={`message-button ${hasCounts}`}>
                    <img src={ENVELOPE_IMAGE} alt="Envelope"/>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>
                        <NavLink to="/claims/unread_client">
                            Clients <Badge>{counts.unreadClient}</Badge>
                        </NavLink>
                        <DropdownItem className="message-dropdown-child-item">
                            <NavLink to="/claims/unread_client_group_1">
                                Group #1 <Badge>{counts.unreadClientGroup1}</Badge>
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem className="message-dropdown-child-item">
                            <NavLink to="/claims/unread_client_group_2">
                                Group #2 <Badge>{counts.unreadClientGroup2}</Badge>
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem className="message-dropdown-child-item">
                            <NavLink to="/claims/unread_client_group_3">
                                Group #3 <Badge>{counts.unreadClientGroup3}</Badge>
                            </NavLink>
                        </DropdownItem>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink to="/claims/unread_airline">
                            Airlines <Badge>{counts.unreadAirline}</Badge>
                        </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink to="/claims/unread_regulator">
                            NEBs <Badge>{counts.unreadRegulator}</Badge>
                        </NavLink>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            {/* <Button
                className="sidebar-title sidebar-title-toggler" 
                onClick={() => this.setState({isOpen: !this.state.isOpen})}
            >
                Icon
            </Button> */}
        </>
    }
}

import * as React from 'react'
import { inject, observer } from 'mobx-react'
import autobind from 'autobind-decorator'

import { AppState } from '../../state/app'
import { FormState, FieldState } from 'formstate'
import { required, validateIBAN } from '../../util/validation'

import { Modal } from '../common/Modal'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { Airline } from '../../state/entities/Airline'
import { FieldFeedBack } from '../common/FieldFeedBack'
import { BankAccount, Currency } from '../../state/entities/Payment'
import { Party } from '../../state/entities/Common'

type BankAccountFields = {
    name: FieldState<string>
    account_number: FieldState<string>
    currency: FieldState<Currency>
    party: FieldState<Party>
    airline: FieldState<Airline>
}

class BankAccountFormState extends FormState<BankAccountFields> {
    constructor (bankAccount: BankAccount, appState?: AppState) {
        super(
            {
                name: new FieldState<string>(
                    bankAccount ? bankAccount.name : null
                ).validators(
                    required(`Please enter the name`)
                ),
                account_number: new FieldState<string>(
                    bankAccount ? bankAccount.account_number : null
                ).validators(
                    required(`Please enter the account number`),
                    (value) => validateIBAN(value)
                ),
                currency: new FieldState<Currency>(
                    bankAccount ? bankAccount.currency : null
                ).validators(
                    required(`Please enter the currency`)
                ),
                party: new FieldState<Party>(
                    bankAccount ? bankAccount.party : null
                ).validators(
                    required(`Please enter the party`)
                ),
                airline: new FieldState<Airline>(bankAccount.airline).validators(),
            }
        )
    }
}


@inject('appState')
@observer
export class BankAccountModal extends React.Component
<{bankAccount: BankAccount, appState?: AppState, create?: boolean}>
{
    formState = new BankAccountFormState(
        this.props.bankAccount,
        this.props.appState
    )

    @autobind
    async onUpdateClick () {
        let result = await this.formState.validate()

        if (result.hasError) {
            return false
        }

        let bankAccount = this.props.bankAccount
        let adStore = this.props.appState.airlineDetailsStore

        for (let k of Object.keys(this.formState.$)) {
            bankAccount[k] = this.formState.$[k].value
        }

        if (this.props.create) {
            adStore.addBankAccount(bankAccount)
        }

        return true
    }

    render () {
        let fields = this.formState.$

        let title = this.props.create ? "Add bank account" : "Edit bank account"

        return <Modal title={title} actionLabel="Save" onAction={this.onUpdateClick}>
            <Form onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                    <Label for="name">{`Name`}</Label>
                    <Input
                        type="text"
                        name="name"
                        invalid={!!fields.name.error}
                        value={fields.name.value || ''}
                        onChange={(e) => fields.name.onChange(e.target.value)}
                        onBlur={fields.name.validate}
                    />
                    <FieldFeedBack field={fields.name}/>
                </FormGroup>

                <FormGroup>
                    <Label for="account-number">{`Account number`}</Label>
                    <Input
                        type="text"
                        name="account-number"
                        invalid={!!fields.account_number.error}
                        value={fields.account_number.value || ''}
                        onChange={(e) => fields.account_number.onChange(e.target.value)}
                        onBlur={fields.account_number.validate}
                    />
                    <FieldFeedBack field={fields.account_number}/>
                </FormGroup>

                <FormGroup>
                    <Label for="currency">{`Currency`}</Label>
                    <Input
                        id="currency"
                        type="select"
                        invalid={!!fields.currency.error}
                        value={fields.currency.value}
                        onChange={
                            (e) => {
                                fields.currency.onChange(e.target.value as Currency);
                                fields.currency.validate();
                            }
                        }
                        onBlur={() => fields.currency.validate()}
                    >
                        {Object.keys(Currency).map(
                            (currency, key) => <option key={key} value={Currency[currency]}>
                                {Currency[currency]}
                            </option>
                        )}
                    </Input>
                    <FieldFeedBack field={fields.currency}/>
                </FormGroup>

                {!this.props.create &&
                    <FormGroup>
                        <Label for="party">{`Party`}</Label>
                        <Input
                            id="party"
                            type="select"
                            invalid={!!fields.party.error}
                            value={fields.party.value}
                            onChange={
                                (e) => {
                                    fields.party.onChange(e.target.value as Party);
                                    fields.party.validate();
                                }
                            }
                            onBlur={() => fields.party.validate()}
                        >
                            {Object.keys(Party).map(
                                (party, key) => <option key={key} value={Party[party]}>
                                    {Party[party]}
                                </option>
                            )}
                        </Input>
                    </FormGroup>
                }
            </Form>
        </Modal>
    }
}

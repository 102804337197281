import * as React from 'react'
import {Alert, Button} from 'reactstrap'

import './SavePrompt.scss'
import {inject} from "mobx-react";
import {AppState} from "../../state/app";

@inject('appState')
export default class SavePrompt extends React.Component<{appState?: AppState}> {
    render () {
        return <div className="save-prompt">
            <Alert color="warning">
                Claim data has been changed

                <Button color="danger" onClick={this.props.appState.claimDetailsStore.save}>
                    Save
                </Button>

                <Button onClick={this.props.appState.claimDetailsStore.reload}>
                    Revert
                </Button>
            </Alert>
        </div>
    }
}
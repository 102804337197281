import * as React from 'react'
import {Document} from "../state/app"


export default class DocumentLink extends React.Component<{document: Document}> {
    render () {
        const doc = this.props.document

        return <div className="document-link d-inline">
            <a href={doc.content_url} target="_blank">
                {doc.name}
            </a>{" "}
            {Math.round(doc.size / 1024)}KB
        </div>
    }
}
export class Entity {
    id: number

    constructor (data) {
        data && Object.assign(this, data)
    }

    parseDates (data, dateFields: string[]) {
        for (let df of dateFields) {
            if (data[df]) {
                this[df] = new Date(data[df])
            }
        }
    }

    update (data) {
        Object.assign(this, data)
    }
}

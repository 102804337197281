import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Link} from 'react-router-dom'
import * as qs from 'qs'
import {Col} from 'reactstrap'
import {AppState} from '../../state/app'
import {Spinner} from 'react-activity'
import ClaimLink from '../common/ClaimLink'

import 'react-activity/dist/react-activity.css';
import {ResultType, SearchResult} from '../../state/search'
import {Party} from '../../state/entities/Common'


@inject('appState')
@observer
export class SearchResults extends React.Component<{appState?: AppState, location: Location}> {

    componentDidMount(): void {
        this.props.appState.searchState.setQuery(this.getQueryFromURL())
    }

    render () {
        let query = this.getQueryFromURL()
        let state = this.props.appState.searchState

        if (state.loading) {
            return <div className="loading">
                <Spinner size={24}/> <span>Loading...</span>
            </div>
        }

        return <>
            <Col xs="12" className="content" key={query}>
                <h2>Search results for "{query}"</h2>
                {state.results.map((r,i) => <SearchResultsItem key={`${r}-${i}`} result={r}/>)}
            </Col>
        </>
    }

    getQueryFromURL () {
        return String(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['q']) || null
    }
}


class ClaimItem extends React.Component<{result: SearchResult}> {
    render () {
        let r = this.props.result
        let c = r.claim

        return <div>
            <Link to={`/claims/${c.id}/details`}>Claim {c.case_id_human}</Link>
        </div>
    }
}


class PassengerItem extends React.Component<{result: SearchResult}> {
    render () {
        let r = this.props.result
        let c = r.claim
        let p = r.passenger

        return <div>
            Passenger {p.name} on <Link to={`/claims/${c.id}/details`}> {c.case_id_human}</Link>
        </div>
    }
}


class MessageItem extends React.Component<{result: SearchResult}> {
    render () {
        let r = this.props.result
        let c = r.claim
        let m = r.message

        let party = m.from_party === Party.SERVICE ? m.to_party : m.from_party
        let direction = m.from_party === Party.SERVICE ? 'to' : 'from'

        return <div>
            <Link to={`/claims/${c.id}/${party}-email/${m.id}`}>
                Message
            </Link>
            {" "} {direction} {party} {" "}
            on <ClaimLink claim={r.claim}/>
        </div>
    }
}

class CommentItem extends React.Component<{result: SearchResult}> {
    render () {
        let r = this.props.result
        let claim = r.claim
        let comment = r.comment

        return <div>
            Comment on <Link to={`/claims/${claim.id}/details`}> {claim.case_id_human}</Link>
        </div>
    }
}


class SearchResultsItem extends React.Component<{result:SearchResult}> {
    render () {
        let r = this.props.result

        if (r.type === ResultType.MESSAGE) {
            return <MessageItem result={r}/>
        } else if (r.type === ResultType.PASSENGER) {
            return <PassengerItem result={r}/>
        } else if (r.type === ResultType.COMMENT) {
            return <CommentItem result={r}/> 
        } else {
            return <ClaimItem result={r}/>
        }
    }
}

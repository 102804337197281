import * as React from 'react'
import {Nav, NavItem, Badge, Collapse} from 'reactstrap'
import {NavLink} from '../NavLink'
import {inject, observer} from "mobx-react";
import {AppState} from "../../state/app";

import {ModuleSidebar} from '../common/ModuleSidebar'


@inject('appState')
@observer
export class ClaimsSidebar extends React.Component<{appState?: AppState}> {
    state = {
        isOpen: false,
    }

    render () {   
        let counts = this.props.appState.claimStatusCounts
        return <ModuleSidebar>
            <div className="sidebar-title">Tasks</div>
            <div className="level-2">
                <NavLink to="/claims/user_active">
                    My Active <Badge className="badge-green">{counts.userActive}</Badge>
                </NavLink>

                <NavLink to="/claims/active">
                    All Active <Badge>{counts.active}</Badge>
                </NavLink>

                <NavLink to="/claims/on_hold">
                    Upcoming <Badge>{counts.onHold}</Badge>
                </NavLink>

                <NavLink to="/claims/partial_claim">
                    Partial <Badge>{counts.partialClaim}</Badge>
                </NavLink>
            </div>
            
            <div className="sidebar-title">New claims</div>
            <div className="level-2">
                <NavLink to="/claims/unsigned">
                    Unsigned <Badge>{counts.unsigned}</Badge>
                </NavLink>

                <NavLink to="/claims/to_review">
                    To Review <Badge>{counts.toReview}</Badge>
                </NavLink>

                <NavLink to="/claims/review_accepted">
                    Accepted <Badge>{counts.reviewAccepted}</Badge>
                </NavLink>

                <NavLink to="/claims/prepare_complete">
                    Submittable <Badge>{counts.prepareComplete}</Badge>
                </NavLink>
            </div>

            <div className="sidebar-title">Filed claims</div>
            <div className="level-2">
                <NavLink to="/claims/claim_submitted">
                    Submitted <Badge>{counts.claimSubmitted}</Badge>
                </NavLink>

                 <NavLink to="/claims/decision_due">
                    Decision due <Badge>{counts.decisionDue}</Badge>
                </NavLink>

                <NavLink to="/claims/claim_rejected">
                    Rejected <Badge>{counts.claimRejected}</Badge>
                </NavLink>

                <NavLink to="/claims/claim_accepted">
                    Approved <Badge>{counts.claimAccepted}</Badge>
                </NavLink>

                <NavLink to="/claims/payment_due">
                    Payment due <Badge>{counts.paymentDue}</Badge>
                </NavLink>

                <NavLink to="/claims/comp_received">
                    Comp received <Badge>{counts.compReceived}</Badge>
                </NavLink>

                <NavLink to="/claims/direct_payout">
                    Direct payout <Badge>{counts.directPayout}</Badge>
                </NavLink>
            </div>

            <div className="sidebar-title">NEB</div>
            <div className="level-2">
                <NavLink to="/claims/neb_new">
                    To Submit <Badge>{counts.nebNew}</Badge>
                </NavLink>

                 <NavLink to="/claims/neb_submitted">
                    Submitted <Badge>{counts.nebSubmitted}</Badge>
                </NavLink>
            </div>

            <div className="sidebar-title">Court</div>
            <div className="level-2">
                <NavLink to="/claims/court_qualified">
                    Qualified <Badge>{counts.courtQualified}</Badge>
                </NavLink>

                <NavLink to="/claims/court_new">
                    To submit <Badge>{counts.courtNew}</Badge>
                </NavLink>

                <NavLink to="/claims/court_submitted">
                    Submitted <Badge>{counts.courtSubmitted}</Badge>
                </NavLink>
            </div>

            <div 
                className="sidebar-title sidebar-title-toggler" 
                onClick={() => this.setState({isOpen: !this.state.isOpen})}
            >
                Other <span className={`other-collapse ${
                    this.state.isOpen ? 'other-collapse-opened' : ''
                }`}></span>
            </div>
            <Collapse isOpen={this.state.isOpen}>
                <div className="level-2">
                    <NavLink to="/claims/prepare_abandoned">
                        Abandoned <Badge>{counts.prepareAbandoned}</Badge>
                    </NavLink>

                    <NavLink to="/claims/review_rejected">
                        Rejected <Badge>{counts.reviewRejected}</Badge>
                    </NavLink>

                    <NavLink to="/claims/claim_invalid">
                        Invalid <Badge>{counts.claimInvalid}</Badge>
                    </NavLink>

                    <NavLink to="/claims/comp_paid">
                        Comp paid <Badge>{counts.compPaid}</Badge>
                    </NavLink>

                    <NavLink to="/claims/manually_closed">
                        Manually closed <Badge>{counts.manuallyClosed}</Badge>
                    </NavLink>
                </div>
            </Collapse>
        </ModuleSidebar>
    }

    componentDidMount () {
        this.props.appState.claimStatusCounts.reload()
    }
}
import {observable} from 'mobx'
import {AppState} from './app'
import {ApiClient} from '../api/client'
import {Claim} from './entities/Claim'
import {Passenger} from './entities/Passenger'
import {EmailMessage} from './entities/EmailMessage'
import {Comment} from './entities/Comment'


export enum ResultType {
    CLAIM = 'claim',
    PASSENGER = 'passenger',
    MESSAGE = 'message',
    COMMENT = 'comment',
}


export interface SearchResult {
    type: ResultType
    claim: Claim
    passenger: Passenger | null
    message: EmailMessage | null
    comment: Comment | null
}


export class SearchState {
    parent: AppState
    apiClient: ApiClient

    @observable loading = false
    @observable query: string = null
    @observable results : SearchResult[] = []

    constructor (parent, apiClient) {
        this.parent = parent
        this.apiClient = apiClient
    }

    setQuery (query) {
        query = (query || '').trim()

        if (query == '') {
            this.query = null
        }

        if (query == this.query) {
            return
        }

        this.query = query
        this.results = []
        this.load()
    }

    async load () {
        this.loading = true
        let response = await this.apiClient.makeCall(
            'GET', `/search?query=${this.query}`
        )
        this.loading = false

        if (response.success) {
            this.results = response.data as SearchResult[]
        }
    }
}

import * as React from 'react'
import {ReviewNewClaimDetailsTask, ReviewNewClaimResult, Action} from '../../state/app'
import TaskNotices from './TaskNotices'
import TaskActions from './TaskActions'
import {ActionAssigneeIcon} from './ActionAssigneeIcon'

import './ReviewNewClaimDetails.scss'


export default class ReviewNewClaimDetails extends React.Component
<{task:ReviewNewClaimDetailsTask}>
{
    render () {
        const task = this.props.task

        return <div className="review-new-claim-task-details">
            <ActionAssigneeIcon user={task.assignee}/>

            <span>Review new claim details</span>

            <div>
                {this.renderResult(task.result)}
            </div>

            <TaskNotices task={task}/>
            <TaskActions task={task}/>
        </div>;
    }

    renderResult (result) {
        if (result == ReviewNewClaimResult.PENDING) {
            return null
        }

        let resultLabel = result == ReviewNewClaimResult.VALID ? "accepted" : "rejected"

        return <div className={`result ${resultLabel}`}>
            {resultLabel}
        </div>
    }
}

import * as React from 'react'
import autobind from 'autobind-decorator'

import {AppState} from "../../state/app"
import {ClaimStatus} from "../../state/entities/Claim"
import {FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'

import {Modal} from '../common/Modal'

const CHOICES = [
    [ClaimStatus.PREPARE_NEW, "To review"],
    [ClaimStatus.PREPARE_ACCEPTED, "Accepted"],
    [ClaimStatus.PREPARE_COMPLETE, "Ready to submit to Airline"],
    [ClaimStatus.CLAIM_SUBMITTED, "Submitted to Airline"],
    [ClaimStatus.SETTLE_PAID_OUT, "Compensation paid"],
    [ClaimStatus.NEB_NEW, "Ready to submit to NEB"],
    [ClaimStatus.NEB_SUBMITTED, "Submitted to NEB"],
    [ClaimStatus.COURT_NEW, "Ready to submit to court"],
    [ClaimStatus.COURT_SUBMITTED, "Submitted to court"],
    [ClaimStatus.SETTLE_DIRECT_PAYOUT, "Direct payout"],
]

export class ManualStatusChangeModal extends React.Component<{appState: AppState}> {
    state = {
        isDropdownOpen: false,
        selectedStatusLabel: 'Select status',
        selectedStatus: '',
        comment: '',
    }

    render () {
        return <Modal title={'Manual status change'} actionLabel="Save" onAction={this.manualStatusChange}>
            <FormGroup>
                <Label for="status">Status</Label>
                <Dropdown isOpen={this.state.isDropdownOpen} toggle={() => this.dropdownToggle()} id="status">
                    <DropdownToggle caret>
                        {this.state.selectedStatusLabel}
                    </DropdownToggle>
                    <DropdownMenu>
                        {
                            CHOICES.map(
                                ([status, label]) => <DropdownItem onClick={
                                    () => this.changeSelectedStatus(status, label)
                                }>
                                    {label}
                                </DropdownItem>
                            )
                        }
                    </DropdownMenu>
                </Dropdown>
            </FormGroup>
            <FormGroup>
                <Label for="comment">Reason for status change</Label>
                <Input type="textarea" onChange={(e) => this.setState({comment: e.target.value})} />
            </FormGroup>
        </Modal>
    }

    dropdownToggle () {
        this.setState({isDropdownOpen: !this.state.isDropdownOpen})
    }

    changeSelectedStatus(status, statusLabel) {
        this.setState({
            selectedStatus: status,
            selectedStatusLabel: statusLabel
        })
    }
    
    @autobind
    async manualStatusChange () {
        await this.props.appState.claimDetailsStore.manualStatusChange(
            this.state.selectedStatus,
            this.state.comment,
        )

        return true
    }
}
import * as React from 'react'
import autobind from 'autobind-decorator'
import {observer} from 'mobx-react'
import {FieldState} from 'formstate'
import {Input} from 'reactstrap'
import {Entity} from '../../../state/entities/Entity'


const EMPTY_KEY = -1


interface Props<T extends Entity> {
    field: FieldState<T>
    idField: FieldState<number>
    options: T[]
    getLabel: (T) => string
    emptyLabel?: string
}

type SetIdField<T extends Entity> = Omit<Props<T>, "field">
type SetInstanceField<T extends Entity> = Omit<Props<T>, "idField">


@observer
export class EntitySelect<T extends Entity> extends React.Component
<SetIdField<T> | SetInstanceField<T>>
{
    render () {
        let field = (this.props as SetInstanceField<T>).field
        let idField = (this.props as SetIdField<T>).idField

        let value = EMPTY_KEY
        if (field && typeof field.value !== 'undefined' && field.value !== null) {
            value = field.value.id
        }

        if (idField && typeof idField.value !== 'undefined' && idField.value !== null) {
            value = idField.value
        }

        return <Input type="select" value={value} onChange={this.onChange}>
            {this.renderOptions()}
        </Input>
    }

    renderOptions() {
        let opts = this.props.options.map((v) =>
            <option key={v.id} value={v.id}>
                {this.props.getLabel(v)}
            </option>
        )

        if (this.props.emptyLabel) {
            opts.unshift(<option key={EMPTY_KEY} value={EMPTY_KEY}>{this.props.emptyLabel}</option>)
        }

        return opts
    }

    internalValueToEntity (value: number) {
        for (let opt of this.props.options) {
            if (opt.id == value) {
                return opt
            }
        }

        return null
    }

    @autobind
    onChange(e) {
        let field = (this.props as SetInstanceField<T>).field
        let idField = (this.props as SetIdField<T>).idField

        let entity = this.internalValueToEntity(e.target.value)

        if (field) {
            field.onChange(entity)
        }

        if (idField) {
            idField.onChange(entity ? entity.id : null)
        }
    }
}

import {observable, computed} from 'mobx'
import autobind from 'autobind-decorator'
import {ApiClient, Forbidden, ValidationError} from '../api/client'
import {User} from './entities/User'


const GOOGLE_OAUTH_CLIENT_ID =
    '309567633544-hl529d1m5e9dmrart25nbudvqijbblsj.apps.googleusercontent.com'

let GAPI


export default class AuthStore {
    @observable statusKnown: boolean = false
    @observable status: boolean = false
    @observable user: User

    @observable private googleUser: any = null
    @observable private googleProfile: any = null

    private apiClient: ApiClient = null
    private googleAuth = null  // gapi.auth2.GoogleAuth

    constructor (apiClient) {
        this.apiClient = apiClient

    }

    @autobind
    init () {
        this.updateSessionStatus()

    }

    async updateSessionStatus () {
        let result = await this.apiClient.makeCall(
            'GET',
            '/session',
            {
                anonymous: true
            }
        )

        if (result.data) {
            this.user = new User(result.data)
            this.status = true
        } else {
            this.user = null
            this.status = false
        }

        this.apiClient.setAuthStatus(this.status)
        this.statusKnown = true
    }

    async passwordLogin (email, password) {
        return await this.initSession({email, password})
    }

    async googleOAuthLogin(googleUser) {
        let token = googleUser.getAuthResponse().id_token;
        await this.initSession({token})
    }

    async logout () {
        await this.apiClient.makeCall('DELETE', '/session');
        (await this.getGoogleAuth()).signOut()
        window.location.reload()
    }

    private async initSession(credentialData) {
        try {
            let result = await this.apiClient.makeCall(
                'POST',
                '/session',
                { data: credentialData, anonymous: true}
            )

            if (result.success) {
                this.user = new User(result.data)
                this.status = true
            } else {
                this.status = false
            }
        } catch (exc) {
            if (exc instanceof Forbidden) {
                this.status = false
            } else {
                throw exc
            }
        }

        this.statusKnown = true
        this.apiClient.setAuthStatus(this.status)

        return this.status
    }

    @computed
    get avatarURL () {
        if (this.googleProfile) {
            return this.googleProfile.getImageUrl()
        }

        return null
    }

    @computed
    get email () {
        if (!this.user) return null

        return this.user.email
    }

    private async getGooglePlatformAPIInstance () {
        let gAPI = (window as any).gapi
        if (gAPI) {
            return gAPI
        }

        return new Promise<any>(
            resolve => {
                window["_initGoogleAuth"] = () => resolve((window as any).gapi)
            }
        )
    }

    @autobind
    private async getGoogleAuth () {
        if (this.googleAuth) {
            return this.googleAuth
        }

        let gapi = await this.getGooglePlatformAPIInstance()

        return new Promise<any>(resolve => {
            gapi.load('auth2', () => {
                this.googleAuth = gapi.auth2.init({client_id: GOOGLE_OAUTH_CLIENT_ID, scope: 'profile'})
                this.googleAuth.currentUser.listen(this.onGoogleUserChanged)

                resolve(this.googleAuth)
            })
        })
    }

    @autobind
    private async onGoogleUserChanged (user) {
        this.googleUser = user
        this.googleProfile = user.getBasicProfile()
        let signedIn = (await this.getGoogleAuth()).isSignedIn.get()

        if (signedIn) {
            await this.googleOAuthLogin(user)
        }
    }

    async renderGoogleSignInButton (elementId) {
        // signin2.render() will fail without initializing auth2 first.
        let gAuth = await this.getGoogleAuth()

        let gapi = await this.getGooglePlatformAPIInstance()
        gapi.signin2.render(
            elementId,
            {
                'scope': 'profile email',
                'width': 200,
                'height': 50,
                'longtitle': true,
                'theme': 'dark',
            }
        );
    }

}

import * as React from 'react'
import {Comment} from '../../state/entities/Comment'
import {ActionAssigneeIcon} from './ActionAssigneeIcon'

import './CommentDetails.scss'

export default class CommentDetails extends React.Component<{task:Comment}> {
    render () {
        return <div className="comment-details">
            <ActionAssigneeIcon user={this.props.task.assignee}/>

            <span className="comment-content">{this.props.task.content}</span>
        </div>
    }
}

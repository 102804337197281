import * as React from 'react'
import {observer} from 'mobx-react'
import {Input} from 'reactstrap'
import {FieldState} from 'formstate'


interface Props {
    field: FieldState<boolean>
}


@observer
export class CheckboxInput extends React.Component<Props> {
    render () {
        let f = this.props.field

        return <Input
            type="checkbox"
            checked={f.value}
            onChange={() => f.onChange(!f.value)}

        />
    }
}
import * as React from 'react'
import {AppState, Action, TaskAction} from "../../state/app"
import {Button} from 'reactstrap'
import {inject, observer} from "mobx-react"
import ActionButton from './ActionButton'


@inject('appState')
@observer
export default class TaskActions extends React.Component<
    {task: Action, params?: any, appState?: AppState}
> {

    state = {
        executing: false,
        action: null
    }

    onActionClick = async (action) => {
        const {appState, task, params} = this.props

        let taskParams = null
        if (params) {
            taskParams = params[action]
        }

        this.setState({executing: true, action: action})
        await appState.taskStore.perform(task, action, taskParams)
        this.setState({executing: false, action: null})

        appState.claimDetailsStore.reload()
    }

    render () {
        const task = this.props.task

        return <div className="task-actions">
            {
                task.actions.slice().map(
                    (a) => <ActionButton
                        key={a.action}
                        onInvoke={()=>this.onActionClick(a.action)}
                        disabled={this.state.executing}
                        executing={this.state.executing && a.action == this.state.action}
                        label={a.label}
                    />
                )
            }
        </div>
    }
}

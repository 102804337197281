import {observable, computed} from 'mobx'


function readCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

interface GenericResponse {
    status: number
}

export interface ErrorData {
    message: string
    details: object
}

interface ErrorResponse extends GenericResponse {
    success: false
    data: ErrorData
}

type ResponseData = object|object[]|null


export class ServerError {
    constructor(public message:string, public details:object|null) {}
}
export class ValidationError extends ServerError {}
export class Forbidden extends ServerError {}

interface DataResponse extends GenericResponse {
    success: true
    data: ResponseData
}

type ApiResponse = ErrorResponse | DataResponse


export class ApiClient {
    baseUrl:string
    csrfInitialized = false
    authenticatedResolve = null
    authenticated = new Promise<boolean>((resolve, reject)=>{this.authenticatedResolve = resolve})
    @observable activeCallCount:number = 0
    errorCallback: (method, path, data: ErrorData, response) => void

    constructor (baseUrl = '') {
        this.baseUrl = baseUrl
    }

    async initCSRF() {
        if (this.csrfInitialized) return

        await fetch(this.baseUrl + '/init-csrf')

        this.csrfInitialized = true
    }

    setErrorCallback (callback) {
        this.errorCallback = callback
    }

    setAuthStatus (status) {
        this.authenticatedResolve(status)
    }

    async waitAuth () {
        await this.authenticated
    }

    async makeCall(method, path, {data = null, query = null, anonymous = false} = {}) : Promise<ApiResponse> {
        if (!anonymous) {
            await this.waitAuth()
        }

        let fetchOptions = {
            method,
            credentials: 'same-origin' as any,
            headers: {},
            body: undefined,
        }

        if (data) {
            fetchOptions.headers = {
                'Content-Type': 'application/json'
            } as any
            fetchOptions.body = JSON.stringify(data)
        }

        await this.initCSRF()

        fetchOptions.headers['X-CSRFToken'] = readCookie('csrftoken')

        try {
            this.activeCallCount++
            let fetchResponse = await fetch(this.baseUrl + path, fetchOptions)


            let data = null
            if (fetchResponse.status != 204) {
                data = await fetchResponse.json()
            }

            if (!fetchResponse.ok && this.errorCallback) {
                this.errorCallback(method, path, data, fetchResponse)
            }

            let success = (fetchResponse.status >= 200 && fetchResponse.status < 300)

            if (success) {
                return {
                    success: true,
                    status: fetchResponse.status,
                    data
                }
            } else {
                if (fetchResponse.status == 400) {
                    throw new ValidationError(data.message, data.details)
                } else if (fetchResponse.status == 403) {
                    throw new Forbidden(data.message, data.details)
                } else {
                    throw new ServerError(data.message, data.details)
                }
            }

        } finally {
            this.activeCallCount--
        }
    }

    @computed
    get busy () {
        return this.activeCallCount > 0
    }
}

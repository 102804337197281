import * as React from 'react'
import {observer} from 'mobx-react'
import {
    Navbar, NavbarBrand, NavItem, Nav,
    Container, Row, Col,
    Form, Input,
    Badge
} from 'reactstrap'
import {Router, Switch, Route, Redirect} from 'react-router'

import {ClaimDetails} from '../case-details/ClaimDetails'
import {PaymentList} from './PaymentList'
import {PaymentSidebar} from './PaymentSidebar'


function renderPaymentList (props) {
    return <PaymentList location={props.location} key={props.match.params.set} paymentSet={props.match.params.set}/>
}

interface Props {
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
}

@observer
export class PaymentModule extends React.Component<Props> {
    render () {
        return <>
            <Col md="2" className="sidebar">
                <PaymentSidebar/>
            </Col>

            <Col md="10" className="content">
                <Switch>
                    <Route exact path="/payments" render={() => (<Redirect to="/payments/pending" />)} />

                    <Route path="/payments/:set([a-z\-_]+)" render={renderPaymentList} />
                    <Route path="/payments/:id" component={ClaimDetails}/>
                </Switch>
            </Col>
        </>
    }
}

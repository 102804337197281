import * as React from 'react'
import {Modal as RSModal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import {UIState} from '../../state/ui'
import ActionButton from '../task-details/ActionButton'


interface Props {
    title: string
    actionLabel: string
    onAction: () => Promise<boolean>
    onClose?: () => void
    uiState?: UIState
}


@inject('uiState')
@observer
export class Modal extends React.Component<Props> {
    state = {
        actionInProgress: false,
    }

    @autobind
    onClose () {
        if (this.state.actionInProgress) {
            return
        }

        if (this.props.onClose) {
            this.props.onClose()
        } else {
            this.props.uiState.closeModal()
        }
    }

    @autobind
    async onActionClick () {
        this.setState({actionInProgress: true})
        let close = false
        try {
            close = await this.props.onAction()
        } finally {
            this.setState({actionInProgress: false})
        }

        if (close) {
            this.onClose()
        }
    }

    render () {
        return <RSModal isOpen={true} autoFocus={false}>
            <ModalHeader toggle={this.onClose}>{this.props.title}</ModalHeader>

            <ModalBody>
                {this.props.children}
            </ModalBody>

            <ModalFooter>
                <ActionButton
                    label={this.props.actionLabel}
                    executing={this.state.actionInProgress}
                    color="primary"
                    onInvoke={this.onActionClick}
                />

                {' '}

                <Button
                    disabled={this.state.actionInProgress}
                    color="secondary"
                    onClick={this.onClose}>

                    Cancel

                </Button>
            </ModalFooter>
        </RSModal>
    }
}

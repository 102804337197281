import * as React from 'react'
import {CloseClaimTask} from '../../state/app'
import TaskNotices from './TaskNotices'
import TaskActions from './TaskActions'
import {ActionAssigneeIcon} from './ActionAssigneeIcon'


export default class CloseClaimDetails extends React.Component
<{task:CloseClaimTask}>
{
    render () {
        const task = this.props.task

        return <div>
            <ActionAssigneeIcon user={task.assignee}/>
            Claim closed: {this.props.task.comment}
        </div>;
    }
}

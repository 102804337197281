import autobind from 'autobind-decorator'
import * as React from 'react'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {Claim} from '../../state/entities/Claim'
import {ClaimStub} from './ClaimStub'


export class ClaimAutocomplete extends React.Component
<{onChange: (Claim) => void, selected?: Claim}>
{
    state = {
        requestCount: 0,
        claims: [] as Claim[]
    }

    render () {
        return <AsyncTypeahead
            options={this.state.claims}
            labelKey={opt => opt.case_id_human}
            onSearch={this.onSearch}
            isLoading={this.state.requestCount > 0}
            minLength={2}
            onChange={(e) => this.props.onChange(e[0] as Claim)}
            selected={this.props.selected ? [this.props.selected] : null}
            renderMenuItemChildren={(opt, props, i) => <div>
                <b>{opt.case_id_human}</b>
                <ClaimStub claim={opt}/>
            </div>}
        />
    }

    @autobind
    async onSearch (query) {
        this.setState({
            requestCount: this.state.requestCount + 1
        })

        let data = await (
            await fetch(`/admin-api/claims?search=${query}&random=${Math.random()}`)
        ).json()

        let claims = (data as object[]).map(c => new Claim(c))

        this.setState({
            requestCount: this.state.requestCount - 1,
            claims: claims,
        })
    }
}

import * as moment from 'moment'


export function formatDateColumn (params) {
    if (!params.value) {
        return 'N/A'
    }

    return moment.utc(params.value).format('YYYY.MM.DD')
}


export function formatMoneyColumn (params) {
    return `€${params.value}`
}

import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'

import {AppState} from '../../state/app'
import {FormState, FieldState} from 'formstate'
import {required, validateFlightNumber} from '../../util/validation'

import {Modal} from '../common/Modal'
import {Form, FormGroup, Label, Input, Button} from 'reactstrap'

import {TripSegment} from '../../state/entities/TripSegment'
import {Airline} from '../../state/entities/Airline'
import {Airport} from '../../state/entities/Airport'
import AirlineAutocomplete from '../common/AirlineAutocomplete'
import AirportAutocomplete from '../common/AirportAutocomplete'
import {FieldFeedBack} from '../common/FieldFeedBack'
import {DatePicker} from 'react-widgets'

enum FlightIssue {
    CANCELLED = 'FlightIssue.CANCELLED',
    BOARDING_DENIED = 'FlightIssue.BOARDING_DENIED',
    LATE_DEPARTURE = 'FlightIssue.LATE_DEPARTURE',
    LATE_ARRIVAL = 'FlightIssue.LATE_ARRIVAL',
}

function getFlightIssueLabel(issue: FlightIssue) {
    switch(issue) {
        case FlightIssue.CANCELLED:
            return `Flight was cancelled`

        case FlightIssue.BOARDING_DENIED:
            return `Boarding was denied`

        case FlightIssue.LATE_DEPARTURE:
            return `Flight departed more than 3 hours late`

        case FlightIssue.LATE_ARRIVAL:
            return `Flight arrived more than 3 hours late`
    }
}

const FLIGHT_ISSUE_CHOICES : [FlightIssue, string][] = [
    [FlightIssue.CANCELLED, `Flight was cancelled`],
    [FlightIssue.BOARDING_DENIED, `Boarding was denied`],
    [FlightIssue.LATE_DEPARTURE, `Flight departed more than 3 hours late`],
    [FlightIssue.LATE_ARRIVAL, `Flight arrived more than 3 hours late`],
]

function onAirportFieldChange (field: FieldState<Airport>, selected: Airport[]) {
    const airport = selected.length > 0 ? selected[0] : null
    field.onChange(airport)
    field.validate()
}

type TripSegmentsFields = {
    airline: FieldState<Airline>
    departure_airport: FieldState<Airport>
    arrival_airport: FieldState<Airport>
    departure_date: FieldState<Date>
    flight_number: FieldState<string>
    flight_issue: FieldState<number>
}

class TripSegmentFormState extends FormState<TripSegmentsFields> {
    constructor (tripSegment: TripSegment, appState?: AppState) {

        function validateFlightNumberUnique (flightNumber: string) {
            if (flightNumber == null) {
                return null
            }

            let flightNos = []
            appState.claimDetailsStore.claim.trip_segments.map((ts) => {
                ts.id != tripSegment.id ? flightNos.push(ts.flight.flight_number) : null
            })

            if (flightNos.filter(item => item == flightNumber).length > 0) {
                return `Flight numbers must not repeat`
            }
        }

        function validateFirstDepartureLastArrivalAirports (arrivalAirport: Airport) {
            if (arrivalAirport == null) {
                return null
            }

            if (appState.claimDetailsStore.claim.trip_segments.length > 1) {
                let tripSegmentsLength = appState.claimDetailsStore.claim.trip_segments.length
                
                if (tripSegment.position == tripSegmentsLength - 1 || tripSegment.position == tripSegmentsLength) {
                    let firstTripSegmentFlightDepartureAirport = appState.claimDetailsStore.claim.trip_segments[0].flight.departure_airport
                    
                    if (arrivalAirport.id == firstTripSegmentFlightDepartureAirport.id) {
                        return `Departure airport and destination airports must not repeat`
                    }

                    if (!arrivalAirport.is_eu && !firstTripSegmentFlightDepartureAirport.is_eu) {
                        return `Compensation can not be claimed when both airports are outside the EU`
                    }
                }   
            } 
        }

        super(
            {
                airline: new FieldState<Airline>(
                    tripSegment ? tripSegment.flight.airline : null
                ).validators(
                    required(`Please select the airline for the flight`)
                ),

                departure_airport: new FieldState<Airport>(
                    tripSegment ? tripSegment.flight.departure_airport : null
                ).validators(
                    required(`Please select the departure airport`)
                ),

                arrival_airport: new FieldState<Airport>(
                    tripSegment ? tripSegment.flight.arrival_airport : null
                ).validators(
                    required(`Please select the arrival airport`),
                    validateFirstDepartureLastArrivalAirports
                ),

                departure_date: new FieldState<Date>(
                    tripSegment ? new Date(tripSegment.flight.departure_date) : null
                ).validators(
                    required(`Please select the scheduled departure date`)
                ),

                flight_number: new FieldState<string>(
                    tripSegment ? tripSegment.flight.flight_number : null
                ).validators(
                    required(`Please enter the flight number`),
                    validateFlightNumberUnique,
                    validateFlightNumber
                ),

                flight_issue: new FieldState<number>(
                    tripSegment ? tripSegment.flight_issue : null
                ).validators(),
            }
        )

        function validateDepartureArrivalAirports ($: TripSegmentsFields) {
            if ($.departure_airport.$.id == $.arrival_airport.$.id) {
                return `Airports must not repeat`
            }
        }

        this.validators(validateDepartureArrivalAirports)
    }
}


@inject('appState')
@observer
export class EditTripSegmentModal extends React.Component
<{tripSegment: TripSegment, appState?: AppState, create?: boolean}>
{
    formState = new TripSegmentFormState(
        this.props.tripSegment,
        this.props.appState
    )

    state = {
        isChangeProblemFlightClicked: false
    }

    @autobind
    async onUpdateClick () {
        let result = await this.formState.validate()

        if (result.hasError) {
            if (this.formState.error == `Airports must not repeat`) {
                this.formState.$.arrival_airport.error = this.formState.error
            }
            return false
        }

        let tripSegment = this.props.tripSegment
        let tripSegments = this.props.appState.claimDetailsStore.claim.trip_segments
        let cdStore = this.props.appState.claimDetailsStore

        let departure_date = this.formState.$.departure_date.value

        //Set update_flight true if only airports are changed
        if (tripSegment.flight.departure_airport != this.formState.$.departure_airport.value ||
            tripSegment.flight.arrival_airport != this.formState.$.arrival_airport.value &&
            tripSegment.flight.airline == this.formState.$.airline.value &&
            tripSegment.flight.departure_date.getTime() == departure_date.getTime() &&
            tripSegment.flight.flight_number == this.formState.$.flight_number.value &&
            !this.props.create) {
                tripSegment.flight.update_flight = true
        }

        tripSegment.flight.airline = this.formState.$.airline.value
        tripSegment.flight.departure_airport = this.formState.$.departure_airport.value
        tripSegment.flight.arrival_airport = this.formState.$.arrival_airport.value
        tripSegment.flight.departure_date = departure_date
        tripSegment.flight.flight_number = this.formState.$.flight_number.value
        tripSegment.flight_number = this.formState.$.flight_number.value
        tripSegment.flight_issue = this.formState.$.flight_issue.value


        if (tripSegments.length > 1 && !this.props.create) {
            let currentTripSegmentDepartureAirport = tripSegment.flight.departure_airport
            let currentTripSegmentArrivalAirport = tripSegment.flight.arrival_airport

            /* Update previous trip segment arrival airport if that exists and current trip segment
            departure airport is changed */
            if (tripSegments[tripSegment.position - 1]) {
                let previousTripSegmentArrivalAirport = tripSegments[tripSegment.position - 1].flight.arrival_airport

                if (currentTripSegmentDepartureAirport != previousTripSegmentArrivalAirport) {
                    tripSegments[tripSegment.position - 1].flight.arrival_airport = currentTripSegmentDepartureAirport
                    tripSegments[tripSegment.position - 1].flight.update_flight = true
                }
            }

            /* Update next trip segment departure airport if that exists and current trip segment
            arrival airport is changed */
            if (tripSegments[tripSegment.position + 1]) {
                let nextTripSegmentDepartureAirport = tripSegments[tripSegment.position + 1].flight.departure_airport

                if (currentTripSegmentArrivalAirport != nextTripSegmentDepartureAirport) {
                    tripSegments[tripSegment.position + 1].flight.departure_airport = currentTripSegmentArrivalAirport
                    tripSegments[tripSegment.position + 1].flight.update_flight = true
                }
            }
        }
        

        if (this.props.create) {
            cdStore.addTripSegment(tripSegment)
        }

        return true
    }

    @autobind
    onFlightIssueChange (e: React.ChangeEvent<HTMLInputElement>) {
        let tripSegments = this.props.appState.claimDetailsStore.claim.trip_segments
        tripSegments.forEach(ts => ts.flight_issue = null)

        let fields = this.formState.$
        fields.flight_issue.onChange(Number(e.target.value))
        fields.flight_issue.validate()
    }

    @autobind
    onChangeProblemFlightClick () {
        this.setState({
            isChangeProblemFlightClicked: true
        })
    }

    render () {
        let fields = this.formState.$

        let title = this.props.create ? "Add flight" : "Edit flight"

        let flightIssueTripSegment = this.props.appState.claimDetailsStore.claim.trip_segments.filter((ts) => {
            return ts.flight_issue !== null ? true : false
        })

        return <Modal title={title} actionLabel="Save" onAction={this.onUpdateClick}>
            <Form onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                    <Label for="departure-airport-autocomplete">{`Departure airport`}</Label>
                    <AirportAutocomplete
                        id="departure-airport-autocomplete"
                        onChange={(sel) => onAirportFieldChange(fields.departure_airport, sel)}
                        onBlur={fields.departure_airport.validate}
                        selected={fields.departure_airport.value}
                    />
                    <FieldFeedBack field={fields.departure_airport}/>
                </FormGroup>

                <FormGroup>
                    <Label for="arrival-airport-autocomplete">{`Arrival airport`}</Label>
                    <AirportAutocomplete
                        id="arrival-airport-autocomplete"
                        onChange={(sel) => onAirportFieldChange(fields.arrival_airport, sel)}
                        onBlur={fields.arrival_airport.validate}
                        selected={fields.arrival_airport.value}
                    />
                    <FieldFeedBack field={fields.arrival_airport}/>
                </FormGroup>

                <FormGroup>
                    <Label for="airline">{`Airline`}</Label>
                    <AirlineAutocomplete
                        id="airline-autocomplete"
                        onChange={(sel) => this.onAirlineChange(fields.airline, sel)}
                        onBlur={fields.airline.validate}
                        selected={fields.airline.value}
                    />
                    <FieldFeedBack field={fields.airline}/>
                </FormGroup>

                <FormGroup>
                    <Label for="flight-number">{`Flight number`}</Label>
                    <Input
                        type="text"
                        name="flight-number"
                        invalid={!!fields.flight_number.error}
                        value={fields.flight_number.value || ''}
                        onChange={(e) => fields.flight_number.onChange(e.target.value)}
                        onBlur={fields.flight_number.validate}
                    />
                    <FieldFeedBack field={fields.flight_number}/>
                </FormGroup>

                <FormGroup>
                    <Label for="departure-date">{`Scheduled departure date`}</Label>
                    <DatePicker
                        name="departure-date"
                        format="DD.MM.YYYY"
                        value={fields.departure_date.value}
                        onChange={
                            (value) => {
                                value.setHours(12, 0, 0, 0)
                                fields.departure_date.onChange(value)
                                fields.departure_date.validate()
                            }
                        }
                        onBlur={() => fields.departure_date.validate()}
                    />
                    <FieldFeedBack field={fields.departure_date}/>
                </FormGroup>

                {!this.state.isChangeProblemFlightClicked && fields.flight_issue.value === null && flightIssueTripSegment.length ? (
                    <Button
                        onClick={this.onChangeProblemFlightClick}
                        className="claim-header-action claim-header-action--c_blue"
                    >
                        Change problem flight
                    </Button>
                ) : (
                    <FormGroup>
                        <Label for="issue">{`What was the issue with the flight?`}</Label>
                        <Input
                            id="issue"
                            type="select"
                            invalid={!!fields.flight_issue.error}
                            value={fields.flight_issue.value}
                            onChange={this.onFlightIssueChange}
                            onBlur={() => fields.flight_issue.validate()}
                        >
                            {fields.flight_issue.value == null ?
                                <option key="" value="">{`Select...`}</option>
                                :
                                null
                            }

                            {FLIGHT_ISSUE_CHOICES.map(
                                ([id], key) => <option key={key} value={key}>
                                    {getFlightIssueLabel(id)}
                                </option>
                            )}
                        </Input>

                        <FieldFeedBack field={fields.flight_issue}/>
                    </FormGroup>
                )}
            </Form>
        </Modal>
    }

    @autobind
    onAirlineChange (field: FieldState<Airline>, selected: Airline[]) {
        field.onChange(selected.length > 0 ? selected[0] : null)
        field.validate()
    }
}

import * as React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input} from 'reactstrap'

interface Props {
    commit: Function
    cancel: Function
}

export default class FlagActionModal extends React.Component<Props> {
    state = {
        comment: ''
    }

    onFlagClick = () => {
        this.props.commit(this.state.comment)
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={(evt: React.MouseEvent) => this.props.cancel() }>Flag claim</ModalHeader>
            <ModalBody>
                Comment:
                <Input type="textarea" onChange={(e) => this.setState({comment: e.target.value})}/>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.onFlagClick}>Flag claim</Button>{' '}
                <Button color="secondary" onClick={(evt: React.MouseEvent) => this.props.cancel()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

import * as React from 'react'
import {Claim} from '../../state/entities/Claim'
import {Link} from 'react-router-dom'


export default class ClaimLink extends React.Component<{claim:Claim}> {
    render () {
        let c = this.props.claim
        return <Link to={`/claims/${c.id}/details`}>{c.case_id_human}</Link>
    }
}

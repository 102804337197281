import {Entity} from './Entity'
import {observable} from 'mobx'
import {RequestClaimDocumentSignature} from './actions/RequestClaimDocumentSignature'
import {ClaimDocument, ClaimDocumentType} from './Claim'


export class Passenger extends Entity {
    @observable name: string
    @observable email: string | null
    @observable phone_number: string | null
    @observable remove: boolean
    @observable process_claim: boolean
    @observable guardian: number | null
    @observable wards: Passenger[] = []
    @observable active_signature_requests: Array<RequestClaimDocumentSignature>
    @observable documents: ClaimDocument[]
    @observable is_child: boolean

    getClaimDocument (type:ClaimDocumentType) {
        return this.documents.find(i => i.type == type)
    }

    get claimDocsSigned () {
        return !!this.getClaimDocument(ClaimDocumentType.POA_SIGNED)
    }
}

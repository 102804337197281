import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'

import {Claim} from '../../../state/entities/Claim'
import {UIState} from '../../../state/ui'
import {AssignClaimModal} from '../../claims/AssignClaimModal'


@inject('uiState')
@observer
export default class AssigneeRenderer extends React.Component<{value:Claim, uiState?:UIState}> {
    render () {
        let c = this.props.value

        let label = 'Assign'

        if (c.assignee) {
            let initials = ''

            if (c.assignee.first_name && c.assignee.first_name.length) {
                initials += c.assignee.first_name[0].toUpperCase()
            } else {
                initials += 'X'
            }

            if (c.assignee.last_name && c.assignee.last_name.length) {
                initials += c.assignee.last_name[0].toUpperCase()
            } else {
                initials += 'X'
            }

            label = initials
        }

        return <a href="#" onClick={this.onAssignClick}>{label}</a>
    }

    @autobind
    onAssignClick (e) {
        e.preventDefault()

        let {uiState, value} = this.props
        this.props.uiState.showModal(<AssignClaimModal claim={value} close={uiState.closeModal}/>)
    }
}

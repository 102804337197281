import {observable} from 'mobx'
import autobind from 'autobind-decorator'
import {ApiClient} from '../api/client'
import {Device, Connection} from 'twilio-client'


// TODO: Caller IDs must match those defined on the server verbatim, quite brittle
export const CALLER_IDS = [
    {
        name: 'UK',
        callerId: '+44-7723-433009',
    },
    {
        name: 'ES',
        callerId: '+34-518-88-88-84'
    },
    {
        name: 'USA, New York',
        callerId: '+1-347-464-0232',
    }
]


interface GetCapTokenResponse {
    token: string
}

export enum CallStatus {
    Idle,
    Calling,
    Connected,
}

export default class VoiceCallStore {
    apiClient: ApiClient = null
    @observable callStatus = CallStatus.Idle
    @observable phoneNumber: string = null
    capToken: string = null
    deviceReady: Promise<void> = null

    device: Device = null
    connection: Connection = null

    constructor (apiClient: ApiClient) {
        this.apiClient = apiClient
    }

    async startCall (phoneNumber: string, callerId: string) {
        if (this.callStatus != CallStatus.Idle) {
            throw new Error("Can't start call while another call is in progress")
        }

        this.capToken = await this.getCapToken()
        this.initTwilioDevice(this.capToken)
        await this.deviceReady

        this.phoneNumber = phoneNumber
        this.callStatus = CallStatus.Calling

        console.log("Twilio device.connect", phoneNumber, callerId)
        this.connection = this.device.connect({number: phoneNumber, caller_id: callerId})
    }

    finishCall () {
        if (this.callStatus == CallStatus.Idle) {
            throw new Error("Call not in progress")
        }

        this.connection.disconnect()

        this.callStatus = CallStatus.Idle
        this.phoneNumber = null
        this.capToken = null
    }

    initTwilioDevice (capToken) {
        if (this.device) {
            return
        }

        // According to the Twilio API docs this method should be invoked from
        // a path triggered by a user action, as browsers might otherwise not
        // allow audio to be initialized.
        this.device = new Device(capToken)

        this.device.on('ready', this.onDeviceReady)
        this.device.on('connect', this.onDeviceConnect)
        this.device.on('disconnect', this.onDeviceDisconnect)
        this.device.on('error', this.onDeviceError)

        this.deviceReady = new Promise((resolve, reject) => {
            this.device.on('ready', resolve)
        })
    }

    @autobind
    onDeviceReady (device) {
        console.log("Twilio device ready")
    }

    @autobind
    onDeviceConnect (conn: Connection) {
        console.log("Twilio connected", conn)
        this.callStatus = CallStatus.Connected
    }

    @autobind
    onDeviceDisconnect (conn: Connection) {
        console.log("Twilio disconnected", conn)
        this.connection = null
        this.destroyTwilioDevice()
        this.callStatus = CallStatus.Idle
    }

    @autobind
    onDeviceError (error) {
        console.log("Twilio error", error)
    }

    destroyTwilioDevice () {
        if (!this.device) {
            return
        }

        this.device.destroy()
        this.device = null
    }

    async getCapToken () {
        let response = await this.apiClient.makeCall('POST', '/voice-calls/generate-token')
        return (response.data as GetCapTokenResponse).token
    }
}

import * as React from 'react'
import autobind from 'autobind-decorator'
import {inject, observer} from 'mobx-react'
import {AppState} from "../../state/app"
import {UIState} from '../../state/ui'
import {Claim} from "../../state/entities/Claim";
import {Alert, Badge, Button, Nav, NavItem} from 'reactstrap'
import {Route, Switch} from 'react-router'
import {ClaimData} from './ClaimData'
import {NavLink} from '../NavLink'
import * as moment from 'moment'
import {Messaging} from '../messaging/Messaging'
import ActivateAtModal from './ActivateAtModal'

import './ClaimDetails.scss'
import {PaymentData} from './PaymentData'
import {AssignClaimModal} from '../claims/AssignClaimModal'
import {Party} from '../../state/entities/Common'


interface ClaimDetailsProps {
    appState: AppState,
    uiState: UIState,
    case_: Claim
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
}

function renderMessagingTab (props) {
    let {id, party, messageId} = props.match.params

    return <Messaging claimId={id} party={party} messageId={messageId}/>
}

@inject('appState', 'uiState')
@observer
export class ClaimDetails extends React.Component<ClaimDetailsProps> {

    componentDidMount () {
        this.props.appState.claimDetailsStore.load(this.props.match.params.id)
    }

    render () {
        const claim = this.props.appState.claimDetailsStore.claim
        const {path, url} = this.props.match

        if (!claim) {
            return <div>
                Loading...
            </div>
        }

        const pastActivateAt = claim.activate_at && claim.activate_at < new Date()

        return <div className="claim-details">

            <h1 className="title-main">
                Claim No.:
                {" "}
                <span className="title-main-claim">
                    {claim.case_id_human} [{(claim.language || 'en').toUpperCase()}] {" "}
                </span>
                <span className="claim-status">
                    {claim.statusLabel}
                    {claim.activate_at && !pastActivateAt ? " / on hold" : null}
                </span>
                {/* <Badge color="info" pill>
                </Badge> */}
            </h1>

            <div className="assignee mb-2 mt-3">
                <span className="claim-header-label">Assigned to</span>
                
                {" "}

                <Button
                    onClick={this.onAssignClick}
                    className="claim-header-action claim-header-action--c_blue"
                >
                    Assign
                </Button>

                {" "}
                :
                {" "}

                { claim.assignee ?
                    <span>{claim.assignee.first_name} {claim.assignee.last_name}</span>
                    :
                    <span>Unassigned</span>
                }
            </div>

            <div className="next-activity">
                <span className="claim-header-label">
                    Next activity
                </span>

                {" "}

                <Button
                    onClick={this.onSetActivateAtClick}
                    className="claim-header-action claim-header-action--c_yellow"
                >
                    Set
                </Button>

                {" "}
                :
                {" "}

                <span className="date-memo-record">
                    <span className="date">
                        {
                            claim.activate_at ?
                                moment.utc(claim.activate_at).format('DD.MM.YYYY')
                                :
                                'N/A'
                        }
                    </span>

                    <span>
                        {claim.activate_at && claim.activate_memo &&
                            <span className="memo">
                                {claim.activate_memo}
                            </span>
                        }

                        {
                            claim.activate_at ?
                                <Button close onClick={this.onClearActivateAtClick} />
                                //     Clear
                                // </Button>
                                :
                                null
                        }
                    </span>

                </span>
            </div>

            { this.renderFlagComment(claim) }

            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink to={`${url}/details`}>
                            Details
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink to={`${url}/client-email`}>
                            Client messages
                            {" "} { this.renderUnreadCountBadge(Party.CLIENT) }
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink to={`${url}/airline-email`}>
                            Airline messages
                            {" "} { this.renderUnreadCountBadge(Party.AIRLINE) }
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink to={`${url}/regulator-email`}>
                            Regulator messages
                            {" "} { this.renderUnreadCountBadge(Party.REGULATOR) }
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink to={`${url}/payments`}>
                            Payments
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>

            <div className="tab-content">
                <Switch>
                    <Route path={`${path}/details`} component={ClaimData}/>
                    <Route
                        path={`${path}/:party(airline|client|regulator)-email/:messageId`}
                        render={renderMessagingTab}
                    />
                    <Route
                        path={`${path}/:party(airline|client|regulator)-email`}
                        render={renderMessagingTab}
                    />
                    <Route path={`${path}/payments`} component={PaymentData}/>
                </Switch>
                {this.props.appState.claimDetailsStore.loading ? <div className="loading"></div> : null}
            </div>
        </div>
    }

    renderFlagComment = (claim) => {
        if (claim && claim.flagged) {
            return <Alert color="warning">
                <b>Flagged:<br/>{claim.flagged_comment}</b>
            </Alert>
        }
    }

    @autobind
    onSetActivateAt (activateAt: Date | null, memo: string | null) {
        this.props.uiState.closeModal()

        this.props.appState.claimDetailsStore.setActivateAt(activateAt, memo)
    }

    @autobind
    async onClearActivateAtClick () {
        await this.props.appState.claimDetailsStore.setActivateAt(null, null)
    }

    @autobind
    onSetActivateAtClick (e) {
        this.props.uiState.showModal(
            <ActivateAtModal
                claim={this.props.appState.claimDetailsStore.claim}
                setActivateAt={this.onSetActivateAt}
                cancel={this.props.uiState.closeModal}
            />
        )
    }

    @autobind
    onAssignClick (e) {
        e.preventDefault()

        let uiState = this.props.uiState
        let claim = this.props.appState.claimDetailsStore.claim

        this.props.uiState.showModal(
            <AssignClaimModal claim={claim} close={uiState.closeModal}/>
        )
    }

    private renderUnreadCountBadge(party: Party) {
        const claim = this.props.appState.claimDetailsStore.claim

        if (!claim.unread_counts[party]) {
            return null
        }

        return <Badge>{claim.unread_counts[party]}</Badge>
    }
}

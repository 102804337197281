import * as React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import {observer, inject} from 'mobx-react'
import {UIState} from '../state/ui'


@inject('uiState')
@observer
export class ConfirmationDialog extends React.Component<{uiState?: UIState}> {
    render () {
        let conf = this.props.uiState.confirmationRequest

        if (!conf) {return null}

        let setResult = this.props.uiState.setConfirmationResult

        return <Modal isOpen={true}>
            <ModalHeader toggle={() => setResult(false)}>{conf.title}</ModalHeader>
            <ModalBody>{conf.body}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => setResult(true)}>OK</Button>{' '}
                <Button color="secondary" onClick={() => setResult(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

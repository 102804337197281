import * as React from 'react'
import {
    Navbar, NavbarBrand, NavItem, Nav,
    Container, Row, Col,
    Form, Input,
    Badge
} from 'reactstrap'
import {Router, Switch, Route, Redirect} from 'react-router'

import {ClaimDetails} from '../case-details/ClaimDetails'
import {ClaimList} from '../claim-list/ClaimList'
import {ClaimsSidebar} from './ClaimsSidebar'
import { inject, observer } from 'mobx-react'
import { UIState } from '../../state/ui';


function renderClaimList (props) {
    return <ClaimList key={props.match.params.set} claimSet={props.match.params.set}/>
}

interface Props {
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
    uiState?: UIState;
}

@inject('uiState')
@observer
export class ClaimsModule extends React.Component<Props> {
    render () {

        let sidebarClassName = this.props.uiState.isSidebarOpened ? 'sidebar-opened' : ''
        return <>
            <Col md="2" className={`sidebar ${sidebarClassName}`}>
                <ClaimsSidebar/>
            </Col>

            <Col md="10" className="content">
                <Switch>
                    <Route exact path="/claims" component={ClaimList} />
                    <Route path="/claims/:set([a-z\-_+]+\d$|[a-z\-_+]+)" render={renderClaimList} />
                    <Route path="/claims/:id" component={ClaimDetails}/>
                </Switch>
            </Col>
        </>
    }
}
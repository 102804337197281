import autobind from 'autobind-decorator'
import {observer, inject} from 'mobx-react'
import * as React from 'react'
import {AppState} from '../../state/app'
import {Button} from 'reactstrap'
import {CallStatus} from '../../state/voice-call'

import './CallControls.scss'


@inject('appState')
@observer
export default class CallControls extends React.Component<{appState?: AppState}> {
    render () {
        if (this.props.appState.voiceCallStore.callStatus == CallStatus.Idle) {
            return null
        }

        return <div className="voice-call-controls">
            <div>
                ACTIVE CALL TO {this.props.appState.voiceCallStore.phoneNumber}
            </div>
            <Button block color="danger" onClick={this.onFinishClick}>Hang up</Button>
        </div>
    }

    @autobind
    onFinishClick (e) {
        e.preventDefault()

        this.props.appState.voiceCallStore.finishCall()
    }
}

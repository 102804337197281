import * as React from 'react'
import {
    Navbar, NavbarBrand, NavItem, Nav,
    Container, Row, Col,
    Form, Input,
    Badge
} from 'reactstrap'
import {Router, Switch, Route, Redirect} from 'react-router'

import {TemplatesSidebar} from './TemplatesSidebar'
import {PaymentList} from '../payment/PaymentList'
import {TemplateEditor} from './TemplateEditor'


function WrapTemplateEditor (props) {
    let id = props.match.params.id
    let key = id

    if (id == 'new') {
        // To force rerender when "new template" is clicked
        key = `new-${new Date().getTime()}`
        id = null
    }

    return <TemplateEditor key={key} id={id}/>
}


const NoTemplateSelected = () => <h4 className="text-muted text-center pt-5">
    Please select a template or create a new one
</h4>


interface Props {
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
}

export class TemplatesModule extends React.Component<Props> {
    render () {
        return <>
            <Col md="2" className="sidebar">
                <TemplatesSidebar/>
            </Col>

            <Col md="10" className="content">
                <Switch>
                    <Route path="/templates" exact component={NoTemplateSelected}/>
                    <Route path="/templates/:id" component={WrapTemplateEditor} />
                </Switch>
            </Col>
        </>
    }
}

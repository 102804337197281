import {Entity} from './Entity'
import {observable} from 'mobx'


export class Template extends Entity {
    @observable created_at: Date
    @observable updated_at: Date
    @observable name: string
    @observable content: string
    @observable notes: string

    constructor (data) {
        super(data)
        this.parseDates(data, ['created_at', 'updated_at'])
    }
}

import * as qs  from 'qs'
import {observable} from 'mobx'
import autobind from 'autobind-decorator'
import {FieldState, FormState} from 'formstate'
import {ApiClient} from '../api/client'

import {Claim} from './entities/Claim'
import {User} from './entities/User'
import {AppState} from './app'
import {Country} from './entities/Country'
import {Airline} from './entities/Airline'
import * as moment from 'moment'

interface ExportClaimsResponse {
    file?: {
        name: string
        content_type: string
        base64: string
    }
}

interface ClaimStatistics {
    count: number;
    passengers: number;
    compensation: number;
    today_count: number;
    today_passengers: number;
    today_compensation: number;
}

interface ImportClaimsResponse {
    updated_claim_count: number
}

interface PaginatedData {
    results: Claim[]
    count: number
}

type ClaimListFilterFormProps = {
    departure_country: FieldState<Country>
    arrival_country: FieldState<Country>
    airline: FieldState<Airline>
    date_received_from: FieldState<Date>
}

export class ClaimListFilterState extends FormState<ClaimListFilterFormProps> {
    constructor () {
        super({
            departure_country: new FieldState<Country>(null),
            arrival_country: new FieldState<Country>(null),
            airline: new FieldState<Airline>(null),
            date_received_from: new FieldState<Date>(null),
        })
    }
}


export class ClaimListStore {
    apiClient: ApiClient
    @observable claimSet: string | null
    searchQuery: string | null
    @observable cases: Claim[]
    @observable loading = false
    parent: AppState
    @observable page = 0
    @observable pageSize = 20
    @observable count = 0
    @observable sortField = null
    @observable sortAscending = true
    @observable exportClaimListColumns = []
    @observable exportClaimListRows = []
    @observable claimStatistics: ClaimStatistics = {
        count: 0,
        passengers: 0,
        compensation: 0,
        today_count: 0,
        today_passengers: 0,
        today_compensation: 0,
    }

    @observable filters = new ClaimListFilterState()

    constructor(parent: AppState, apiClient: ApiClient) {
        this.parent = parent
        this.cases = []
        this.apiClient = apiClient
    }

    buildAPIQueryParams () {
        let queryParams = {
            limit: 20,
            offset: this.page * this.pageSize,
        }

        if (this.claimSet) {
            queryParams["set"] = this.claimSet
        }

        if (this.searchQuery) {
            queryParams["search"] = this.searchQuery
        }

        if (this.sortField) {
            let direction = this.sortAscending ? '' : '-'
            queryParams["order"] = `${direction}${this.sortField}`
        }

        if (this.filters.$.departure_country.value) {
            queryParams["departure_country"] = this.filters.$.departure_country.value.code
        }

        if (this.filters.$.arrival_country.value) {
            queryParams["arrival_country"] = this.filters.$.arrival_country.value.code
        }

        if (this.filters.$.airline.value) {
            queryParams["airline"] = this.filters.$.airline.value.iata_code
        }

        if (this.filters.$.date_received_from.value) {
            queryParams["created_at_after"] = moment(
                this.filters.$.date_received_from.value
            ).format('YYYY-MM-DD')
        }

        return qs.stringify(queryParams)
    }

    async load() {
        this.loading = true
        const response = await this.apiClient.makeCall(
            'GET',
            `/cases?${this.buildAPIQueryParams()}`
        )

        let {results, count} = response.data as PaginatedData
        this.cases = results.map(c => new Claim(c))
        this.count = count
        this.loading = false
    }

    async reload() {
        this.cases = []
        this.page = 0
        this.load()
    }

    async exportClaims() {
        this.loading = true
        const response = await this.apiClient.makeCall(
            'POST',
            `/cases/export-claims`,
            {
                data: {
                    set: this.claimSet || '',
                    columns: this.exportClaimListColumns,
                    rows: this.exportClaimListRows,
                }
            }
        )
        if ((response.data as ExportClaimsResponse).file) {
            const {
                name: filename,
                content_type: contentType,
                base64
            } = (response.data as ExportClaimsResponse).file
            const sliceSize = 1024;
            const byteCharacters = atob(base64);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);
            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                const begin = sliceIndex * sliceSize;
                const end = Math.min(begin + sliceSize, bytesLength);
            
                const bytes = new Array(end - begin);
                for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            const url = window.URL.createObjectURL(new Blob(byteArrays, { type: contentType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}_${this.claimSet ? this.claimSet : 'all'}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }
        this.loading = false
    }

    async importClaims(upload: string) {
        const response = await this.apiClient.makeCall(
            'POST',
            `/cases/import-claims`,
            { data: { upload } }
        )
        return (response.data as ImportClaimsResponse).updated_claim_count
    }

    async getClaimStatistics() {
        let queryParams = {}
        if (this.claimSet) {
            queryParams["set"] = this.claimSet
        }
        const response = await this.apiClient.makeCall(
            'GET',
            `/claims/claim-statistics?${qs.stringify(queryParams)}`
        )
        this.claimStatistics = response.data as ClaimStatistics
    }

    setSearchQuery(q) {
        this.searchQuery = q
        this.reload()
    }

    @autobind
    changePage(page) {
        this.page = page-1
        this.load()
    }

    async assign(claim: Claim, assignee: User | null) {
        if (!assignee) {
            assignee = this.parent.auth.user
        }

        let result = await this.apiClient.makeCall(
            'PATCH', `/claims/${claim.id}`, {data: {assignee}}
        )

        if (result.success) {
            claim.assignee = assignee
        }
    }

    setSort(field: string | null, ascending: boolean = true) {
        this.sortField = field
        this.sortAscending = ascending
        this.load()
    }

    get isFiltered () : boolean {
        for (let field in this.filters.$) {
            if (!!this.filters.$[field].value) {
                return true
            }
        }

        return false
    }

    resetFilters () {
        this.filters = new ClaimListFilterState()
    }

    setExportClaimListColumns (exportClaimListColumns: string[]) {
        this.exportClaimListColumns = exportClaimListColumns
    }

    setExportClaimListRows (exportClaimListRows: number[]) {
        this.exportClaimListRows = exportClaimListRows
    }
}

import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input} from 'reactstrap'
import {AirlineEmailContentClass, AppState} from '../../state/app'
import {UIState} from '../../state/ui'
import autobind from 'autobind-decorator'
import {getContentClassLabel} from '../task-details/ProcessAirlineEmailTaskDetails'
import {EmailMessage} from '../../state/entities/EmailMessage'


interface Props {
    message: EmailMessage
    appState?: AppState
    uiState?: UIState
    close: Function
}


@inject('appState')
@observer
export class ClassifyEmailContentModal extends React.Component<Props> {
    state = {
        selected: AirlineEmailContentClass.IRRELEVANT
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={(evt: React.MouseEvent) => this.props.close()}>Classify email content</ModalHeader>

            <ModalBody>
                {
                    Object.keys(AirlineEmailContentClass).map(
                        (k) => <FormGroup check key={k}>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="content_class"
                                    value={k}
                                    checked={AirlineEmailContentClass[k] == this.state.selected}
                                    onChange={() => this.setState({selected: AirlineEmailContentClass[k]})}
                                />
                                {' '} {getContentClassLabel(AirlineEmailContentClass[k])}
                            </Label>
                        </FormGroup>
                    )
                }
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onClassifyClick}>Classify</Button>{' '}
                <Button color="secondary" onClick={(evt: React.MouseEvent) => this.props.close()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }

    @autobind
    async onClassifyClick (e) {
        e.preventDefault()

        await this.props.appState.messageListStore.classify(
            this.props.message,
            this.state.selected
        )

        this.props.close()
    }
}

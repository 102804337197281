import * as moment from 'moment'


export function formatDateTime (d: Date) {
    return moment.utc(d).format('DD.MM.YYYY HH:mm')
}


export function formatDate (d: Date) {
    return moment.utc(d).format('DD.MM.YYYY')
}

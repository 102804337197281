import * as React from 'react'
import * as qs  from 'qs'
import autobind from 'autobind-decorator'
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap'
import {observer, inject} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from "../../state/ui"
import CountryAutocomplete from '../common/CountryAutocomplete'
import AirlineAutocomplete from '../common/AirlineAutocomplete'
import {DatePicker} from 'react-widgets'
import {Modal} from '../common/Modal'


@inject('appState')
@inject('uiState')
@observer
export class ClaimListFiltersModal extends React.Component<{appState?: AppState, uiState?: UIState}> {

    @autobind
    onDepartureCountryChange (selection: object[]) {
        let store = this.props.appState.claimListStore

        let selValue = null
        if (selection.length) {
            selValue = selection[0]
        }

        store.filters.$.departure_country.onChange(selValue)
        store.load()
    }

    @autobind
    onArrivalCountryChange (selection: object[]) {
        let store = this.props.appState.claimListStore

        let selValue = null
        if (selection.length) {
            selValue = selection[0]
        }

        store.filters.$.arrival_country.onChange(selValue)
        store.load()
    }

    @autobind
    onAirlineChange (selection: object[]) {
        let store = this.props.appState.claimListStore

        let selValue = null
        if (selection.length) {
            selValue = selection[0]
        }

        store.filters.$.airline.onChange(selValue)
        store.load()
    }

    @autobind
    onDateReceivedFromChange(value: Date) {
        let store = this.props.appState.claimListStore

        store.filters.$.date_received_from.onChange(value)
        store.load()
    }

    @autobind
    async onApplyFilters () {
        this.props.appState.claimListStore.reload()
        this.props.uiState.closeModal()

        return true
    }

    render () {
        let form = this.props.appState.claimListStore.filters.$

        return <Modal title="Filter claims" actionLabel="Apply" onAction={this.onApplyFilters}>
            <Row>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">Departure country:</Label>
                        <CountryAutocomplete
                            id="departureCountryAC"
                            key={
                                form.departure_country.value ?
                                    form.departure_country.value.id : 'empty-departure-country'
                            }
                            selected={form.departure_country.value}
                            onChange={this.onDepartureCountryChange}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Arrival country:</Label>
                        <CountryAutocomplete
                            id="arrivalCountryAC"
                            key={
                                form.arrival_country.value ?
                                    form.arrival_country.value.id : 'empty-arrival-country'
                            }
                            selected={form.arrival_country.value}
                            onChange={this.onArrivalCountryChange}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Airline:</Label>
                        <AirlineAutocomplete
                            id="airlineAC"
                            key={
                                form.arrival_country.value ?
                                    form.arrival_country.value.id : 'empty-airline'
                            }
                            selected={form.airline.value}
                            onChange={this.onAirlineChange}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Date received from:</Label>
                        <DatePicker
                            name="settled_at"
                            format="DD.MM.YYYY"
                            onChange={this.onDateReceivedFromChange}
                            value={form.date_received_from.value}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </Modal>
    }
}

import * as React from 'react'
import autobind from 'autobind-decorator'
import {observable, computed} from "mobx"
import { createBrowserHistory } from 'history'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import {toast, ToastOptions} from 'react-toastify'
import * as qs  from 'qs'
import {ErrorData} from '../api/client'


// TODO: Global loading indicator

export enum NotificationType {
    Info,
    Success,
    Warning,
    Error,
}


const TOAST_TYPE_MAP = {
    [NotificationType.Info]: toast.TYPE.INFO,
    [NotificationType.Success]: toast.TYPE.SUCCESS,
    [NotificationType.Warning]: toast.TYPE.WARNING,
    [NotificationType.Error]: toast.TYPE.ERROR,
}

interface ConfirmationRequest {
    title: string
    body: string
    resolve: (boolean) => void
}


function flattenObject(source, output = {}, keyPrefix = '') {
    function getNextKey(key) {
        return `${keyPrefix}${keyPrefix ? '.' : ''}${key}`
    }

    if (typeof source === 'object') {
        for (const key in source) {
            flattenObject(source[key], output, getNextKey(key))
        }
    } else {
        output[keyPrefix] = source
    }
    return output
}


@autobind
export class UIState {
    routing: RouterStore
    history
    @observable modal: React.Component = null

    @observable confirmationRequest: ConfirmationRequest = null

    @observable isSidebarOpened: boolean = false
    @observable isMenuOpen: boolean = false

    constructor () {
        const browserHistory = createBrowserHistory()
        this.routing = new RouterStore()
        this.history = syncHistoryWithStore(browserHistory, this.routing)
    }

    showNotification (message, type=NotificationType.Info, persistent=false) {
        let opts: ToastOptions = {
            type: TOAST_TYPE_MAP[type]
        }

        if (persistent) {
            opts.autoClose = false
        }

        toast(message, opts)
    }

    @computed
    get currentLocation() : string {
        let uri = this.history.location.pathname

        if (this.history.location.search) {
            uri += this.history.location.search
        }

        return encodeURI(uri)
    }

    @computed
    get currentSearchParams() : object {
        return qs.parse(this.history.location.search, {ignoreQueryPrefix: true})
    }

    apiErrorCallback (method, path, data: ErrorData, response) {
        // TODO: Very crude, will only show the first error message without indicating a field,
        // TODO: potentially puzzling for the user
        let msg = Object.values(flattenObject(data.details))[0]
        this.showNotification(msg, NotificationType.Error)
    }

    showModal (modal) {
        this.modal = modal
    }

    closeModal () {
        this.modal = null
    }

    showSidebar () {
        this.isSidebarOpened = true
    }

    closeSidebar () {
        this.isSidebarOpened = false
    }

    @autobind
    setConfirmationResult (result: boolean) {
        if (!this.confirmationRequest) {
            throw new Error("No confirmation request active")
        }

        this.confirmationRequest.resolve(result)
        this.confirmationRequest = null
    }

    async requestConfirmation (title, body) : Promise<boolean> {
        if (this.confirmationRequest) {
            throw new Error("Concurrent confirmation requests not supported")
        }

        let resolve
        let result = new Promise(_resolve => resolve = _resolve) as Promise<boolean>

        this.confirmationRequest = {title, body, resolve}

        return result
    }
}

import * as React from "react";
import {inject, observer} from 'mobx-react'
import {NavLink as RouterNavLink} from 'react-router-dom'
import {NavLink as ReactstrapNavLink} from 'reactstrap'
import {UIState} from '../state/ui'


@inject('uiState')
@observer
export class NavLink extends React.Component<RouterNavLink|{uiState?: UIState}> {
    render () {
        // Reference location to make observer trigger re-render on change
        let dummy = this.props.uiState.routing.location

        // Silence console error
        let childProps : object = Object.assign({}, this.props)
        delete childProps['uiState']

        return <ReactstrapNavLink activeClassName="active" tag={RouterNavLink} {...childProps}>
            {this.props.children}
        </ReactstrapNavLink>
    }
}

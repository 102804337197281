import * as React from 'react'
import * as moment from 'moment'
import {inject, observer} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from '../../state/ui'
import {Payment, PaymentDetails} from '../../state/entities/Payment'
import Field from './Field'
import {Party} from '../../state/entities/Common'
import {Alert} from 'reactstrap'
import {PaymentStatus} from '../../state/entities/Payment'
import {RegisterPaymentModal} from '../payment/RegisterPaymentModal'
import ActionButton from "../task-details/ActionButton"


interface PaymentDataProps {
    appState: AppState,
    uiState: UIState,
}


@inject('appState', 'uiState')
@observer
export class PaymentData extends React.Component<PaymentDataProps, {requestingBankDetails: boolean} > {
    state = {
        requestingBankDetails: false
    }

    render () {

        let c = this.props.appState.claimDetailsStore.claim

        let req = c.payment_details_requests?.length ? c.payment_details_requests[0] : null

        let paymentsIn = c.payments.filter(i => i.to_party == Party.SERVICE)
        let paymentsOut = c.payments.filter(i => i.from_party == Party.SERVICE)

        let canRequestBankDetails = (!req && paymentsIn.length)

        return <div className="payment-data">
            <Field label="Client bank details">
                {
                    !req

                    ?

                    <div>
                        Client bank details not requested yet
                        {" "}
                        <ActionButton
                            executing={this.state.requestingBankDetails}
                            disabled={!canRequestBankDetails}
                            onInvoke={() => this.onRequestBankDetailsClick()}
                            label="Request bank details"
                        />
                    </div>

                    :

                    <div>
                        {
                            !req.payment_details

                            ?

                            <div>
                                Payment details request sent, details not submitted yet
                            </div>

                            :

                            <div>
                                <div className="mt-1 mb-1">
                                    <b>Account:</b> {req.payment_details.account_number} <br/>
                                </div>

                                {
                                    req.payment_details.holder_name
                                    ?
                                    <div className="mt-1 mb-1">
                                        <b>Account holder:</b> {req.payment_details.holder_name}
                                    </div>
                                    :
                                    null
                                }

                                {
                                    req.payment_details.holder_address
                                    ?
                                    <div className="mt-1 mb-1">
                                        <b>Account holder address:</b>
                                        <pre>{req.payment_details.holder_address}</pre>
                                    </div>
                                    :
                                    null
                                }

                                {
                                    req.payment_details.bank_code
                                    ?
                                    <div className="mt-1 mb-1">
                                        <b>Bank code:</b> {req.payment_details.bank_code}
                                    </div>
                                    :
                                    null
                                }

                                {
                                    req.payment_details.bank_name
                                    ?
                                    <div className="mt-1 mb-1">
                                        <b>Bank name:</b> {req.payment_details.bank_name}
                                    </div>
                                    :
                                    null
                                }

                                
                                <div className="mt-1 mb-1">
                                    <b>Editing payment details:</b>
                                    
                                    {' '}

                                    <a href="#" onClick={
                                        () => this.onChangeAllowEditingPaymentDetailsClick(req.payment_details)
                                    }>
                                        {req.payment_details.allow_editing ? 'Disable' : 'Enable'}
                                    </a>
                                </div>
                                
                            </div>

                        }
                    </div>
                }

            </Field>

            <Field label="Incoming compensation payment">
                {
                    !paymentsIn
                    ?
                    <div>No payments received yet</div>
                    :
                    paymentsIn.map(
                        i => <div key={i.id} className="mb-2">
                            <div className="mt-1 mb-1"><b>Settled on:</b> {moment.utc(i.settled_at).format('DD.MM.YYYY')}</div>
                            <div className="mt-1 mb-1"><b>Amount:</b> {i.amount} {i.currency}</div>
                            <div className="mt-1 mb-1"><b>From account:</b> {i.from_account_number}</div>
                            <div className="mt-1 mb-1"><b>To account:</b> {i.to_account_number}</div>
                            <div className="mt-1 mb-1"><b>Transfer details:</b> {i.details}</div>
                            <div className="mt-1 mb-1"><b>Edit the payment:</b><a href="#" onClick={() => this.onClick(event, i)}> Edit</a></div>
                        </div>
                    )
                }
            </Field>

            <Field label="Outgoing compensation payment">
                {
                    !paymentsOut
                    ?
                    <div>No commission transferred yet</div>
                    :
                    paymentsOut.map(
                        i => <div key={i.id} className="mb-2">
                            {i.status == PaymentStatus.SETTLED ?
                                <div className="mt-1 mb-1"><b>Settled on:</b> {moment.utc(i.settled_at).format('DD.MM.YYYY')}</div>
                                :
                                <Alert color="warning" className="d-inline-block">Pending</Alert>
                            }

                            <div className="mt-1 mb-1"><b>Amount:</b> {i.amount} {i.currency}</div>
                            <div className="mt-1 mb-1"><b>From account:</b> {i.from_account_number}</div>
                            <div className="mt-1 mb-1"><b>To account:</b> {i.to_account_number}</div>
                            <div className="mt-1 mb-1"><b>Transfer details:</b> {i.details}</div>
                            <div className="mt-1 mb-1"><b>Edit the payment:</b><a href="#" onClick={() => this.onClick(event, i)}> Edit</a></div>
                        </div>
                    )
                }

            </Field>
        </div>
    }

    onClick (e, payment) {
        e.preventDefault()

        payment.claim = this.props.appState.claimDetailsStore.claim
        let objPayment = new Payment(payment)

        this.props.uiState.showModal(
            <RegisterPaymentModal
                fromParty={objPayment.from_party}
                close={this.props.uiState.closeModal}
                initial={objPayment}
                isClaimPage={true}
            />
        )
    }

    onChangeAllowEditingPaymentDetailsClick (payment_details: PaymentDetails) {
        this.props.appState.claimDetailsStore.changeAllowEditingPaymentDetails(
            payment_details.id,
            payment_details.allow_editing
        )
    }

    async onRequestBankDetailsClick () {
        let store = this.props.appState.claimDetailsStore
        this.setState({requestingBankDetails: true})
        await store.requestBankDetails()
        this.setState({requestingBankDetails: false})
    }
}

import * as React from "react"
import autobind from "autobind-decorator"
import {inject, observer} from "mobx-react"
import * as Moment from "moment"
const momentLocalizer = require('react-widgets-moment')
import { DatePicker } from 'react-widgets'

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input,
    Alert
} from 'reactstrap'

import {AppState, DeliverClaimNoticeTask, TaskStatus} from "../../state/app"
import {UIState} from "../../state/ui"
import ActionButton from "./ActionButton"

import 'react-widgets/dist/css/react-widgets.css'
import {ActionAssigneeIcon} from './ActionAssigneeIcon'

Moment.locale('en')
Moment.updateLocale('en', {week : {dow : 1, doy : 4}});  // Start week on Monday
momentLocalizer()


const DELIVER_ACTION = 'deliver_notice'
const REGISTER_DELIVERY_ACTION = 'register_manual_delivery'

const DELIVERY_METHODS = [
    {id: 'form', label: 'Airline claim form submitted manually'},
    {id: 'mail', label: 'Paper claim sent via mail'}
]


@inject('appState')
@inject('uiState')
@observer
export default class DeliverClaimNoticeDetails extends React.Component
<{appState?: AppState, uiState?: UIState, task: DeliverClaimNoticeTask}>
{
    state = {
        sending: false,
        registering: false,
    }
    render () {
        const task = this.props.task

        let title = 'Deliver initial claim notice'
        let severity = this.props.task.severity

        if (severity == 1) {
            title = 'Deliver 2nd notice'
        } else if (severity == 2) {
            title = 'Deliver 3rd notice letter'
        }

        if (!task) {
            return <div>Loading...</div>
        }

        return <div className="generic-task-details">
            <ActionAssigneeIcon user={task.assignee}/>

            <span>{title}</span>

            {this.props.task.status == TaskStatus.PENDING ?
                <div className="task-actions">
                    <ActionButton
                        label="Deliver notice"
                        onInvoke={this.onSend}
                        executing={this.state.sending}
                        disabled={this.state.sending || this.state.registering}
                    />
                    <ActionButton
                        label="Register manual delivery"
                        onInvoke={this.onRegisterManualDelivery}
                        color="link"
                        executing={this.state.registering}
                        disabled={this.state.sending || this.state.registering}
                    />
                </div>
                :
                <div>Delivery method: {this.props.task.method}</div>
            }

        </div>
    }

    @autobind
    async onSend () {
        this.setState({sending: true})
        await this.props.appState.taskStore.perform(this.props.task, DELIVER_ACTION)
        this.setState({sending: false})

        await this.props.appState.claimDetailsStore.reload()
    }

    @autobind
    onRegisterManualDelivery () {
        this.props.uiState.showModal(
            <RegisterDeliveryModal
                cancel={this.props.uiState.closeModal}
                registerDelivery={this.registerDelivery}
            />
        )
    }

    @autobind
    async registerDelivery (method, date) {
        console.log("registerDelivery")
        this.setState({registering: true})
        await this.props.appState.taskStore.perform(
            this.props.task,
            REGISTER_DELIVERY_ACTION,
            {method, date}
        )
        this.setState({registering: false})
        this.props.uiState.closeModal()
        await this.props.appState.claimDetailsStore.reload()
    }
}


class RegisterDeliveryModal extends React.Component
<{cancel: () => void, registerDelivery: (mode: string, date: Date) => void }>
{
    state = {
        method: 'form',
        date: new Date(),

        error: null
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={this.props.cancel}>Register manual delivery</ModalHeader>

            <ModalBody>
                <FormGroup>
                    <legend>Delivery method</legend>

                    {DELIVERY_METHODS.map((i) =>
                        <FormGroup key={i.id} check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="delivery_method"
                                    value={i.id}
                                    checked={this.state.method == i.id}
                                    onChange={() => this.onMethodChange(i.id)}
                                />{' '}
                                {i.label}
                            </Label>
                        </FormGroup>

                    )}
                </FormGroup>

                <FormGroup>
                    <legend>Delivery date</legend>
                    <DatePicker
                        defaultValue={this.state.date}
                        name="delivery-date"
                        format="DD.MM.YYYY"
                        onChange={this.onDeliveryDateChange}
                    />
                </FormGroup>

                { this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null }
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onRegisterClick}>Register delivery</Button>{' '}
                <Button color="secondary" onClick={this.props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }

    @autobind
    onDeliveryDateChange (date) {
        this.setState({date: date})
    }

    @autobind
    onMethodChange (method) {
        this.setState({method})
    }

    @autobind
    onRegisterClick () {
        if (!this.state.date) {
            this.setState({error: "Please select the delivery date"})
        } else {
            this.props.registerDelivery(this.state.method, this.state.date)
        }
    }
}

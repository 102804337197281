import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Button} from 'reactstrap'
import autobind from 'autobind-decorator'

import {AppState} from '../../state/app'
import {UIState} from '../../state/ui'
import {Passenger} from '../../state/entities/Passenger'

import { Spinner } from 'react-activity'
import PassengerEntry from './PassengerEntry'
import {EditPassengerModal} from './EditPassengerModal'

import './PassengerList.scss'


function comparePax (a: Passenger, b: Passenger) {
    let aId = [a.id, 0]
    let bId = [b.id, 0]

    if (a.guardian) { aId = [a.guardian, a.id] }
    if (b.guardian) { bId = [b.guardian, b.id] }

    if (aId > bId) return 1
    if (aId < bId) return -1
    return 0
}

@inject('appState', 'uiState')
@observer
export class PassengerList extends React.Component
<{passengers: Passenger[], appState?: AppState, uiState?: UIState}>
{
    @autobind
    onAddPassengerClick () {
        let newPax = this.props.appState.claimDetailsStore.initPassenger()
        this.props.uiState.showModal(<EditPassengerModal passenger={newPax} create={true}/>)
    }

    render () {
        let pax = this.props.passengers.slice()
        let submitter = this.props.appState.claimDetailsStore.claim.submitter
        pax.sort(comparePax)

        return <div className="passenger-list">
            {
                pax.map(
                    i => <PassengerEntry
                        key={i.id || Date.now()}
                        passenger={i}
                        isSubmitter={i.id === submitter.id}
                    />
                )
            }

            <Button className='add-passenger' onClick={this.onAddPassengerClick}>
                Add passenger
            </Button>

            {
                this.props.appState.claimDetailsStore.APICallInProgress ?
                    <ActionInProgressOverlay/> : null
            }

        </div>
    }
}


class ActionInProgressOverlay extends React.Component {
    render () {
        return <div className='action-in-progress'>
            <Spinner size={18}/> <span>Processing</span>
        </div>
    }
}

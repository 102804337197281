import * as React from 'react'
import {AppState} from '../../state/app'
import {inject, observer} from "mobx-react"
import {
    UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import DocumentLink from '../DocumentLink'

import './PassengerEntry.scss'
import autobind from 'autobind-decorator'
import {UIState} from '../../state/ui'
import {ClaimDocumentType} from '../../state/entities/Claim'
import {Passenger} from '../../state/entities/Passenger'
import {EditPassengerModal} from './EditPassengerModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {faSquare, faCheckSquare} from '@fortawesome/free-regular-svg-icons'
import {faFileSignature, faHourglassHalf} from '@fortawesome/free-solid-svg-icons'


interface Props {
    appState?: AppState,
    uiState?: UIState,
    passenger: Passenger,
    isSubmitter: boolean
}

@inject('appState')
@inject('uiState')
@observer
export default class PassengerEntry extends React.Component<Props>
{
    renderEmail() {
        let email = this.props.passenger.email
        if (!email) {
            email = "Email not set"
        }

        if (this.props.passenger.remove) {
            return <s>{email}</s>
        }

        return `(${email})`
    }

    renderClaimDocuments () {
        let p = this.props.passenger
        let poaClaimDoc = p.getClaimDocument(ClaimDocumentType.POA_SIGNED)
        let deedClaimDoc = p.getClaimDocument(ClaimDocumentType.ASSIGNMENT_DEED_SIGNED)
        if (!poaClaimDoc && !deedClaimDoc) {
            return null
        }

        return <div className="documents">
            {poaClaimDoc ?
                <div>
                    Power of attorney: {" "}
                    <DocumentLink document={poaClaimDoc.document}/>
                </div>
                :
                null

            }

            {deedClaimDoc ?
                <div>
                    Assignment deed: {" "}
                    <DocumentLink document={deedClaimDoc.document}/>
                </div>
                :
                null
            }
        </div>
    }

    render () {
        let block = this.props.appState.claimDetailsStore.APICallInProgress
        let p = this.props.passenger

        let classNames = ['passenger-details']

        if (p.guardian) {
            classNames.push('ward')
        }

        if (this.props.isSubmitter) {
            classNames.push('submitter')
        }

        if (p.remove) {
            classNames.push('removed')
        }

        if (p.claimDocsSigned) {
            classNames.push('signed')
        }

        return <div className={classNames.join(" ")}>
            <span className="process-claim">
                <FontAwesomeIcon icon={p.process_claim ? faCheckSquare : faSquare} size="lg"/>
            </span>

            { !p.guardian ? <SignatureStatus passenger={p}/> : null }

            <span className="identity">
                {p.name} {" "} {this.renderEmail()} {p.is_child ? '- Child' : null}
            </span>

            {this.renderClaimDocuments()}

            <ActionMenu
                passenger={p}
                disabled={block}
                isSubmitter={this.props.isSubmitter}
            />

        </div>
    }
}


interface ActionMenuProps {
    uiState?: UIState,
    appState?: AppState,

    passenger: Passenger,
    disabled: boolean,
    isSubmitter: boolean
}

@inject('uiState', 'appState')
@observer
class ActionMenu extends React.Component<ActionMenuProps>
{

    @autobind
    onRemoveClick () {
        this.props.passenger.remove = !this.props.passenger.remove
    }

    @autobind
    onEditClick () {
        this.props.uiState.showModal(
            <EditPassengerModal passenger={this.props.passenger}/>
        )
    }

    @autobind
    async onDiscardSignedDocsClick () {
        let p = this.props.passenger

        let title = "Discard signed claim documents"
        let body = "Are you sure you want to discard the passengers signed claim documents?"

        if (await this.props.uiState.requestConfirmation(title, body)) {
            await this.props.appState.claimDetailsStore.discardSignedClaimDocuments(p)
        }
    }

    @autobind
    async onCancelPoaClick () {
        let p = this.props.passenger

        let title = "Cancel claim document signing request"
        let body = "Are you sure you want to cancel the passenger's claim document signing request?"

        if (await this.props.uiState.requestConfirmation(title, body)) {
            await this.props.appState.claimDetailsStore.cancelSignatureRequests(p)
        }
    }

    @autobind
    async onCheckSignatureStatus (reqId) {
        await this.props.appState.claimDetailsStore.checkPoaStatus(reqId)
    }

    @autobind
    async onRequestPoaClick (e) {
        e.preventDefault()
        await this.props.appState.claimDetailsStore.requestClaimDocumentSignature(this.props.passenger)
    }

    render () {
        let p = this.props.passenger
        let hasActiveSignatureRequests = (
            p.active_signature_requests && p.active_signature_requests.length > 0
        )

        let showRemove = !this.props.isSubmitter
        let showEdit = !p.remove
        let showSigRequest = (
            !p.guardian  // Guardian must sign for ward
            && p.email  // Need somewhere to send the request to
            && !p.claimDocsSigned  // Already have signed claim docs, discard those first
            && !hasActiveSignatureRequests  // Already have a pending request, cancel that first
        )

        let showDiscardDocs = p.claimDocsSigned
        let showSigCancel = !p.claimDocsSigned && hasActiveSignatureRequests

        return <UncontrolledButtonDropdown disabled={this.props.disabled}>
            <DropdownToggle caret disabled={this.props.disabled}>Action</DropdownToggle>
            <DropdownMenu>
                { showEdit ?
                    <DropdownItem onClick={this.onEditClick}>Edit</DropdownItem> : null }

                { showRemove ?
                    <DropdownItem onClick={this.onRemoveClick}>
                        {p.remove ? 'Undo remove' : 'Remove'}
                    </DropdownItem>
                    :
                    null
                }

                { showDiscardDocs ?
                    <DropdownItem onClick={this.onDiscardSignedDocsClick}>
                        Discard signed documents
                    </DropdownItem> : null }

                { showSigCancel ?
                    <DropdownItem onClick={this.onCancelPoaClick}>
                        Cancel signature request
                    </DropdownItem> : null }

                { showSigRequest ?
                    <DropdownItem onClick={this.onRequestPoaClick}>
                        Request signature
                    </DropdownItem> : null}

            </DropdownMenu>
        </UncontrolledButtonDropdown>
    }
}


@observer
class SignatureStatus extends React.Component<{passenger: Passenger}> {
    render () {
        let p = this.props.passenger

        let pending = p.active_signature_requests && p.active_signature_requests.length > 0
        pending = pending && !p.claimDocsSigned

        let title = "Claim documents not signed"
        if (p.claimDocsSigned) {
            title = "Claim documents signed"
        }
        else if (pending) {
            title = "Requested signature"
        }

        return <span className="signature-status">
            <span className="fa-layers fa-fw" title={title}>
                <FontAwesomeIcon
                    icon={faFileSignature}
                    size="lg"
                    color={p.claimDocsSigned ? "royalblue" : null}
                />
                { pending ?
                    <FontAwesomeIcon
                        icon={faHourglassHalf}
                        color="#444"
                        transform="shrink-3 right-8 up-8"
                    />
                    :
                    null
                }
            </span>
        </span>
    }
}

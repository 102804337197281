import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import {EmailAttachment, EmailMessage} from '../../state/entities/EmailMessage'
import {Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'

import './EmailMessageView.scss'
import {formatDateTime} from '../../util/date'
import {UIState} from '../../state/ui'
import {AddAttachmentToClaimDocumentsModal} from './AddAttachmentToClaimDocumentsModal'
import DocumentLink from '../DocumentLink'


@inject('uiState')
@observer
class AttachmentList extends React.Component<{message: EmailMessage, uiState?:UIState}> {

    @autobind
    onAddToClaimDocumentsClick (attachment: EmailAttachment) {
        this.props.uiState.showModal(<AddAttachmentToClaimDocumentsModal attachment={attachment}/>)
    }

    render () {
        return <div className="message-attachments">
            Attachments:<br/>
            {
                this.props.message.attachments.map(
                    i => <div key={i.id}>
                        <DocumentLink document={i.document}/>

                        <UncontrolledDropdown size="sm" className="d-inline-block">
                            <DropdownToggle caret color="link"/>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => this.onAddToClaimDocumentsClick(i)}
                                >
                                    Add to claim documents
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </div>
                )
            }
        </div>
    }
}


@observer
export class EmailMessageView extends React.Component<{message: EmailMessage}> {
    onBodyFrameLoad (e) {
        let doc = e.target.contentDocument

        let contentHeight = doc.body.scrollHeight
        if (contentHeight == 0 && doc.body.firstElementChild) {
            // If, for example, the email content is wrapped in a container element
            // with "position: absolute", the body will get a calculated height of 0.
            // Assume the first element will be the container and use its height.
            contentHeight = doc.body.firstElementChild.scrollHeight
        }

        // Reset the height, otherwise the reported iframe content height won't shrink
        // if previous message was longer
        e.target.height = 0

        // Fudge the height a little to prevent scrollbars inside the iframe
        e.target.height = contentHeight + 10 + 'px'
    }

    render () {
        let m = this.props.message

        return <div className="email-message-view">
            <div className="message-header">
                <Row>
                    <Col xs={2}>Received at:</Col>
                    <Col>{ formatDateTime(m.created_at) }</Col>
                </Row>

                <Row>
                    <Col xs={2}>From:</Col>
                    <Col>{ m.from_address }</Col>
                </Row>

                <Row>
                    <Col xs={2}>To:</Col>
                    <Col>{ m.to_address }</Col>
                </Row>

                <Row>
                    <Col xs={2}>Cc:</Col>
                    <Col>{ m.cc_address }</Col>
                </Row>

                <Row>
                    <Col xs={2}>Subject:</Col>
                    <Col>{ m.subject }</Col>
                </Row>
            </div>

            <iframe sandbox="allow-same-origin" src={m.body_url} onLoad={this.onBodyFrameLoad}></iframe>

            <div>

            </div>
            {this.renderAttachments()}
        </div>
    }

    renderAttachments () {
        let msg = this.props.message

        if (!msg.attachments || msg.attachments.length == 0) return null

        return <AttachmentList message={msg}/>
    }
}

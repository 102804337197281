import {ApiClient, ServerError} from '../api/client'
import {Template} from './entities/Template'
import {observable} from 'mobx'


export class TemplateStore {
    apiClient: ApiClient
    @observable templates: Template[] = []
    @observable loading: boolean = false

    constructor (apiClient:ApiClient) {
        this.apiClient = apiClient
    }

    async load () {
        this.loading = true
        let result = await this.apiClient.makeCall('GET', '/templates')
        this.loading = false

        this.templates = (result.data as Array<object>).map((t) => new Template(t))
    }

    async get (id: number) {
        let response = await this.apiClient.makeCall('GET', `/templates/${id}`)

        if (response.success === true) {
            return new Template(response.data)
        } else {
            throw new ServerError(response.data.message, response.data.details)
        }
    }

    async save (template: Template) {
        let data = template
        let method = 'POST'
        let path = '/templates'

        if (template.id != null) {
            method = 'PUT'
            path = `/templates/${template.id}`
        }

        this.loading = true
        let response = await this.apiClient.makeCall(method, path, {data})
        this.loading = false

        this.load()

        if (template.id == null) {
            template.id = (response.data as Template).id
        }

        return template
    }
}

import * as React from 'react'
import autobind from "autobind-decorator"
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label} from 'reactstrap'
import * as Uppy from '@uppy/core/lib'
import * as XHRUpload from '@uppy/xhr-upload'
import * as UppyDragDrop from '@uppy/react/lib/DragDrop'
import * as UppyProgressBar from '@uppy/react/lib/ProgressBar'
import * as cookie from 'cookie'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'


interface Props {
    addAttachments: Function
    cancel: Function
}

export default class AddAttachmentsModal extends React.Component<Props> {
    uppy = Uppy.Uppy
    state = {
        uploads: [],
    }

    constructor (props) {
        super(props)

        this.uppy = Uppy({
            id: 'ClaimAddAttachments',
            autoProceed: true,
            restrictions: {
                maxFileSize: 10 * 1024 * 1024,
                maxNumberOfFiles: 100,
                allowedFileTypes: [
                    'application/pdf',
                    'image/jpeg',
                    'image/png',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]
            }
        }).use(
            XHRUpload, {
                endpoint: '/admin-api/uploads',
                fieldName: 'file',
                headers: {
                    'X-CSRFToken': cookie.parse(document.cookie)['csrftoken']
                }
            }
        )
        this.uppy.on('upload-success', this.onUploadSuccess)
        this.uppy.run()
    }

    @autobind
    async onAttachClick () {
        this.props.addAttachments(this.state.uploads.map(i => i.id))
    }

    @autobind
    onUploadSuccess (file, resp, uploadURL) {
        console.log("Upload success", file, resp, uploadURL)
        this.setState(
            { uploads: this.state.uploads.concat([{id: resp.body.id, name: file.name}]) }
        )
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={(evt: React.MouseEvent) => this.props.cancel() }>Add claim attachments</ModalHeader>

            <ModalBody>
                <Label>Add any files needed for the claim</Label>
                { this.state.uploads.length > 0 ?
                    <div>
                        {this.state.uploads.map((i) => <div>{i.name}</div>)}
                    </div>
                    :
                    null
                }
                <UppyDragDrop uppy={this.uppy}/>
                <UppyProgressBar uppy={this.uppy}/>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onAttachClick}>Attach files</Button>{' '}
                <Button color="secondary" onClick={(evt: React.MouseEvent) => this.props.cancel() }>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

import * as React from "react";
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import * as moment from 'moment'
import {Col, Row, Button} from 'reactstrap'

import {AppState} from "../../state/app";
import {UIState} from '../../state/ui'
import {
    Claim, CLAIM_TASKS_LABELS, ClaimTasks, NEW_CLAIMS_LABELS, FILLED_CLAIMS_LABELS,
    STATUS_LABELS, MESSAGE_CLAIMS_LABELS
} from '../../state/entities/Claim'

import DataTable, {IDataTableColumn} from 'react-data-table-component'

import ClaimLinkRenderer from './cell-renderers/ClaimLinkRenderer'
import AssigneeRenderer from './cell-renderers/AssigneeRenderer'
import StatusFlags from './StatusFlags'
import { ClaimListFiltersModal } from '../claims/ClaimListFiltersModal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { ClaimListDownloadModal } from "../claims/ClaimListDownloadModal"

import 'react-activity/dist/react-activity.css'
import './ClaimList.scss'


export enum ClaimColumn {
    ClaimNumber,
    Assignee,
    Status,
    SubmitDate,
    ActivateAt,
    ActivateMemo,
    Language,
    AirlineIATA,
    DepartureAirportIATA,
    DestinationAirportIATA,
    FlaggedComment,
    StatusFlags,
    TotalClaimAmount,
    OldestUnreadAt,
}

export const ClaimColumnNames = {
    [ClaimColumn.ClaimNumber]: 'Claim №',
    [ClaimColumn.Assignee]: 'Assignee',
    [ClaimColumn.Status]: 'Status',
    [ClaimColumn.SubmitDate]: 'Submitted on',
    [ClaimColumn.ActivateAt]: 'Next activity',
    [ClaimColumn.ActivateMemo]: 'Memo',
    [ClaimColumn.Language]: 'Language',
    [ClaimColumn.AirlineIATA]: 'Airline',
    [ClaimColumn.DepartureAirportIATA]: 'From',
    [ClaimColumn.DestinationAirportIATA]: 'To',
    [ClaimColumn.FlaggedComment]: 'Flag comment',
    [ClaimColumn.StatusFlags]: 'Status',
    [ClaimColumn.TotalClaimAmount]: 'Amount',
    [ClaimColumn.OldestUnreadAt]: 'Oldest unread',
}

export const ClaimColumnValues = {
    [ClaimColumn.ClaimNumber]: 'case_id_human',
    [ClaimColumn.Assignee]: 'assignee',
    [ClaimColumn.Status]: 'status_detail',
    [ClaimColumn.SubmitDate]: 'created_at',
    [ClaimColumn.ActivateAt]: 'activate_at',
    [ClaimColumn.ActivateMemo]: 'activate_memo',
    [ClaimColumn.Language]: 'language',
    [ClaimColumn.AirlineIATA]: 'airline',
    [ClaimColumn.DepartureAirportIATA]: 'departure_airport',
    [ClaimColumn.DestinationAirportIATA]: 'destination_airport',
    [ClaimColumn.FlaggedComment]: 'flagged_comment',
    [ClaimColumn.StatusFlags]: 'status_flags',
    [ClaimColumn.TotalClaimAmount]: 'total_claim_amount',
    [ClaimColumn.OldestUnreadAt]: 'oldest_unread_at',
}


const TEXT_CELL_STYLE = {
    'white-space': 'normal',
    'line-height': '1.1rem',
    'padding-top': '5px',
    'padding-bottom': '5px',
}


interface ColumnDef<T> extends IDataTableColumn<T> {
    sortField?: string
}


const COLUMN_DEFS: { [index in ClaimColumn]: ColumnDef<Claim> } = {
    [ClaimColumn.ClaimNumber]: {
        name: ClaimColumnNames[ClaimColumn.ClaimNumber],
        selector: i => i,
        cell: i => <ClaimLinkRenderer value={i}/>,
        minWidth: '140px',
        wrap: false,
    },

    [ClaimColumn.Assignee]: {
        name: ClaimColumnNames[ClaimColumn.Assignee],
        selector: i => i,
        cell: i => <AssigneeRenderer value={i}/>,
    },

    [ClaimColumn.Status]: {
        name: ClaimColumnNames[ClaimColumn.Status],
        selector: 'statusLabel',
        wrap: false,
    },

    [ClaimColumn.SubmitDate]: {
        name: ClaimColumnNames[ClaimColumn.SubmitDate],
        selector: i => formatDateColumn(i.created_at),
        sortable: true,
        sortField: 'created_at',
        minWidth: '110px',
    },

    [ClaimColumn.ActivateAt]: {
        name: ClaimColumnNames[ClaimColumn.ActivateAt],
        selector: i => formatDateColumn(i.activate_at),
        sortable: true,
        sortField: 'activate_at',
        minWidth: '110px',
    },

    [ClaimColumn.ActivateMemo]: {
        name: ClaimColumnNames[ClaimColumn.ActivateMemo],
        selector: 'activate_memo',
        minWidth: '400px',
        wrap: true,
        // autoHeight: true,
        // cellStyle: TEXT_CELL_STYLE,
    },

    [ClaimColumn.Language]: {
        name: ClaimColumnNames[ClaimColumn.Language],
        selector: 'language',
        minWidth: '80px',
    },

    [ClaimColumn.AirlineIATA]: {
        name: ClaimColumnNames[ClaimColumn.AirlineIATA],
        selector: 'airline.iata_code',
        minWidth: '60px',
    },

    [ClaimColumn.DepartureAirportIATA]: {
        name: ClaimColumnNames[ClaimColumn.DepartureAirportIATA],
        selector: 'departureAirport.iata_code',
        minWidth: '60px',
    },

    [ClaimColumn.DestinationAirportIATA]: {
        name: ClaimColumnNames[ClaimColumn.DestinationAirportIATA],
        selector: 'arrivalAirport.iata_code',
        minWidth: '60px',
    },

    [ClaimColumn.FlaggedComment]: {
        name: ClaimColumnNames[ClaimColumn.FlaggedComment],
        selector: 'flagged_comment',
        minWidth: '200px',
    },

    [ClaimColumn.StatusFlags]: {
        name: ClaimColumnNames[ClaimColumn.StatusFlags],
        selector: i => i,
        cell: i => <StatusFlags claim={i}/>,
        minWidth: '160px'
    },

    [ClaimColumn.TotalClaimAmount]: {
        name: ClaimColumnNames[ClaimColumn.TotalClaimAmount],
        selector: i => `€${i.total_claim_amount}`,
        sortable: true,
        sortField: 'total_claim_amount',
        minWidth: '80px',
    },

    [ClaimColumn.OldestUnreadAt]: {
        name: ClaimColumnNames[ClaimColumn.OldestUnreadAt],
        selector: i => formatDateColumn(i['oldest_unread_at']),
        sortable: true,
        sortField: 'oldest_unread_at',
        minWidth: '110px',
    },

}


export const DEFAULT_COLUMNS = [
    ClaimColumn.ClaimNumber,
    ClaimColumn.Assignee,
    ClaimColumn.ActivateAt,
    ClaimColumn.SubmitDate,
    ClaimColumn.Language,
    ClaimColumn.AirlineIATA,
    ClaimColumn.DepartureAirportIATA,
    ClaimColumn.DestinationAirportIATA,
    ClaimColumn.TotalClaimAmount,
    ClaimColumn.StatusFlags,
]


export const SET_CONFIG = {
    flagged: {
        columns: [
            ClaimColumn.ClaimNumber,
            ClaimColumn.SubmitDate,
            ClaimColumn.Language,
            ClaimColumn.AirlineIATA,
            ClaimColumn.DepartureAirportIATA,
            ClaimColumn.DestinationAirportIATA,
            ClaimColumn.TotalClaimAmount,
            ClaimColumn.FlaggedComment
        ]
    },

    on_hold: {
        columns: [
            ClaimColumn.ClaimNumber,
            ClaimColumn.Assignee,
            ClaimColumn.Status,
            ClaimColumn.ActivateAt,
            ClaimColumn.Language,
            ClaimColumn.AirlineIATA,
            ClaimColumn.DepartureAirportIATA,
            ClaimColumn.DestinationAirportIATA,
            ClaimColumn.TotalClaimAmount,
            ClaimColumn.ActivateMemo,
        ],
        sort: {
            column: ClaimColumn.ActivateAt,
            direction: 'asc'
        }
    },

    unread_client: {
        columns: [
            ClaimColumn.ClaimNumber,
            ClaimColumn.Assignee,
            ClaimColumn.OldestUnreadAt,
            ClaimColumn.SubmitDate,
            ClaimColumn.Language,
            ClaimColumn.AirlineIATA,
            ClaimColumn.DepartureAirportIATA,
            ClaimColumn.DestinationAirportIATA,
            ClaimColumn.TotalClaimAmount,
            ClaimColumn.StatusFlags,
        ]
    },
}

SET_CONFIG['unread_client_group_1'] = SET_CONFIG['unread_client']
SET_CONFIG['unread_client_group_2'] = SET_CONFIG['unread_client']
SET_CONFIG['unread_client_group_3'] = SET_CONFIG['unread_client']

SET_CONFIG['active'] = SET_CONFIG['on_hold']
SET_CONFIG['user_active'] = SET_CONFIG['on_hold']
SET_CONFIG['partial_claim'] = SET_CONFIG['on_hold']



function formatDateColumn(value) {
    if (!value) {
        return 'N/A'
    }

    return moment.utc(value).format('YYYY.MM.DD')
}


function compareNullableDates(d1: Date | null, d2: Date | null) {
    if (!d1 && !d2) {
        return 0
    }

    if (!d1) {
        return 1
    }

    if (!d2) {
        return -1
    }

    return (d1.getTime() - d2.getTime()) / Math.abs(d1.getTime() - d2.getTime())
}


function getColumnDefs(claimSet): IDataTableColumn<Claim>[] {
    let columns = DEFAULT_COLUMNS
    let sort_ = null

    if (SET_CONFIG[claimSet]) {
        columns = SET_CONFIG[claimSet].columns
        sort_ = SET_CONFIG[claimSet].sort
    }

    return columns.map((i) => {
        let colDef = {...COLUMN_DEFS[i]}
        // if (sort_ && i == sort_.column) {
        //     colDef.sort = sort_.direction
        // }
        return colDef
    })
}


@inject('uiState')
@inject('appState')
@observer
export class ClaimList extends
React.Component<{ appState?: AppState, uiState?: UIState, claimSet?: string }> {
    componentDidMount() {
        let store = this.props.appState.claimListStore
        store.claimSet = this.props.claimSet
        store.reload()
        store.getClaimStatistics()
    }

    @autobind
    onGridReady(params) {
        params.columnApi.autoSizeAllColumns()
    }

    @autobind
    onSort(column: ColumnDef<any>, direction: "asc" | "desc") {
        let store = this.props.appState.claimListStore
        store.setSort(column.sortField, direction == "asc")
    }

    @autobind
    onShowFilters() {
        this.props.uiState.showModal(<ClaimListFiltersModal/>)
    }

    @autobind
    onClearFilters() {
        this.props.appState.claimListStore.resetFilters()
        this.props.uiState.routing.push('/claims')
        this.props.appState.claimListStore.load()
    }

    getPageTitle(claimSet: string) {
        let title = 'Claims'
        let labelLists = [
            CLAIM_TASKS_LABELS,
            FILLED_CLAIMS_LABELS,
            NEW_CLAIMS_LABELS,
            STATUS_LABELS,
            MESSAGE_CLAIMS_LABELS
        ]

        labelLists.map(list => list[claimSet] && (title = list[claimSet]))

        return title
    }

    @autobind
    onClaimListDownload() {
        this.props.uiState.showModal(<ClaimListDownloadModal/>)
    }

    @autobind
    onRowClicked (row: any, e: MouseEvent) {
        let store = this.props.appState.claimListStore
        let selectedRows = store.exportClaimListRows

        if (selectedRows.includes(row.id)) {
            store.setExportClaimListRows(selectedRows.filter((column) => column !== row.id))
        } else {
            selectedRows.push(row.id)
            store.setExportClaimListRows(selectedRows)
        }
        this.forceUpdate()
    }

    render() {
        let store = this.props.appState.claimListStore
        let selectedRows = store.exportClaimListRows
        let {
            count,
            passengers,
            compensation,
            today_count,
            today_passengers,
            today_compensation,
        } = store.claimStatistics
        const compensationFormatted =
            String(compensation).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
        const todayCompensationFormatted =
            String(today_compensation).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
        let conditionalRowStyles = [
            {
                when: row => selectedRows.includes(row.id),
                style: {
                    backgroundColor: "#EEEEEE",
                    userSelect: "none"
                }
            }
        ]

        return <div className="claim-list ag-theme-balham">
            <Row className="mb-2">
                <Col sm={8}>
                    <h1 className="title-main">{this.getPageTitle(this.props.claimSet)}</h1>
                </Col>

                <Col sm={4} className="text-right">
                    <Button
                        outline
                        size="sm"
                        onClick={this.onClaimListDownload}
                    >
                        <FontAwesomeIcon
                            icon={faDownload}
                        />
                    </Button>
                    {" "}
                    { store.isFiltered ?
                        <>
                            <Button outline size="sm" onClick={this.onClearFilters}>
                                Clear filters
                            </Button>
                            {" "}
                        </>
                        :
                        null
                    }
                    <Button
                        outline
                        size="sm"
                        onClick={() => this.onShowFilters()}
                    >
                        Filter
                    </Button>
                </Col>
            </Row>

            <Row className="mb-2">
                <Col>
                    <p className="statistics">
                        All time: {count} cases, {passengers} PAX, {compensationFormatted} € <br/>
                        Today: +{today_count} cases, +{today_passengers} PAX, +{todayCompensationFormatted} €
                    </p>
                </Col>
            </Row>

            <div className="grid-container">
                <DataTable
                    columns={getColumnDefs(this.props.claimSet)}
                    data={this.props.appState.claimListStore.cases}
                    dense={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={"calc(100vh - 11.75rem)"}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    paginationComponentOptions={{noRowsPerPage: true}}
                    paginationPerPage={20}
                    paginationDefaultPage={store.page+1}
                    paginationTotalRows={store.count}
                    sortServer={true}
                    onSort={this.onSort}
                    progressPending={store.loading}
                    onChangePage={store.changePage}
                    onRowClicked={this.onRowClicked}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
        </div>
    }
}

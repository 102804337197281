import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {AppState} from '../../state/app'
import {BankAccount, Currency} from '../../state/entities/Payment'

import {Input} from 'reactstrap'
import autobind from 'autobind-decorator'


@inject('appState')
@observer
export class BankAccountSelect extends React.Component
<{appState?: AppState, onChange: (BankAccount) => void, value: string}>
{
    state = {
        ready: false,
        accounts: [] as BankAccount[]
    }

    async componentDidMount () {
        let response = await this.props.appState.apiClient.makeCall('GET', '/bank-accounts')
        if (response.status == 200) {
            let accounts = response.data as BankAccount[] || []
            accounts.sort((a, b) => a.account_number.localeCompare(b.account_number))
            
            this.setState({accounts, ready: true})

            if (accounts.length && !this.props.value) {
                this.props.onChange(accounts[0])
            }
        }
    }

    @autobind
    onChange (e) {
        e.preventDefault()

        for (let i of this.state.accounts) {
            if (i.account_number == e.target.value) {
                this.props.onChange(i)
            }
        }
    }

    render () {
        return <Input
            type="select"
            disabled={!this.state.ready}
            value={this.props.value}
            onChange={this.onChange}
        >
            {
                this.state.accounts.map((i) => <option key={i.account_number} value={i.account_number}>
                    {i.name} ({i.currency})
                </option>)
            }
        </Input>
    }
}

import {Entity} from './Entity'
import {Party} from './Common'
import {Airline} from './Airline'
import {Claim} from './Claim'


export enum PaymentStatus {
    PENDING = 'pending',
    SETTLED = 'settled',
    RETURNED = 'returned',
    CANCELLED = 'cancelled',
}

export enum Currency {
    EUR = 'EUR',
    GBP = 'GBP',
    USD = 'USD',
    CAD = 'CAD',
    NOK = 'NOK',
}

const STATUS_LABELS = {
    [PaymentStatus.PENDING]: 'Pending',
    [PaymentStatus.SETTLED]: 'Settled',
    [PaymentStatus.CANCELLED]: 'Cancelled',
    [PaymentStatus.RETURNED]: 'Returned',
}

export class Payment extends Entity {
    created_at: Date
    updated_at: Date
    settled_at: Date

    status: PaymentStatus

    amount: number
    currency: Currency

    from_party: Party
    from_account_number: string
    from_account_is_iban: boolean
    from_account_bic: string
    from_airline: Airline

    to_party: Party
    to_account_number: string
    to_account_is_iban: boolean
    to_account_bic: string
    to_name: string
    to_address: string

    details: string

    claim: Claim

    constructor (data) {
        super(data)

        this.parseDates(data, ['created_at', 'updated_at', 'settled_at'])
        if (this.claim) {
            this.claim = new Claim(this.claim)
        }
    }

    get statusLabel () {
        return STATUS_LABELS[this.status]
    }

}

export class BankAccount extends Entity {
    party: Party
    airline: Airline
    account_number: string
    currency: Currency
    name: string
}


export class PaymentDetails extends Entity {
    holder_name: string
    holder_address: string|null

    account_number: string
    is_iban: boolean

    bank_code: string|null
    bank_name: string|null

    allow_editing: boolean
}


export class PaymentDetailsRequest extends Entity {
    request_id: string
    payment_details: PaymentDetails
}

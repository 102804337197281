import * as React from 'react'
import {ProcessAirlineEmailTask, AirlineEmailContentClass, AppState} from "../../state/app";
import {Form, FormGroup, Label, Input} from 'reactstrap'
import TaskActions from "./TaskActions";
import {inject, observer} from "mobx-react";
import {ActionAssigneeIcon} from './ActionAssigneeIcon'


const ACTION_CLASSIFY = 'classify'


export function getContentClassLabel (contentClass) {
    switch (contentClass) {
        case AirlineEmailContentClass.IRRELEVANT:
            return "No relevant information"

        case AirlineEmailContentClass.CLAIM_ACCEPTED:
            return "Responsibility accepted, agreed to pay compensation"

        case AirlineEmailContentClass.CLAIM_REJECTED:
            return "Responsibility denied"

        case AirlineEmailContentClass.CLAIM_INVALID:
            return "Claim rejected with valid reason"

        case AirlineEmailContentClass.DIRECT_PAYOUT:
            return "Compensation paid directly to client"
    }
}


function getContentClassValues () {
    let values = []

    for (let value in AirlineEmailContentClass) {
        if (parseInt(value, 10) >= 0) {
            values.push(parseInt(value, 10))
        }
    }

    return values
}


@inject('appState')
@observer
export class ProcessAirlineEmailDetails extends React.Component<
    {task:ProcessAirlineEmailTask, appState: AppState}
> {
    state = {
        selectedContentClass: null
    }

    render () {
        let task = this.props.task

        return <div>
            <ActionAssigneeIcon user={task.assignee}/>

            <div>Process airline email</div>
            <a href={task.view_url} target="_blank">View message</a>

            { task.content_class == null ?
                this.renderClassSelection()
                :
                <div>
                    Content classification: {getContentClassLabel(task.content_class)}
                </div>
            }
        </div>
    }

    onContentClassChange(selectedContentClass) {
        this.setState({selectedContentClass})
    }

    getActionParams() {
        return {
            [ACTION_CLASSIFY]: {
                content_class: this.state.selectedContentClass
            }
        }
    }

    renderClassSelection () {
        let selected = this.state.selectedContentClass
        return <div style={{marginTop: '10px'}}>
            {
                getContentClassValues().map(
                    (cc) => <div style={{marginLeft: '20px'}}><Label>
                        <Input
                            type="radio"
                            name="content_class"
                            value={cc}
                            checked={selected == cc}
                            onChange={() => this.onContentClassChange(cc)}
                        />
                        {' '} {getContentClassLabel(cc)}
                    </Label></div>
                )
            }

            <TaskActions task={this.props.task} params={this.getActionParams()}/>
        </div>
    }
}
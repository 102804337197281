import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {AirlineEmailContentClass, AppState} from '../../state/app'
import {getContentClassLabel} from '../task-details/ProcessAirlineEmailTaskDetails'
import autobind from 'autobind-decorator'
import {Claim} from '../../state/entities/Claim'
import {
    Modal, ModalHeader, ModalBody, FormGroup, Input, Label, ModalFooter, Button
} from 'reactstrap'
import {User} from '../../state/entities/User'
import {UserAutocomplete} from './UserAutocomplete'


interface Props {
    appState?: AppState
    claim: Claim
    close: () => void
}


@inject('appState')
@observer
export class AssignClaimModal extends React.Component<Props> {
    state = {
        selected: null as User
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={this.props.close}>Assign claim</ModalHeader>

            <ModalBody>
                <FormGroup>
                    <Label>Assignee</Label>
                    <UserAutocomplete onChange={(u) => this.setState({selected: u})}/>
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onAssignToMeClick}>Assign to me</Button>{' '}
                <Button
                    color="primary"
                    onClick={this.onAssignClick}
                    disabled={!this.state.selected}
                >
                    Assign to selected
                </Button> {' '}
                <Button color="secondary" onClick={this.props.close}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }

    @autobind
    async onAssignToMeClick (e) {
        e.preventDefault()

        await this.props.appState.claimListStore.assign(this.props.claim, null)
        this.props.close()
    }

    @autobind
    async onAssignClick (e) {
        e.preventDefault()

        await this.props.appState.claimListStore.assign(this.props.claim, this.state.selected)
        this.props.close()
    }
}

import autobind from 'autobind-decorator'
import * as React from 'react'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {User} from '../../state/entities/User'


export class UserAutocomplete extends React.Component
<{onChange: (User) => void}>
{
    state = {
        requestCount: 0,
        users: [] as User[]
    }

    render () {
        return <AsyncTypeahead
            options={this.state.users}
            labelKey={opt => `${opt.first_name} ${opt.last_name}`}
            onSearch={this.onSearch}
            isLoading={this.state.requestCount > 0}
            minLength={2}
            onChange={(e) => this.props.onChange(e[0] as User)}
        />
    }

    @autobind
    async onSearch (query) {
        this.setState({
            requestCount: this.state.requestCount + 1
        })

        let data = await (
            await fetch(`/admin-api/users?search=${query}&random=${Math.random()}`)
        ).json()

        let users = (data as object[]).map(u => new User(u))

        this.setState({
            requestCount: this.state.requestCount - 1,
            users: users,
        })
    }
}

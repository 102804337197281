import * as React from 'react'
import {PrepareClaimLetterTask, Action} from "../../state/app";
import DocumentLink from "../DocumentLink";
import TaskActions from "./TaskActions";
import TaskNotices from "./TaskNotices";
import {Alert} from 'reactstrap'
import {ActionAssigneeIcon} from './ActionAssigneeIcon'


export default class PrepareDemandLetterDetails extends React.Component<{task:PrepareClaimLetterTask}> {
    render () {
        let title = 'Prepare initial claim notice'
        let severity = this.props.task.severity

        if (severity == 1) {
            title = 'Prepare 2nd notice'
        } else if (severity == 2) {
            title = 'Prepare 3rd notice'
        }

        return <div>
            <ActionAssigneeIcon user={this.props.task.assignee}/>

            <span>{title}</span>

            <div className="field mt-2">
                <div className="label">
                    For electronic delivery
                </div>

                <div className="value">
                    {
                        this.props.task.document ?
                            <DocumentLink document={this.props.task.document}/>
                            :
                            <Alert color="danger">File missing!</Alert>
                    }
                </div>
            </div>

            <div className="field">
                <div className="label">
                    For paper delivery
                </div>

                <div className="value">
                    {
                        this.props.task.printable_document ?
                            <DocumentLink document={this.props.task.printable_document}/>
                            :
                            <Alert color="danger">File missing!</Alert>
                    }
                </div>
            </div>

            <TaskNotices task={this.props.task}/>
            <TaskActions task={this.props.task}/>
        </div>
    }
}

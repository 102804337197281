import * as React from 'react'
import {Action} from '../../state/app'
import {Alert} from 'reactstrap'


export default class TaskNotices extends React.Component<{task: Action}> {
    render () {
        const task = this.props.task

        return <div>
            {(task.notices || []).map((i) => <Alert key={i[1]} color={i[0]}>{i[1]}</Alert>)}
        </div>
    }
}
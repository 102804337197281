import * as React from 'react'
import {Claim} from '../../../state/entities/Claim'
import ClaimLink from '../../common/ClaimLink'


export default class ClaimLinkRenderer extends React.Component<{value:Claim}> {
    render () {
        return <ClaimLink claim={this.props.value}/>
    }
}

import * as React from "react"
import autobind from 'autobind-decorator'
import {inject, observer} from 'mobx-react'
import {Row, Col, Button} from 'reactstrap'

import {AppState} from "../../state/app"
import {Airline} from '../../state/entities/Airline'

import DataTable, {IDataTableColumn} from 'react-data-table-component'

import 'react-activity/dist/react-activity.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './AirlineList.scss'

import {Link} from '../common/Link'
import {AirlineListFilters} from './AirlineListFilters'


enum Column {
    Name,
    IATA,
    ICAO,
    EUStatus,
}

interface ColumnDef<T> extends IDataTableColumn<T> {
    sortField?: string
}

const COLUMN_DEFS: { [index in Column]: ColumnDef<Airline> } = {
    [Column.Name]: {
        name: 'Name',
        selector: i => i,
        cell: i => <Link to={`/airlines/${i.id}`}>{i.name}</Link>,
    },

    [Column.IATA]: {
        name: 'IATA code',
        selector: 'iata_code',
    },
    
    [Column.ICAO]: {
        name: 'ICAO code',
        selector: 'icao_code',
    },
    
    [Column.EUStatus]: {
        name: 'EU status',
        selector: i => i.is_eu ? "Yes" : "No"
    },
}
    


const DEFAULT_COLUMNS = [
    Column.Name,
    Column.IATA,
    Column.ICAO,
    Column.EUStatus,
]


const SET_CONFIG = {
    // incoming: {
    //     columns: [
    //         Column.Status,
    //         Column.AirlineIATA
    //     ]
    // },
}


function getColumnDefs(airlineSet): IDataTableColumn<Airline>[] {
    let columns = DEFAULT_COLUMNS
    let sort_ = null

    if (SET_CONFIG[airlineSet]) {
        columns = SET_CONFIG[airlineSet].columns
        sort_ = SET_CONFIG[airlineSet].sort
    }

    return columns.map((i) => {
        let colDef = {...COLUMN_DEFS[i]}
        // if (sort_ && i == sort_.column) {
        //     colDef.sort = sort_.direction
        // }
        return colDef
    })
}


@inject('appState')
@observer
export class AirlineList extends React.Component<{ appState?:AppState, airlineSet?:string }>
{
    state = {
        showFilters: false
    }
    
    componentDidMount() {
        let store = this.props.appState.airlineListStore
        store.airlineSet = this.props.airlineSet
        store.reload()
    }

    @autobind
    onGridReady(params) {
        params.columnApi.autoSizeAllColumns()
    }

    @autobind
    onSort(column: ColumnDef<any>, direction: "asc" | "desc") {
        let store = this.props.appState.airlineListStore
        store.setSort(column.sortField, direction == "asc")
    }

    @autobind
    onClearFilters () {
        this.props.appState.airlineListStore.resetFilters()
        this.props.appState.airlineListStore.load()
    }

    render() {
        let store = this.props.appState.airlineListStore

        return <div className="airline-list ag-theme-balham">
            <Row>
                <Col sm={6}><h1 className="title-main">Airlines</h1></Col>
                <Col sm={6} className="text-right">
                    <Button
                        outline
                        size="sm"
                        onClick={() => this.setState({showFilters: !this.state.showFilters})}
                    >
                        {this.state.showFilters ? "Hide filters" : "Show filters"}
                    </Button>
                    {" "}
                    { this.props.appState.airlineListStore.isFiltered ?
                        <Button outline size="sm" onClick={this.onClearFilters}>
                            Clear filters
                        </Button>
                        :
                        null
                    }
                </Col>
            </Row>

            { this.state.showFilters ? <AirlineListFilters/> : null }

            <div className="grid-container">
                <DataTable
                    columns={getColumnDefs(this.props.airlineSet)}
                    data={this.props.appState.airlineListStore.airlines}
                    dense={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={"calc(100vh - 11rem)"}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    paginationComponentOptions={{noRowsPerPage: true}}
                    paginationPerPage={100}
                    paginationDefaultPage={store.page+1}
                    paginationTotalRows={store.count}
                    sortServer={true}
                    onSort={this.onSort}
                    progressPending={store.loading}
                    onChangePage={store.changePage}
                />
            </div>
        </div>
    }
}

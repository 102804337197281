import * as React from 'react'
import * as moment from "moment";
import {Claim} from "../../state/entities/Claim";
import {Input} from 'reactstrap'


export default class DepartureTime extends React.Component<{claim:Claim}> {
    state = {
        editing: false,
        value: null
    }
    render () {
        const c = this.props.claim

        return <span>
            {
                this.state.editing ?
                    <Input
                        value={this.state.value}
                        onBlur={this.onAbortEdit}
                        onChange={this.onChange}
                        onKeyUp={this.onInputKeyUp}
                    />
                    :
                    <span>{moment.utc(c.departure_time).format('DD.MM.YYYY HH:mm')}</span>
            }
            {" "}
            <a href="#" onClick={this.onEditClick}>Edit</a>
        </span>
    }

    onEditClick = (e) => {
        e.preventDefault()
        this.setState({
            editing: true,
            value: moment.utc(this.props.claim.departure_time).format('DD.MM.YYYY HH:mm')
        })
    }

    onFinishEdit = () => {
        this.props.claim.departure_time = moment.utc(this.state.value, 'DD.MM.YYYY HH:mm', true).toDate()
        this.setState({editing: false, value: null})
    }

    onAbortEdit = () => {
        this.setState({editing: false, value: null})
    }

    onChange = (e) => {
        this.setState({value: e.target.value})
    }

    onInputKeyUp = (e) => {
        if (e.keyCode == 27) {
            e.preventDefault()
            this.onAbortEdit()
        }
        else if (e.keyCode == 13) {
            e.preventDefault()
            this.onFinishEdit()
        }
    }
}
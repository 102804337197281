import * as React from 'react'
import * as moment from 'moment'
import {inject, observer} from 'mobx-react'
import {AppState} from "../../state/app"
import {
    Button, Input, Card, CardText, UncontrolledButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import {TaskDetails} from "../task-details/TaskDetails"
import SavePrompt from './SavePrompt'
import Field from './Field'
import AirportAutocomplete from '../common/AirportAutocomplete'

import AirlineField from './AirlineField'
import FlightNumberField from "./FlightNumber";
import DepartureTime from "./DepartureTime";
import FlagActionModal from "./FlagActionModal";
import {Claim} from "../../state/entities/Claim";
import TextField from "./TextField";
import autobind from "autobind-decorator";
import {UIState} from "../../state/ui";
import AddAttachmentsModal from "./AddAttachmentsModal";
import CloseClaimActionModal from "./CloseClaimActionModal";
import AddComment from './AddComment';
import EditRegulatorDecisionModal, { getNebDecisionLabel } from "./EditRegulatorDecisionModal";

import './ClaimDetails.scss'
import StartCallModal from '../voice-call/StartCallModal'
import {Airport} from '../../state/entities/Airport'
import {PassengerList} from './PassengerList'
import {ManualStatusChangeModal} from './ManualStatusChangeModal'
import {EditTripSegmentModal} from './EditTripSegmentModal'
import {TripSegment} from '../../state/entities/TripSegment'
import {ManualCompensationChangeModal} from './ManualCompensationChangeModal'
import {NebDecision} from '../../state/entities/RegulatorDecision'


class FlightData extends React.Component<{claim: Claim, uiState?:UIState}> {
    render () {
        const c = this.props.claim

        if (c.trip_segments.length == 0) {
            return null
        } else {
            const f = c.trip_segments[0].flight

            if (f.claim_count < 2) {
                return null
            }

            return <div>
                <a href={`/flights/${f.id}`}>{f.claim_count} claims, {f.pax_count} passengers</a>
                {" "}
                from this flight, compensation payments {f.is_comp_paid ? "RECEIVED" : "not received"}
            </div>
        }
    }
}

interface TripSegmentActionMenuProps {
    uiState?: UIState
    appState?: AppState
    tripSegment: TripSegment
    editRegulatorDecision: Function
}

@inject('uiState', 'appState')
@observer
class TripSegmentActionMenu extends React.Component<TripSegmentActionMenuProps>
{
    @autobind
    onRemoveClick (e, tripSegment: TripSegment) {
        e.preventDefault()
        let cdStore = this.props.appState.claimDetailsStore
        if (tripSegment.position != cdStore.claim.trip_segments.length - 1) {
            let nextFlight = cdStore.claim.trip_segments[tripSegment.position + 1].flight
            let previousFlight = cdStore.claim.trip_segments[tripSegment.position - 1].flight

            nextFlight.departure_airport = previousFlight.arrival_airport
            nextFlight.update_flight = true
            previousFlight.update_flight = true
        }
        cdStore.claim.trip_segments.splice(cdStore.claim.trip_segments.indexOf(tripSegment), 1)
    }

    @autobind
    onEditClick () {
        this.props.uiState.showModal(
            <EditTripSegmentModal tripSegment={this.props.tripSegment}/>
        )
    }

    render () {
        let ts = this.props.tripSegment

        return <UncontrolledButtonDropdown>
            <DropdownToggle caret>Action</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={this.onEditClick}>Edit</DropdownItem>
                {!ts.flight.regulator_decision &&
                    <DropdownItem onClick={(e) => this.props.editRegulatorDecision(e, ts)}>
                        Set regulator decision
                    </DropdownItem>
                }
                { ts.position > 0 ?
                    <DropdownItem onClick={(e) => this.onRemoveClick(e, ts)}>
                        Remove
                    </DropdownItem>
                    :
                    null
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    }
}

interface ConnectingFlightDataProps {
    appState?: AppState
    uiState?: UIState
    claim: Claim
}

@inject('appState', 'uiState')
class ConnectingFlightData extends React.Component<ConnectingFlightDataProps> {
    render () {
        const c = this.props.claim
            
        return <div>
            {c.trip_segments.map((ts, key) => {

                let classNames = ["mb-4"]

                if (ts.flight.regulator_decision?.neb_decision == NebDecision.ACCEPTED) {
                    classNames.push('neb-accepted')
                }
                else if (ts.flight.regulator_decision?.neb_decision == NebDecision.REJECTED) {
                    classNames.push('neb-rejected')
                }
                else if (c.problem_flight == ts.flight.id) {
                    classNames.push("problem-flight")
                }

                let problemFlightLabel = ts.flight_issue !== null ? " - problem flight" : ""

                return (
                    <Card body className={classNames.join(" ")} style={{ width: '36rem' }} key={key}>
                        <CardText tag="div">
                            <Field label={"Flight #".concat(String(key+1)).concat(problemFlightLabel)}>
                                <span className="airline-field">
                                    {ts.flight.airline.name} ({ts.flight.airline.iata_code})
                                </span>
                                {" "}
                                <span>{ts.flight.flight_number}</span>
                                <br/>

                                <span className="airport-field">
                                    {ts.flight.departure_airport.name} ({ts.flight.departure_airport.iata_code})
                                </span>
                                <span> → </span>
                                <span className="airport-field">
                                    {ts.flight.arrival_airport.name} ({ts.flight.arrival_airport.iata_code})
                                </span>
                            </Field>
                            <Field label="Departure date">
                                <span>{moment(ts.flight.departure_date).format('DD.MM.YYYY')}</span>
                            </Field>
                            {ts.flight.regulator_decision &&
                                <Field label="Regulator decision">
                                    <span>{getNebDecisionLabel(
                                        ts.flight.regulator_decision.neb_decision
                                    )}</span>
                                    {" "}
                                    <a href="#" onClick={(e) => this.onEditRegulatorDecisionClick(e, ts)}>
                                        Edit
                                    </a>
                                    <br/>
                                    {ts.flight.regulator_decision.document &&
                                        <a href={ts.flight.regulator_decision.document.content_url} target="_blank">
                                            {ts.flight.regulator_decision.document.name}
                                        </a>
                                    }
                                </Field>
                            }
                            <TripSegmentActionMenu
                                tripSegment={ts}
                                editRegulatorDecision={this.onEditRegulatorDecisionClick}
                            />
                        </CardText>
                    </Card>
                )
            })}
        </div>
    }

    @autobind
    onEditRegulatorDecisionClick (e, ts: TripSegment) {
        e.preventDefault()
        let uiState = this.props.uiState

        uiState.showModal(
            <EditRegulatorDecisionModal
                cancel={uiState.closeModal}
                tripSegment={ts}
                setRegulatorDecision={this.setRegulatorDecision}
            />
        )
    }

    @autobind
    async setRegulatorDecision (nebDecision, uploads) {
        await this.props.appState.flightDetailsStore.setRegulatorDecision(
            nebDecision,
            uploads
        )
        this.props.uiState.closeModal()
    }
}


interface ClaimDataProps {
    appState: AppState
    uiState: UIState
    case_: Claim
}


@inject('appState', 'uiState')
@observer
export class ClaimData extends React.Component<ClaimDataProps> {
    state = {
        modal: null
    }

    render () {
        const claim = this.props.appState.claimDetailsStore.claim

        let flightSectionTitle = "Flight"

        if (claim.trip_segments.length > 1) {
            flightSectionTitle += "s"
        }

        return <div className="claim-data">
            {this.state.modal}
            <div className="actions mb-3">
                <Button
                    className={'claim-data-action'}
                    onClick={() => this.onSetStatusClick()}
                >
                    Set claim status
                </Button>

                <Button
                    className={'claim-data-action'}
                    onClick={() => this.onChangeCompensationClick()}
                >
                    Edit Comp.
                </Button>

                {claim.actions.map(
                    (a) =>
                    <Button
                        key={a.action}
                        className={'claim-data-action'}
                        onClick={() => this.onActionClick(a.action)}
                    >
                        {a.label}
                    </Button>
                )}
            </div>


            <Field label="Submitted at">
                {moment.utc(claim.created_at).format('DD.MM.YYYY HH:mm')}
            </Field>

            <Field label="Submitted by">
                {claim.submitter.name}
                {" "}
                ({claim.submitter.email}
                {
                    (claim.submitter.phone_number || claim.phone_number)
                    && `, ${claim.submitter.phone_number || claim.phone_number}`}
                )
                {" "}
                <Button onClick={this.onStartCallClick}>Call</Button>
            </Field>

            {claim.passenger_address && (
                <Field label="Address">
                    {claim.passenger_address.city}, {claim.passenger_address.country}{claim.passenger_address.state ? `(${claim.passenger_address.state})` : null} <br/>
                    {claim.passenger_address.address1}{claim.passenger_address.address2 ? `, ${claim.passenger_address.address2}` : null} <br/>
                    {claim.passenger_address.postal_code}
                </Field>
            )}

            <Field label="Passengers">
                <PassengerList passengers={claim.passengers}/>
            </Field>

            <Field label="Flight">
                <span className="airport-field">
                    {claim.departureAirport.name} ({claim.departureAirport.iata_code})
                </span>
                <span> → </span>
                <span className="airport-field">
                    {claim.arrivalAirport.name} ({claim.arrivalAirport.iata_code})
                </span>
            </Field>

            <Field label="Distance">{claim.distance}km</Field>

            <Field label="Scheduled departure time"><DepartureTime claim={claim}/></Field>
            <Field label="Booking number">
                <TextField value={claim.booking_number} updateValue={(v) => claim.booking_number = v}/>
            </Field>

            <h3 className="mt-4 mb-3">{flightSectionTitle}</h3>
            <Button
                className='add-trip-segment mb-3'
                onClick={() => this.onAddTripSegmentClick(true)}
            >
                Add flight
            </Button>
            <ConnectingFlightData claim={claim}/>
            <Button
                className='add-trip-segment mb-3'
                onClick={() => this.onAddTripSegmentClick(false)}
            >
                Add flight
            </Button>

            <Field label="Issue with flight">{this.getFlightIssueText()}</Field>
            <Field label="Explanation given by airline">{claim.stated_cause_label}</Field>
            <Field label="Comment">{claim.comment}</Field>

            <Field label="Claim amount per passenger">
                <span className="claim-amount">{claim.passenger_claim_amount}€</span>
            </Field>

            <Field label="Total claim amount">
                <span className="claim-amount">{claim.total_claim_amount}€</span>
            </Field>

            <Field label="Fee structure">
                <span className="claim-amount">
                    {claim.fee_fraction*100}% + {claim.service_charge}€/pax
                </span>
            </Field>

            <h3>Attachments</h3>
            <div className="attachments">
                {
                    claim.documents.map(
                        (i) => <div key={i.id}>
                            <Input
                                type="checkbox"
                                checked={i.attach}
                                onChange={() => {i.attach = !i.attach}}

                            />

                            {" "}

                            <a href={i.document.content_url} target="_blank">
                                {i.document.name}
                            </a>

                            {" "}

                            <span>{Math.round(i.document.size / 1024)}KB</span>
                        </div>
                    )
                }

                <a href="#" onClick={this.onAddAttachmentsClick}>Add attachments</a>
            </div>

            { this.props.appState.claimDetailsStore.dirty ? <SavePrompt/> : null }

            <h3>History</h3>
            <div>
                {claim.done_tasks.map((i) => <TaskDetails key={i.id} task={i}/>)}
            </div>

            { claim.pending_tasks.length > 0 ?
                <div>
                    <h3>Pending tasks</h3>
                    <div>
                        {claim.pending_tasks.map((i) => <TaskDetails key={i.id} task={i}/>)}
                    </div>
                </div>
                :
                null
            }

            <AddComment/>

        </div>
    }

    onAddTripSegmentClick = (isPrepend: boolean) => {
        let newTs = this.props.appState.claimDetailsStore.initTripSegment(isPrepend)
        this.props.uiState.showModal(<EditTripSegmentModal tripSegment={newTs} create={true}/>)
    }

    onActionClick = (action) => {
        switch(action) {
            case "flag":
                this.setState(
                    {modal: <FlagActionModal cancel={this.closeModal} commit={this.flagClaim}/>}
                )
                break

            case "unflag":
                this.unflagClaim()
                break

            case "close":
                this.showCloseClaimModal()
                break
        }
    }

    @autobind
    onAddAttachmentsClick (e) {
        e.preventDefault()
        let uiState = this.props.uiState

        uiState.showModal(
            <AddAttachmentsModal cancel={uiState.closeModal} addAttachments={this.addAttachments}/>
        )
    }

    closeModal = () => {
        this.setState({modal: null})
    }

    flagClaim = async (comment) => {
        await this.props.appState.claimDetailsStore.performAction('flag', {comment})
        this.closeModal()
    }

    unflagClaim = async () => {
        await this.props.appState.claimDetailsStore.performAction('unflag')
    }

    @autobind
    showCloseClaimModal () {
        this.props.uiState.showModal(
            <CloseClaimActionModal
                closeClaim={this.actionCloseClaim}
                cancel={this.props.uiState.closeModal}
            />
        )
    }

    @autobind
    async actionCloseClaim (reason, comment) {
        await this.props.appState.claimDetailsStore.performAction('close', {reason, comment})
        this.props.uiState.closeModal()
    }

    @autobind
    async addAttachments (uploads) {
        await this.props.appState.claimDetailsStore.addAttachments(uploads)
        this.props.uiState.closeModal()
    }

    @autobind
    onStartCallClick (e) {
        e.preventDefault()

        const claim = this.props.appState.claimDetailsStore.claim

        this.props.uiState.showModal(
            <StartCallModal close={this.props.uiState.closeModal} phoneNumber={claim.phone_number}/>
        )
    }

    @autobind
    onSetStatusClick () {
        this.props.uiState.showModal(<ManualStatusChangeModal appState={this.props.appState} />)
    }

    @autobind
    onChangeCompensationClick () {
        this.props.uiState.showModal(<ManualCompensationChangeModal appState={this.props.appState} />)
    }

    getFlightIssueText () {
        const claim = this.props.appState.claimDetailsStore.claim
        if (
            claim.flight_issue_label === "Flight cancelled" &&
            claim.flight_cancellation_notification_label !== null
        ) {
            return `${claim.flight_issue_label} (${claim.flight_cancellation_notification_label})`
        }
        if (claim.flight_issue_label === "Late arrival" && claim.arrival_delay !== null) {
            const arrivalDelayInHours = claim.arrival_delay / 3600
            return `${claim.flight_issue_label} (${arrivalDelayInHours} h)`
        }
        return claim.flight_issue_label
    }
}


interface AirportFieldProps {
    airport: Airport
    onChange: Function
}

class AirportField extends React.Component<AirportFieldProps> {
    state = {
        editing: false,
        initialValueLoaded: false
    }

    render () {
        if (!this.state.editing) {
            return <span className="airport-field">
                {this.props.airport.name} ({this.props.airport.iata_code})
                {" "}
                <Button color="link" onClick={this.onEditClick}>Edit</Button>
            </span>
        } else {
            return <span className="airport-field editing">
                <AirportAutocomplete
                    // id="airport-input"
                    onChange={this.onChange}
                    defaultValue={this.props.airport.iata_code}
                />
            </span>
        }

    }

    onEditClick = () => {
        this.setState({editing: true, initialValueLoaded: false})
    }

    onChange = (selection) => {
        if (selection.length == 0) {
            return
        }

        if (this.state.initialValueLoaded) {
            this.setState({editing: false})
            this.props.onChange(selection[0] as Airport)
        } else {
            this.setState({initialValueLoaded: true})
        }
    }
}

import * as IBAN from 'iban'
import BIC from 'bic'


export function required(errorMessage = "This field is required") {
    return function (value) {
        if (value === null || String(value).trim() === '') {
            return errorMessage
        }
        return null
    }
}


export function numeric(errorMessage = "Enter a number") {
    return function (value) {
        if (isNaN(value)) {
            return errorMessage
        }
        return null
    }
}


export function positive(errorMessage = "Enter a positive number") {
    return function (value) {
        if (value < 0) {
            return errorMessage
        }
        return null
    }
}


export function validateIBAN(value) {
    if (!IBAN.isValid(value)) {
        return "Enter a valid IBAN"
    }

    return null
}


export function validateBIC(value) {
    if (!BIC.isValid(value)) {
        return "Enter a valid SWIFT-BIC code"
    }

    return null
}


const EMAIL_REGEX = /[^@\s]+@[^@\s]+(?:\.[^@\s]+)+/
export function validateEmail (value) {
    if (!value) return null

    value = value.trim()

    if (value.length > 0 && !EMAIL_REGEX.test(value)) {
        return "Enter a valid email address"
    }

    return null
}


const PHONE_NUMBER_REGEX = /^[0-9 +\-()]+$/
export function validatePhoneNumber (value) {
    if (!value) return null

    value = value.trim()

    if (value.length > 0 && !PHONE_NUMBER_REGEX.test(value)) {
        return "Enter a valid phone number"
    }

    return null
}

export function validateFlightNumber (value) {
    value = value.replace(/[^0-9a-z]/gi, '')

    let prefix = ''
    let flightNumber = value

    let genericErrorMsg = "Please enter a valid flight number"
    let tooLongErrorMsg = "Flight number should be shorter"

    // Don't allow empty input
    if (flightNumber.length == 0) {
        return genericErrorMsg
    }

    // Unprefixed flight number, digits only
    if (/^\d+$/.test(flightNumber)) {
        // Accept 4 digits max
        if (flightNumber.length > 4) {
            return tooLongErrorMsg
        }

        return null
    }

    // Prefixed flight number
    // To determine if an IATA or ICAO prefix is present, we rely on the (currently
    // correct) assumption that ICAO codes end on a letter

    let prefixLen = 2  // Expect the more common 2 char IATA prefix
    console.log("Checking for ICAO prefix", flightNumber[2], /[a-z]/.test(flightNumber[2]))
    if (/[a-z]/i.test(flightNumber[2])) {
        // Appears to be a 3 character ICAO prefix
        prefixLen = 3
    }

    prefix = flightNumber.slice(0, prefixLen)
    flightNumber = flightNumber.slice(prefixLen)

    if (flightNumber.length == 0 || !/\d+$/.test(flightNumber)) {
        return "Flight number must end with at least one digit"
    }

    if (flightNumber.length > 4) {
        return tooLongErrorMsg
    }

    if (!/^\d+$/.test(flightNumber)) {
        return genericErrorMsg
    }

    return null
}

import {Entity} from './Entity'
import {Document} from '../app'

export enum NebDecision {
    ACCEPTED = 'NebDecision.ACCEPTED',
    REJECTED = 'NebDecision.REJECTED',
}

export class RegulatorDecision extends Entity{
    document: Document
    neb_decision: NebDecision
}

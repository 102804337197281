import * as React from 'react'
import autobind from "autobind-decorator"
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label} from 'reactstrap'
import * as Uppy from '@uppy/core/lib'
import * as XHRUpload from '@uppy/xhr-upload'
import * as UppyDragDrop from '@uppy/react/lib/DragDrop'
import * as UppyProgressBar from '@uppy/react/lib/ProgressBar'
import * as cookie from 'cookie'
import {NebDecision} from '../../state/entities/RegulatorDecision'
import {TripSegment} from '../../state/entities/TripSegment'
import {inject, observer} from 'mobx-react'
import {AppState} from "../../state/app"
import {UIState} from "../../state/ui"

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

interface EditRegulatorDecisionModalProps {
    setRegulatorDecision: Function
    cancel: Function
    tripSegment: TripSegment
    uiState?: UIState
    appState?: AppState
}

const NEB_DECISION_CHOICES : [NebDecision, string][] = [
    [NebDecision.ACCEPTED, `In favour of passenger`],
    [NebDecision.REJECTED, `In favour of airline`],
]

export function getNebDecisionLabel(nebDecision: NebDecision) {
    switch(nebDecision) {
        case NebDecision.ACCEPTED:
            return `In favour of passenger`

        case NebDecision.REJECTED:
            return `In favour of airline`
    }
}

@inject('appState', 'uiState')
export default class EditRegulatorDecisionModal extends React.Component<EditRegulatorDecisionModalProps> {
    uppy = Uppy.Uppy
    defNebDecision = this.props.tripSegment.flight.regulator_decision ?
        this.props.tripSegment.flight.regulator_decision.neb_decision
    :
        NebDecision.ACCEPTED
    
    state = {
        neb_decision: this.defNebDecision,
        upload: null,
    }

    constructor (props) {
        super(props)

        this.uppy = Uppy({
            id: 'RegulatorDecisionAddAttachments',
            autoProceed: true,
            restrictions: {
                maxFileSize: 10 * 1024 * 1024,
                maxNumberOfFiles: 1,
                allowedFileTypes: [
                    'application/pdf',
                    'image/jpeg',
                    'image/png',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]
            }
        }).use(
            XHRUpload, {
                endpoint: '/admin-api/uploads',
                fieldName: 'file',
                headers: {
                    'X-CSRFToken': cookie.parse(document.cookie)['csrftoken']
                }
            }
        )
        this.uppy.on('upload-success', this.onUploadSuccess)
        this.uppy.run()
    }

    componentDidMount () {
        this.props.appState.flightDetailsStore.load(this.props.tripSegment.flight.id)
    }

    @autobind
    onSaveDecision () {
        let upload = null

        if (this.state.upload) {
            upload = this.state.upload.id
        }

        this.props.setRegulatorDecision(
            this.state.neb_decision,
            upload
        )
        this.props.appState.claimDetailsStore.reload()
    }

    @autobind
    onUploadSuccess (file, resp, uploadURL) {
        console.log("Upload success", file, resp, uploadURL)
        this.setState(
            { 
                upload: {
                    id: resp.body.id,
                    name: file.name
                }
            }
        )
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={ (evt: React.MouseEvent) => this.props.cancel() }>Edit regulator decision</ModalHeader>

            <ModalBody>
                <Label>NEB decision</Label>
                <Input
                    id="nebDecision"
                    type="select"
                    className="mb-3"
                    value={this.state.neb_decision}
                    onChange={(e) => this.setState({neb_decision: e.target.value})}
                >
                    {NEB_DECISION_CHOICES.map(
                        ([id]) => <option key={id} value={id}>
                            {getNebDecisionLabel(id)}
                        </option>
                    )}
                </Input>
                <Label>Add any files needed for the regulator decision</Label>
                { this.state.upload &&
                    <div>
                        <div>{this.state.upload.name}</div>
                    </div>
                }
                <UppyDragDrop uppy={this.uppy}/>
                <UppyProgressBar uppy={this.uppy}/>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onSaveDecision}>Save</Button>{' '}
                <Button color="secondary" onClick={() => this.props.cancel()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

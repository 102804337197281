import * as React from 'react'
import { observer } from 'mobx-react'
import { Col } from 'reactstrap'
import { Switch, Route } from 'react-router'
import { UploadClaimStatusExcel } from './UploadClaimStatusExcel'

interface Props {
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
}

@observer
export class UploadModule extends React.Component<Props> {
    render () {
        return <>
            <Col xs="12" className="content">
                <Switch>
                    <Route exact path="/upload" component={UploadClaimStatusExcel} />
                </Switch>
            </Col>
        </>
    }
}

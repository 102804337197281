import * as React from 'react'
import {Claim} from '../../../state/entities/Claim'
import StatusFlags from "../StatusFlags";

export default class StatusFlagsRenderer extends React.Component<{value:Claim}> {
    render () {
        let c = this.props.value
        return <StatusFlags claim={c}/>
    }
}

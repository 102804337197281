import {observer, inject} from 'mobx-react'
import * as React from 'react'
import autobind from 'autobind-decorator'
import {Input, Button} from 'reactstrap'

import './AddComment.scss'
import {AppState} from '../../state/app'

@inject("appState")
@observer
export default class AddComment extends React.Component<{appState?: AppState}> {
    input: React.RefObject<HTMLTextAreaElement>

    constructor (props) {
        super(props)

        this.input = React.createRef<HTMLTextAreaElement>()
    }

    render () {
        return  <div className="add-comment mt-3">
            <Input innerRef={this.input} type="textarea"/>
            <Button
                className="mt-2 mb-5"
                onClick={this.onAddCommentClick}
            >
                Add comment
            </Button>
        </div>
    }

    @autobind
    onAddCommentClick (e) {
        e.preventDefault()

        let val = this.input.current.value

        if (!val) {
            return
        }

        val = val.trim()

        if (val.length == 0) {
            return
        }

        this.props.appState.claimDetailsStore.addComment(val)
    }
}
import {observable} from "mobx";

import {Entity} from './Entity'
import {Airport} from './Airport'
import {Airline} from './Airline'
import {RegulatorDecision} from './RegulatorDecision'

export class Flight extends Entity {
    created_at: Date
    departure_date: Date
    flight_number: string
    @observable airline: Airline
    @observable departure_airport: Airport
    @observable arrival_airport: Airport
    regulator_decision: RegulatorDecision

    claim_count: number
    pax_count: number
    is_comp_paid: boolean

    update_flight: boolean

    constructor (data) {
        super(data)

        this.parseDates(data, ['created_at', 'departure_date'])
    }
}

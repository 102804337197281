import * as React from 'react'
import autobind from 'autobind-decorator'
import {AppState} from '../../state/app'
import {inject, observer} from 'mobx-react'
import * as moment from 'moment'
import {AgGridReact} from 'ag-grid-react'
import ClaimLinkRenderer from '../claim-list/cell-renderers/ClaimLinkRenderer'
import AssigneeRenderer from '../claim-list/cell-renderers/AssigneeRenderer'
import {formatDateColumn, formatMoneyColumn} from '../common/grid-utils'
import StatusFlagsRenderer from '../claim-list/cell-renderers/StatusFlagsRenderer'
import {Claim} from '../../state/entities/Claim'
import {Row, Col, Button} from 'reactstrap'
import {FlightDetailsFilters} from './FlightDetailsFilters'

import './FlightDetails.scss'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'


interface Props {
    appState?: AppState,
    match: {
        params: {
            id: string
        },
        path: string
        url: string
    }
}

const CLAIM_COLUMNS = [
    {
        headerName: 'Claim №',
        valueGetter: (params) => params.data,
        cellRendererFramework: ClaimLinkRenderer,
    },
    {
        headerName: 'Assignee',
        valueGetter: (params) => params.data,
        cellRendererFramework: AssigneeRenderer,
    },
    {
        headerName: 'Submitted on',
        field: 'created_at',
        valueFormatter: formatDateColumn,
        sortable: true,
    },
    {
        headerName: 'Amount',
        field: 'total_claim_amount',
        type: 'numericColumn',
        valueFormatter: formatMoneyColumn,
        sortable: true,
        sortingOrder: [null, "desc", "asc"]
    },
    {
        headerName: 'Status',
        valueGetter: (params) => params.data,
        cellRendererFramework: StatusFlagsRenderer
    },
    {
        headerName: 'State',
        valueGetter: (params) => (params.data as Claim).statusLabel,
    }
]

@inject('appState')
@observer
export class FlightDetails extends React.Component<Props> {
    state = {
        showFilters: false
    }

    componentDidMount(): void {
        this.props.appState.flightDetailsStore.load(Number(this.props.match.params.id))
    }

    @autobind
    onClearFilters () {
        this.props.appState.flightDetailsStore.resetFilters()
        this.props.appState.flightDetailsStore.load(Number(this.props.match.params.id))
    }
    
    render () {
        let {flight, claims} = this.props.appState.flightDetailsStore

        if (!flight) return null

        console.log("Rendering flight details", claims.length)

        return <div className="flight-details ag-theme-balham">
            <h1 className="title-main">
                {flight.airline.name}
                {" "}
                {flight.airline.iata_code} {flight.flight_number}
                {" "}
                {flight.departure_airport.city} ({flight.departure_airport.iata_code})
                {" "}
                →
                {" "}
                {flight.arrival_airport.city} ({flight.arrival_airport.iata_code})
                {" on "}
                {moment.utc(flight.departure_date).format('YYYY.MM.DD')}
            </h1>


            <Row>
                <Col sm={6}><h2 className="title-secondary">Claims</h2></Col>
                <Col sm={6} className="text-right">
                    <Button
                        outline
                        size="sm"
                        onClick={() => this.setState({showFilters: !this.state.showFilters})}
                    >
                        {this.state.showFilters ? "Hide filters" : "Show filters"}
                    </Button>
                    {" "}
                    { this.props.appState.flightDetailsStore.isFiltered ?
                        <Button outline size="sm" onClick={this.onClearFilters}>
                            Clear filters
                        </Button>
                        :
                        null
                    }
                </Col>
            </Row>

            { this.state.showFilters ? <FlightDetailsFilters/> : null }
            
            <div className="grid-container">
                <AgGridReact
                    key={claims.length ? claims[0].id : 'empty'}
                    columnDefs={CLAIM_COLUMNS}
                    rowData={claims}
                    defaultColDef={{resizable: true, unSortIcon: true}}
                    suppressColumnVirtualisation={true}
                    autoSizePadding={0}
                    enableCellTextSelection={true}
                />
            </div>

        </div>
    }
}

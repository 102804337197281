import * as React from "react";
import "./../assets/scss/App.scss";
import {
    Navbar, NavbarBrand, NavItem, Nav,
    Container, Row, Col,
    Form, Input,
    Badge, NavbarToggler, Collapse
} from 'reactstrap'
import {ToastContainer} from "react-toastify";
import {UIState} from "../state/ui"
import {AppState} from "../state/app"
import {observer, Provider} from "mobx-react"
import {Router, Switch, Route, Redirect} from 'react-router'
import {SearchBox} from "./SearchBox"
import {NavLink} from './NavLink'

import 'react-toastify/dist/ReactToastify.css'
import './app.scss'
import {ApiClient} from "../api/client";
import LoginScreen from './LoginScreen'
import CurrentUser from './CurrentUser'
import CallControls from './voice-call/CallControls'
import {ClaimsModule} from './claims/ClaimsModule'
import {PaymentModule} from './payment/PaymentModule'
import {TemplatesModule} from './templates/TemplatesModule'
import {ConfirmationDialog} from './ConfirmationDialog'
import {FlightsModule} from './flights/FlightsModule'
import {AirlineModule} from './airlines/AirlineModule'
import {SearchResults} from './search/SearchResults'
import { MessageDropdown } from './MessageDropdown'
import PAPER_PLANE_IMAGE from '../assets/img/paper-plane.svg'
import { UploadModule } from "./upload/UploadModule";

export interface AppProps {
}

let uiState: UIState, appState: AppState


function initApp () {
    let apiClient = new ApiClient('/admin-api')
    uiState = new UIState()
    appState = new AppState(apiClient)

    apiClient.setErrorCallback(uiState.apiErrorCallback)
}
initApp()


@observer
export default class App extends React.Component<AppProps> {

    setIsMenuOpened() {
        uiState.isMenuOpen = !uiState.isMenuOpen
    }

    setIsSidebarOpened () {
        if (uiState.isSidebarOpened) {
            uiState.closeSidebar()
        } else {
            uiState.showSidebar()
        }
    }

    render() {
        if (!appState.auth.statusKnown) {
            return "Checking access"
        }

        return <Provider uiState={uiState} appState={appState}>
            <Router history={uiState.history}>
                <Switch>

                    {/* Move user away from login screen if already logged in */}
                    { appState.auth.status ?
                        <Route exact path="/login">
                            <Redirect to={ uiState.currentSearchParams['next'] || "/"}/>
                        </Route>
                        :
                        null
                    }

                    {/* Render the login screen itself */}
                    <Route exact path="/login" component={LoginScreen} />

                    {/* Move the user to the login screen if not logged in */}
                    { !appState.auth.status ?
                        <Route>
                            <Redirect
                                to={`/login?next=${uiState.currentLocation}`}
                            />
                        </Route>
                        :
                        null
                    }

                    <Route>

                        <div className="root-container container-fluid">
                            {uiState.modal}
                            <ConfirmationDialog/>

                            <Navbar className="navbar-dark bg-dark" fixed="top" expand="lg">
                                <NavbarToggler
                                    className="sidebar-toggler"
                                    onClick={() => this.setIsSidebarOpened()}
                                />

                                <NavbarBrand href="/" className="col-lg-2">
                                    Claimotion <img src={PAPER_PLANE_IMAGE} alt="Paper-plane"/>
                                </NavbarBrand>
                                
                                <NavbarToggler
                                    className="menu-toggler"
                                    onClick={() => this.setIsMenuOpened()}
                                />
                                
                                <Collapse
                                    className="col-lg-10"
                                    isOpen={uiState.isMenuOpen}
                                    navbar
                                >
                                    <Nav>
                                        <NavItem><NavLink to="/claims">Claims</NavLink></NavItem>
                                        <NavItem><NavLink to="/flights">Flights</NavLink></NavItem>
                                        <NavItem><NavLink to="/payments">Payments</NavLink></NavItem>
                                        <NavItem className="nav-item-search-box">
                                            <SearchBox/>
                                        </NavItem>
                                        <NavItem className="nav-item-message-dropdown">
                                            <MessageDropdown/>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="nav-link-dark" to="/templates">
                                                Templates
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="nav-link-dark" to="/airlines">
                                                Airlines
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="nav-item-dark">
                                            <NavLink className="nav-link-dark" to="/upload">
                                                Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="nav-item-user">
                                            <CurrentUser/>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                              </Navbar>

                            <Row className="root-row">
                                <Switch>
                                    <Route exact path="/" render={() => (<Redirect to="/claims" />)} />

                                    <Route path="/search" component={SearchResults} />

                                    <Route path="/claims" component={ClaimsModule} />
                                    <Route path="/flights" component={FlightsModule} />
                                    <Route path="/payments" component={PaymentModule} />
                                    <Route path="/templates" component={TemplatesModule} />
                                    <Route path="/airlines" component={AirlineModule} />
                                    <Route path="/upload" component={UploadModule} />
                                </Switch>

                            </Row>

                            <ToastContainer toastClassName="global-notification"/>

                            <CallControls/>

                        </div>
                    </Route>
                </Switch>
            </Router>
        </Provider>
    }
}
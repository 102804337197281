import * as React from 'react'
import {observer} from 'mobx-react'
import {
    Navbar, NavbarBrand, NavItem, Nav,
    Container, Row, Col,
    Form, Input,
    Badge
} from 'reactstrap'
import {Router, Switch, Route, Redirect} from 'react-router'
import {FlightList} from './FlightList'
import {FlightDetails} from './FlightDetails'


interface Props {
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
}

@observer
export class FlightsModule extends React.Component<Props> {
    render () {
        return <>
            <Col xs="12" className="content">
                <Switch>
                    <Route exact path="/flights" component={FlightList} />
                    <Route path="/flights/:id" component={FlightDetails}/>
                </Switch>
            </Col>
        </>
    }
}
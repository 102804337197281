import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import {UIState} from '../../state/ui'


@inject('uiState')
@observer
export class Link extends React.Component<{uiState?: UIState, to: string}> {
    render () {
        return <a href={this.props.to} onClick={this.onClick}>{this.props.children}</a>
    }

    @autobind
    onClick (e: React.MouseEvent<HTMLAnchorElement>) {
        // If any modifier keys are pressed or link was middle clicked, let the
        // browser handle it, mostly to allow opening links in new tabs.
        if (e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button == 1)){
            return
        }

        // Otherwise prevent page reload and let the router deal with the URL change
        e.preventDefault()

        // NB: External links won't work, setting path only
        this.props.uiState.routing.push(e.currentTarget.pathname)
    }
}

import { Entity } from './Entity'
import { observable } from 'mobx'
import { BankAccount } from './Payment'

export class Airline extends Entity {
    name: string
    iata_code: string
    icao_code: string
    @observable contact_email: string
    is_eu: boolean
    address_line1: string
    address_line2: string
    address_line3: string
    address_line4: string
    @observable bank_accounts: BankAccount[]
}

import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import {Modal} from '../common/Modal'
import {EmailAttachment} from '../../state/entities/EmailMessage'
import {Col, Form, FormGroup, Label} from 'reactstrap'
import DocumentLink from '../DocumentLink'
import {Select} from '../common/form-input/Select'
import {FieldState, FormState} from 'formstate'
import {CLAIM_DOCUMENT_TYPE_LABELS, ClaimDocumentType} from '../../state/entities/Claim'
import {AppState} from '../../state/app'
import {EntitySelect} from '../common/form-input/EntitySelect'
import {Passenger} from '../../state/entities/Passenger'


const DOCUMENT_TYPE_OPTS = [
    {
        value: ClaimDocumentType.UNSPECIFIED,
        label: CLAIM_DOCUMENT_TYPE_LABELS[ClaimDocumentType.UNSPECIFIED]
    },
    {
        value: ClaimDocumentType.POA_SIGNED,
        label: CLAIM_DOCUMENT_TYPE_LABELS[ClaimDocumentType.POA_SIGNED]
    },
]

interface Props {
    appState?: AppState
    attachment: EmailAttachment
}

@inject('appState')
@observer
export class AddAttachmentToClaimDocumentsModal extends React.Component<Props> {
    form = new FormState({
        type: new FieldState(ClaimDocumentType.UNSPECIFIED),
        passenger: new FieldState<Passenger>(null)
    })

    @autobind
    async onAttach() {
        let result = await this.form.validate()
        if (result.hasError) {
            return false
        }

        await this.props.appState.claimDetailsStore.addClaimDocumentFromEmailAttachment(
            this.props.attachment,
            this.form.$.type.value,
            this.form.$.passenger.value,
        )

        return true
    }

    render () {
        return <Modal actionLabel="Attach" onAction={this.onAttach} title="Add attachment to claim">
            <Form>
                <FormGroup row>
                    <Col sm={3}><Label>Document</Label></Col>
                    <Col sm={9}><DocumentLink document={this.props.attachment.document}/></Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={3}><Label>Type</Label></Col>
                    <Col sm={9}>
                        <Select options={DOCUMENT_TYPE_OPTS} field={this.form.$.type}/>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={3}><Label>Passenger (optional)</Label></Col>
                    <Col sm={9}>
                        <EntitySelect
                            options={this.props.appState.claimDetailsStore.claim.passengers}
                            emptyLabel="N/A"
                            getLabel={p=>p.name}
                            field={this.form.$.passenger}
                        />
                    </Col>
                </FormGroup>
            </Form>
        </Modal>
    }
}

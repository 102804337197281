import * as React from 'react'
import autobind from 'autobind-decorator'
import {inject, observer} from 'mobx-react'

import {AppState} from '../../state/app'
import {NotificationType, UIState} from '../../state/ui'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input} from 'reactstrap'
import CallControls from './CallControls'
import {FieldState, FormState} from 'formstate'
import {required} from '../../util/validation'
import {CALLER_IDS} from '../../state/voice-call'
import {TextInput} from '../common/TextInput'
import {Select} from '../common/form-input/Select'



interface Props {
    appState?: AppState
    uiState?: UIState
    phoneNumber: string
    close: Function
}

@inject('appState', 'uiState')
@observer
export default class StartCallModal extends React.Component<Props> {
    formState = new FormState({
        phoneNumber: new FieldState('').validators(required()),
        callerId: new FieldState(CALLER_IDS[0].callerId).validators(required())
    })

    constructor (props) {
        super(props)

        this.formState.$.phoneNumber.onChange(props.phoneNumber)
    }

    @autobind
    onCallClick (e) {
        e.preventDefault()

        this.props.appState.voiceCallStore.startCall(
            this.formState.$.phoneNumber.value,
            this.formState.$.callerId.value,
        )
        this.props.close()
    }

    render() {
        return <Modal isOpen={true}>
            <ModalHeader toggle={(evt: React.MouseEvent) => this.props.close()}>Start call</ModalHeader>

            <ModalBody>
                <FormGroup>
                    <Label>Phone number</Label>
                    <TextInput field={this.formState.$.phoneNumber}/>
                </FormGroup>

                <FormGroup>
                    <Label>Call from number</Label>
                    <Select
                        options={
                            CALLER_IDS.map(
                                i => ({value: i.callerId, label: `${i.callerId} ${i.name}`})
                            )
                        }
                        field={this.formState.$.callerId}
                    />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onCallClick}>Call</Button>{' '}
                <Button color="secondary" onClick={ (evt: React.MouseEvent) => this.props.close() }>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

import * as qs from 'qs'
import * as moment from 'moment'
import {ApiClient, ServerError} from '../api/client'
import {Flight} from './entities/Flight'
import {observable} from 'mobx'
import {Claim} from './entities/Claim'
import {FormState, FieldState, ValidatableMapOrArray} from 'formstate'
import {Airline} from './entities/Airline'
import {Airport} from './entities/Airport'
import { NebDecision } from './entities/RegulatorDecision';


type FlightListFilterFormProps = {
    airline: FieldState<Airline>
    departure_airport: FieldState<Airport>
    arrival_airport: FieldState<Airport>
    departure_date_after: FieldState<Date>
    departure_date_before: FieldState<Date>
    is_active_only: FieldState<boolean>
}

export class FlightListFilterState extends FormState<FlightListFilterFormProps> {
    constructor () {
        super({
            airline: new FieldState<Airline>(null),
            departure_airport: new FieldState<Airport>(null),
            arrival_airport: new FieldState<Airport>(null),
            departure_date_after: new FieldState<Date>(null),
            departure_date_before: new FieldState<Date>(null),
            is_active_only: new FieldState<boolean>(false),
        })
    }
}

export class FlightListStore {
    apiClient: ApiClient
    @observable flights: Flight[] = []
    @observable loading: boolean = false

    @observable filters: FlightListFilterState = new FlightListFilterState()

    loadRequestSerial: number = 0

    constructor (apiClient:ApiClient) {
        this.apiClient = apiClient
    }

    resetFilters () {
        this.filters = new FlightListFilterState()
    }

    get isFiltered () {
        for (let k in this.filters.$) {
            if (this.filters.$[k].value != null) {
                return true
            }
        }

        return false
    }

    flattenFilters () {
        let result = {}
        for (let k in this.filters.$) {
            let val = this.filters.$[k].value

            if (val == null) {
                continue
            }

            if (val.constructor == Date) {
                val = moment(val).format('YYYY-MM-DD')
            } else if (typeof val === 'object' && val.id != null) {
                val = val.id
            }

            if (k == 'is_active_only') {
                k = 'status'
                val = val ? 'active' : null
            }
            
            result[k] = val
        }
        return result
    }

    async load () {
        this.loadRequestSerial++
        let serial = this.loadRequestSerial

        let query = qs.stringify(this.flattenFilters())
        if (query.length) {
            query = `?${query}`
        }

        this.loading = true
        let result = await this.apiClient.makeCall('GET', `/flights${query}`)
        this.loading = false

        // Don't clobber the store data if this response is not for the latest request
        if (serial != this.loadRequestSerial) {
            return
        }

        this.flights = (result.data as Array<object>).map((t) => new Flight(t))
    }
}


type FlightDetailsFilterFormProps = {
    is_active_only: FieldState<boolean>
}

export class FlightDetailsFilterState extends FormState<FlightDetailsFilterFormProps> {
    constructor () {
        super({
            is_active_only: new FieldState<boolean>(false),
        })
    }
}

export class FlightDetailsStore {
    apiClient: ApiClient
    @observable flight: Flight = null
    @observable claims: Claim[] = []

    @observable filters: FlightDetailsFilterState = new FlightDetailsFilterState()

    constructor (apiClient:ApiClient) {
        this.apiClient = apiClient
    }

    resetFilters () {
        this.filters = new FlightDetailsFilterState()
    }

    get isFiltered () {
        for (let k in this.filters.$) {
            if (this.filters.$[k].value != false) {
                return true
            }
        }

        return false
    }

    flattenFilters () {
        let result = {}
        for (let k in this.filters.$) {
            let val = this.filters.$[k].value

            if (val == null) {
                continue
            }

            if (val.constructor == Date) {
                val = moment(val).format('YYYY-MM-DD')
            } else if (typeof val === 'object' && val.id != null) {
                val = val.id
            }

            if (k == 'is_active_only') {
                k = 'status'
                val = val ? 'active' : null
            }

            result[k] = val
        }
        return result
    }

    async load (id: number) {
        let flightResponse = await this.apiClient.makeCall('GET', `/flights/${id}`)
        if (flightResponse.success === true) {
            this.flight = new Flight(flightResponse.data)
        } else {
            throw new ServerError(flightResponse.data.message, flightResponse.data.details)
        }

        let query = qs.stringify(this.flattenFilters())
        if (query.length) {
            query = `&${query}`
        }

        let claimResponse = await this.apiClient.makeCall('GET', `/claims?flight_id=${id}${query}`)
        if (claimResponse.success === true) {
            this.claims = (claimResponse.data as Array<object>).map((t) => new Claim(t))
        } else {
            throw new ServerError(claimResponse.data.message, claimResponse.data.details)
        }
    }

    async setRegulatorDecision(neb_decision: NebDecision, upload: string) {
        await this.apiClient.makeCall(
            'POST',
            `/flights/${this.flight.id}/regulator-decision`,
            {
                data: {
                    neb_decision: neb_decision,
                    upload: upload
                }
            }
        )
        await this.load(this.flight.id)
    }
}

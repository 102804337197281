import * as React from 'react'
import * as qs  from 'qs'
import autobind from 'autobind-decorator'
import {AgGridReact} from 'ag-grid-react'
import {inject, observer} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from '../../state/ui'
import {Flight} from '../../state/entities/Flight'

import { Spinner } from 'react-activity'
import { Row, Col, Button } from 'reactstrap'
import { ColDef, RowClickedEvent } from 'ag-grid-community'

import {formatDateColumn} from '../common/grid-utils'
import {FlightListFilters} from './FlightListFilters'

import 'react-activity/dist/react-activity.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {Link} from '../common/Link'


enum Column {
    Id,
    AirlineIATA,
    FlightNumber,
    DepartureDateScheduled,
    DepartureAirportIATA,
    ArrivalAirportIATA,
    IsCompPaid,
    PaxCount,
    ClaimCount,
}


const COLUMN_DEFS: {[index in Column]: ColDef} = {
    [Column.Id]: {
        headerName: "ID",
        valueGetter: params => params.data.id,
        cellRendererFramework: props => <Link to={`/flights/${props.value}`}>{props.value}</Link>,
    },
    [Column.AirlineIATA]: {
        headerName: "Airline",
        field: 'airline.iata_code'
    },
    [Column.FlightNumber]: {
        headerName: 'Flight №',
        field: 'flight_number',
    },
    [Column.DepartureDateScheduled]: {
        headerName: 'Departure',
        field: 'departure_date',
        valueFormatter: formatDateColumn,
        sortable: true,
    },
    [Column.DepartureAirportIATA]: {
        headerName: 'From',
        field: 'departure_airport.iata_code'
    },
    [Column.ArrivalAirportIATA]: {
        headerName: 'To',
        field: 'arrival_airport.iata_code'
    },
    [Column.IsCompPaid]: {
        headerName: 'Comp paid',
        field: 'is_comp_paid',
        valueFormatter: params => params.value ? "YES" : "NO"
    },
    [Column.PaxCount]: {
        headerName: 'Pax',
        field: 'pax_count',
    },
    [Column.ClaimCount]: {
        headerName: 'Claims',
        field: 'claim_count',
    }
}

function getColumnDefs () {
    return [
        COLUMN_DEFS[Column.Id],
        COLUMN_DEFS[Column.AirlineIATA],
        COLUMN_DEFS[Column.FlightNumber],
        COLUMN_DEFS[Column.DepartureDateScheduled],
        COLUMN_DEFS[Column.DepartureAirportIATA],
        COLUMN_DEFS[Column.ArrivalAirportIATA],
        COLUMN_DEFS[Column.PaxCount],
        COLUMN_DEFS[Column.ClaimCount],
        COLUMN_DEFS[Column.IsCompPaid],
    ]
}


interface Props {
    appState?: AppState,
    uiState?: UIState,
    location: Location,
}

@inject('appState', 'uiState')
@observer
export class FlightList extends React.Component<Props> {
    state = {
        showFilters: false
    }

    componentDidMount(): void {
        const store = this.props.appState.flightListStore
        store.resetFilters()
        this.setFiltersFromURL().then(() => {
            store.load()
        })
    }

    onGridReady (params) {
        params.columnApi.autoSizeAllColumns()
    }

    @autobind
    onClearFilters () {
        this.props.appState.flightListStore.resetFilters()
        this.props.uiState.routing.push('/flights')
        this.props.appState.flightListStore.load()
    }

    async setFiltersFromURL () {
        if (this.props.location.search) {
            let urlSearchFilters = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            let filters = this.props.appState.flightListStore.filters.$

            if (urlSearchFilters['airline']) {
                let url = `/api/airlines?iata_code=${urlSearchFilters['airline']}&random=${Math.random()}`
                let airline = (await (await fetch(url)).json())[0]
                filters.airline.onChange(airline)
            }
            if (urlSearchFilters['departure_airport']) {
                let url = `/api/airports?iata_code=${urlSearchFilters['departure_airport']}&random=${Math.random()}`
                let departure_airport = (await (await fetch(url)).json())[0]
                filters.departure_airport.onChange(departure_airport)
            }
            if (urlSearchFilters['arrival_airport']) {
                let url = `/api/airports?iata_code=${urlSearchFilters['arrival_airport']}&random=${Math.random()}`
                let arrival_airport = (await (await fetch(url)).json())[0]
                filters.arrival_airport.onChange(arrival_airport)
            }
            
            if (urlSearchFilters['departure_date_after']) {
                let departure_date_after = new Date(String(urlSearchFilters['departure_date_after']))
                filters.departure_date_after.onChange(departure_date_after)
            }
            if (urlSearchFilters['departure_date_before']) {
                let departure_date_before = new Date(String(urlSearchFilters['departure_date_before']))
                filters.departure_date_before.onChange(departure_date_before)
            }
            if (urlSearchFilters['status']) {
                let is_active_only = urlSearchFilters['status'] === 'active'
                filters.is_active_only.onChange(is_active_only)
            }
        }
    }

    render () {
        let store = this.props.appState.flightListStore
        let key = 'empty'
        if (store.flights.length) {
            key = String(store.flights[0].id)
        }

        return <div className="payment-list ag-theme-balham">
            <Row>
                <Col sm={6}><h1 className="title-main">Flights</h1></Col>
                <Col sm={6} className="text-right">
                    <Button
                        outline
                        size="sm"
                        onClick={() => this.setState({showFilters: !this.state.showFilters})}
                    >
                        {this.state.showFilters ? "Hide filters" : "Show filters"}
                    </Button>
                    {" "}
                    { store.isFiltered ?
                        <Button outline size="sm" onClick={this.onClearFilters}>
                            Clear filters
                        </Button>
                        :
                        null
                    }
                </Col>
            </Row>

            { this.state.showFilters ? <FlightListFilters/> : null }

            {
                this.props.appState.flightListStore.loading ?
                    <div className="loading">
                        <Spinner size={24}/> <span>Loading...</span>
                    </div>

                    :

                    <>
                        <div className="grid-container">
                            <AgGridReact
                                key={key}
                                columnDefs={getColumnDefs()}
                                rowData={this.props.appState.flightListStore.flights}
                                onGridReady={this.onGridReady}
                                defaultColDef={{resizable: true, unSortIcon: true}}
                                suppressColumnVirtualisation={true}
                                autoSizePadding={0}
                            />
                        </div>
                    </>

            }
        </div>

    }
}

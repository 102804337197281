import * as React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Alert} from 'reactstrap'
import {Claim} from '../../state/entities/Claim'
import * as Moment from "moment"
const momentLocalizer = require('react-widgets-moment')
import { DatePicker } from 'react-widgets'

Moment.locale('en')
Moment.updateLocale('en', {week : {dow : 1, doy : 4}});  // Start week on Monday
momentLocalizer()

import 'react-widgets/dist/css/react-widgets.css'
import autobind from 'autobind-decorator'


interface Props {
    claim: Claim
    setActivateAt: (activateAt: Date | null, memo: string | null) => void
    cancel: React.MouseEventHandler<any>
}


export default class ActivateAtModal extends React.Component<Props> {
    state = {
        memo: '',
        activateAt: null,
    }

    constructor (props) {
        super(props)

        this.state.memo = props.claim.activate_memo
        this.state.activateAt = props.claim.activate_at
    }

    @autobind
    onActivateAtChange (date) {
        let m = Moment(date)

        // Shift to UTC midnight of the selected date
        m.hour(0)
        m.minute(0)
        m.second(0)
        m.millisecond(0)

        m.add(m.utcOffset(), 'minutes')

        this.setState({activateAt: m.toDate()})
    }

    @autobind
    onSetClick (e) {
        e.preventDefault()

        this.props.setActivateAt(this.state.activateAt, this.state.memo)
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={this.props.cancel}>Set next activity date</ModalHeader>
            <ModalBody>
                Next activity date:
                <DatePicker
                    defaultValue={this.state.activateAt}
                    name="activate-at"
                    format="DD.MM.YYYY"
                    onChange={this.onActivateAtChange}
                />

                Memo:
                <Input
                    type="textarea"
                    value={this.state.memo || ''}
                    onChange={(e) => this.setState({memo: e.target.value})}
                />
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onSetClick}>Set</Button>{' '}
                <Button color="secondary" onClick={this.props.cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

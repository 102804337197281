import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {ModuleSidebar} from '../common/ModuleSidebar'
import {Badge} from 'reactstrap'
import {NavLink} from '../NavLink'
import {AppState} from '../../state/app'


@inject('appState')
@observer
export class PaymentSidebar extends React.Component<{appState?: AppState}> {
    render () {
        let counts = this.props.appState.paymentStatusCounts

        return <ModuleSidebar>
            <div className="sidebar-title">Payments</div>
            <div className="level-2">
                <NavLink to="/payments/received">Received <Badge>{counts.received}</Badge></NavLink>
                <NavLink to="/payments/pending">Pending <Badge>{counts.pending}</Badge></NavLink>
                <NavLink to="/payments/sent">Sent <Badge>{counts.sent}</Badge></NavLink>
            </div>
        </ModuleSidebar>
    }

    componentDidMount() {
        this.props.appState.paymentStatusCounts.reload()
    }
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./login-bg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#login-container{height:100vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;color:#666;font-size:1rem}#login-container button{font-size:1rem}#login-container #or{margin-top:2rem;margin-bottom:1rem;text-align:center}#login-container label{margin-bottom:.125rem}#login-container h1{text-align:left;color:#fff;padding:1rem;font-size:2rem}#login-container h2{color:#555;font-size:1.25rem;margin-bottom:1rem;text-align:center}#login-container #the-sign-in-button{display:inline-block}#login-container .wrap-button{background-color:rgba(255,255,255,.8);padding:2rem 3rem;border-radius:.5rem}", ""]);
// Exports
module.exports = exports;

import * as React from 'react'
import autobind from "autobind-decorator"
import { Row, Col, Button } from 'reactstrap'
import * as Uppy from '@uppy/core/lib'
import * as XHRUpload from '@uppy/xhr-upload'
import * as UppyDragDrop from '@uppy/react/lib/DragDrop'
import * as UppyProgressBar from '@uppy/react/lib/ProgressBar'
import * as cookie from 'cookie'
import { inject } from 'mobx-react'
import { AppState } from '../../state/app'
import { UIState } from '../../state/ui'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import './UploadClaimStatusExcel.scss'

@inject('appState')
@inject('uiState')
export class UploadClaimStatusExcel extends React.Component
<{appState?: AppState, uiState?: UIState}>
{
    uppy = Uppy.Uppy
    state = {
        uploads: [],
    }

    constructor (props) {
        super(props)

        this.uppy = Uppy({
            id: 'UploadClaimStatusExcel',
            autoProceed: true,
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: [
                    'application/vnd.ms-excel',
                    '.xlsx',
                ]
            }
        }).use(
            XHRUpload, {
                endpoint: '/admin-api/uploads',
                fieldName: 'file',
                headers: {
                    'X-CSRFToken': cookie.parse(document.cookie)['csrftoken']
                }
            }
        )
        this.uppy.on('upload-success', this.onUploadSuccess)
        this.uppy.run()
    }

    @autobind
    async onUpdateStatusesClick () {
        if (this.state.uploads.length) {
            const upload = this.state.uploads[0]
            const updatedClaimCount =
                await this.props.appState.claimListStore.importClaims(upload.id)
            this.props.uiState.showNotification(`There were updated ${updatedClaimCount} claims`)
            this.setState(
                {
                    uploads: [],
                }
            )
        }
    }

    @autobind
    onUploadSuccess (file, resp, uploadURL) {
        console.log("Upload success", file, resp, uploadURL)
        this.setState(
            { uploads: this.state.uploads.concat([{id: resp.body.id, name: file.name}]) }
        )
    }

    @autobind
    clearUploads () {
        this.setState(
            { uploads: [] }
        )
    }

    render () {
        return (
            <div className="upload-claim-status-excel ag-theme-balham">
                <Row>
                    <Col sm={6}>
                        <h1 className="title-main">Claim status update</h1>
                        <ol className="uploaded-file-requirenments">
                            <li>
                                There have to be two columns "case_number" and "new_status" in an Excel file.
                            </li>
                            <li>
                                "new_status" has to be equal to "Partial / Incomplete", "Partial / Complete",
                                "Submit / Unsigned", "Review / New", "Review / Rejected", "Review / Accepted",
                                "Review / Abandoned", "Prepare / Complete", "Claim / Submitted", "Claim / Accepted",
                                "Claim / Rejected", "Claim / Invalid", "NEB / New", "NEB / Submitted", "Court / New",
                                "Court / Submitted", "Settle / Payment received", "Settle / Payout ready",
                                "Settle / Paid out", "Settle / Abandoned" or "Misc / Manually closed".
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col sm={6}>
                        {this.state.uploads.map((i) => <div>{i.name}</div>)}
                        <UppyDragDrop uppy={this.uppy}/>
                        <UppyProgressBar uppy={this.uppy}/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Button color="primary" onClick={this.onUpdateStatusesClick}>
                            Update statuses
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

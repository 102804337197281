import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {AppState} from '../state/app'
import {FormState, FieldState} from 'formstate'
import {Form, FormGroup, FormFeedback, Label, Button, Container, Row, Col} from 'reactstrap'

import {TextInput} from './common/TextInput'
import {required, validateEmail} from '../util/validation'

import './LoginScreen.scss'
import {FieldFeedBack} from './common/FieldFeedBack'


@inject("appState")
@observer
export default class LoginScreen extends React.Component<{appState?: AppState}> {
    loginFormState = new FormState({
        email: new FieldState<string>('').validators(required(), validateEmail),
        password: new FieldState<string>('').validators(required())
    })

    state = {
        loginFailed: false
    }

    render () {
        console.log("RENDER", this.state.loginFailed)
        return <div id="login-container">
            <Container>
                <h1>Claimotion</h1>

                <Row>
                    <Col className="wrap-button" sm={{size: 6, offset: 3}}>
                        <h2>Sign in to Claimotion</h2>

                        <div className="text-center">
                            <div id='the-sign-in-button'/>
                        </div>

                        <div id="or">- or -</div>

                        <Form onSubmit={this.onPasswordLogin}>
                            <FormGroup className="mb-1">
                                <Label for="email">Email</Label>
                                <TextInput
                                    field={this.loginFormState.$.email}
                                    type="email"
                                    name="email"
                                    placeholder="name@example.com"
                                />
                                <FieldFeedBack field={this.loginFormState.$.email}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                <TextInput
                                    field={this.loginFormState.$.password}
                                    type="password"
                                    name="password"
                                    placeholder="password"
                                />
                                <FieldFeedBack field={this.loginFormState.$.password}/>
                            </FormGroup>

                            <Button
                                className="mt-4" color='primary' block={true}>
                                Log in
                            </Button>
                            {
                                this.state.loginFailed ?
                                    <FormFeedback className="d-block">Invalid email or password</FormFeedback>
                                    :
                                    null
                            }
                        </Form>
                    </Col>

                </Row>

            </Container>

        </div>

    }

    onPasswordLogin = async (e) => {
        e.preventDefault()

        let loginStatus = await this.props.appState.auth.passwordLogin(
            this.loginFormState.$.email.value,
            this.loginFormState.$.password.value
        )

        console.log("Login status", loginStatus, this.state.loginFailed)
        this.setState({loginFailed: !loginStatus})
        if (!loginStatus) {
            this.loginFormState.$.password.reset()
        }
    }

    componentDidMount () {
        this.props.appState.auth.renderGoogleSignInButton("the-sign-in-button")
        this.loginFormState.reset()
    }
}

import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import {AppState} from '../../state/app'
import {NotificationType, UIState} from '../../state/ui'
import {Template} from '../../state/entities/Template'
import {FieldState, FormState} from 'formstate'
import {required} from '../../util/validation'
import {ServerError, ValidationError} from '../../api/client'

import {Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap'
import {
    Editor,
    EditorState,
    CompositeDecorator,
    convertToRaw,
    convertFromRaw,

} from 'draft-js'


import './TemplateEditor.scss'
import 'draft-js/dist/Draft.css'

const UNBALANCED_BRACKET_REGEX = /(?=.*{[^}]*{)|(?=.*}[^{]*})/g
function variablesTerminated (value: string) {
    if (value.match(UNBALANCED_BRACKET_REGEX)) {
        return 'Unterminated variable found (mismatched "{" and "}")'
    }
    return null
}

class TemplateEditorFormState {
    name = new FieldState<string>('').validators(required())
    content = new FieldState<string>('').validators(required(), variablesTerminated)
    notes = new FieldState<string>('')

    form = new FormState({
        name: this.name,
        content: this.content,
        notes: this.notes,
    })

    constructor (template?: Template) {

        if (template) {
            this.name.reset(template.name)
            this.content.reset(template.content)
            this.notes.reset(template.notes)
        }
    }
}


function renderError (field:FieldState<any>) {
    if (field.hasError) {
        return <FormFeedback valid={false}>{field.error}</FormFeedback>
    }

    return null
}


let keySerial = 0;
export function generateBlockKey() {
  return String(keySerial++);

}


interface Props {
    appState?: AppState
    uiState?: UIState
    id: number | null
}


export const TEMPLATE_VAR_REGEX = /{[\w_]+}/g

function markTemplateVars (contentBlock, callback, currentState) {
    let text = contentBlock.getText()

    let matchArr, start;
    while ((matchArr = TEMPLATE_VAR_REGEX.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

class TemplateVar extends React.Component<{key:any}> {
    render () {
        return <span className="template-var">{this.props.children}</span>
    }
}

const DRAFT_DECORATOR = new CompositeDecorator(
    [{strategy: markTemplateVars, component: TemplateVar}]
)

@inject('appState')
@inject('uiState')
@observer
export class TemplateEditor extends React.Component<Props> {
    formState: TemplateEditorFormState
    state = {
        ready: false,
        editorState: EditorState.createEmpty(DRAFT_DECORATOR),
    }

    constructor (props: Props) {
        super(props)
    }

    async componentDidMount () {
        let template: Template = null
        if (this.props.id !== null) {
            template = await this.props.appState.templateStore.get(this.props.id)
        }

        this.formState = new TemplateEditorFormState(template)

        this.setState({
            ready: true
        })

        if (!template) {
            return
        }

        let blocks = template.content.split('\n').map(
            (text) => {
                return {
                    text,
                    key: generateBlockKey(),
                    type: 'paragraph' as 'paragraph',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: []
                }
            }
        )

        this.setState({
            editorState: EditorState.createWithContent(
                convertFromRaw({blocks, entityMap: {}}),
                DRAFT_DECORATOR
            ),
        })
    }

    @autobind
    async onSubmit (e) {
        e.preventDefault()

        let res = await this.formState.form.validate()

        if (!res.hasError) {
            let template = new Template({
                id: this.props.id,
                name: this.formState.name.value,
                content: this.formState.content.value,
                notes: this.formState.notes.value,
            })

            try {
                await this.props.appState.templateStore.save(template)
                this.props.uiState.showNotification("Template saved")

                if (this.props.id === null) {
                    // Saved new template, redirect to the stored version
                    this.props.uiState.routing.push(`/templates/${template.id}`)
                }

            } catch (exc) {
                if (exc instanceof ValidationError) {
                    for (let k in exc.details) {
                        (this.formState[k] as FieldState<any>).setError(exc.details[k])
                    }
                } else if (exc instanceof ServerError) {
                    this.props.uiState.showNotification(
                        "Failed to save template: " + exc.message,
                        NotificationType.Error
                    )
                } else {
                    throw exc
                }
            }
        }
    }

    @autobind
    onEditorChange (editorState: EditorState) {
        this.setState({editorState})

        let content = convertToRaw(editorState.getCurrentContent())

        let text = ''
        for (let block of content.blocks) {
            text += block.text + '\n'
        }

        this.formState.content.onChange(text)
    }

    render () {
        if (!this.state.ready) return null

        let form = this.formState

        return <Form className="template-editor pb-5 pt-4" onSubmit={this.onSubmit}>
            <h1 className="title-main mb-4">Edit template</h1>

            <FormGroup className="mt-4">
                <Label>Name</Label>
                <Input
                    className="name-input"
                    value={form.name.value}
                    invalid={form.name.hasError}
                    onChange={(e) => form.name.onChange(e.target.value)}
                />
                <FormFeedback>{form.name.error}</FormFeedback>
            </FormGroup>

            <FormGroup className="mt-4">
                <Label>Content</Label>

                <div className={`form-control content-input ${form.content.error ? 'is-invalid' : ''}`}>
                    <Editor
                        editorState={this.state.editorState}
                        onChange={this.onEditorChange}
                    />
                </div>

                <FormFeedback>{form.content.error}</FormFeedback>
            </FormGroup>

            <FormGroup className="mt-4 mb-4">
                <Label>Notes</Label>
                <Input
                    className="notes-input"
                    type="textarea"
                    value={form.notes.value}
                    invalid={form.notes.hasError}
                    onChange={(e) => form.notes.onChange(e.target.value)}
                />
                <FormFeedback>{form.notes.error}</FormFeedback>
            </FormGroup>

            <Button>Save</Button>
        </Form>
    }
}

import * as React from "react";
import * as qs  from 'qs'
import * as moment from 'moment'
import autobind from 'autobind-decorator'
import {inject, observer} from 'mobx-react'

import {AppState} from "../../state/app";
import {UIState} from '../../state/ui'
import {PaymentSet} from '../../state/payment'
import {Payment, PaymentDetailsRequest} from '../../state/entities/Payment'
import {Party} from '../../state/entities/Common'
import {Claim} from "../../state/entities/Claim"

import {Row, Col, Button} from 'reactstrap'
import {RegisterPaymentModal} from './RegisterPaymentModal'
import ClaimLinkRenderer from '../claim-list/cell-renderers/ClaimLinkRenderer'
import DataTable, {IDataTableColumn} from 'react-data-table-component'

import {PaymentListFiltersModal} from "./PaymentListFiltersModal";

import 'react-activity/dist/react-activity.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './PaymentList.scss'


enum Column {
    Id,
    FromParty,
    ToParty,
    Amount,
    Currency,
    Status,
    AirlineIATA,
    CreatedAt,
    SettledAt,
    Claim,
    BankDetailStatus,
    PayoutStatus,
}

interface ColumnDef<T> extends IDataTableColumn<T> {
    sortField?: string
}

const COLUMN_DEFS: { [index in Column]: ColumnDef<Payment> } = {
    [Column.Id]: {
        name: '№',
        selector: i => i,
        cell: i => <EditPaymentLink payment={i} label={`${i.id}`}/>,

    },

    [Column.FromParty]: {
        name: 'From',
        selector: 'from_party',
    },

    [Column.ToParty]: {
        name: 'To',
        selector: 'to_party',
    },
    
    [Column.Amount]: {
        name: 'Amount',
        selector: 'amount',
    },
    
    [Column.Currency]: {
        name: 'Currency',
        selector: 'currency',
    },
    
    [Column.Status]: {
        name: 'Status',
        selector: 'statusLabel',
    },
    
    [Column.AirlineIATA]: {
        name: 'Airline',
        selector: 'airline.iata_code',
    },
    
    [Column.CreatedAt]: {
        name: 'Registered on',
        selector: i => formatDateColumn(i.created_at),
        sortable: true,
        sortField: 'created_at',
    },
    
    [Column.SettledAt]: {
        name: 'Settled on',
        selector: i => formatDateColumn(i.settled_at),
        sortable: true,
        sortField: 'settled_at',
    },
    
    [Column.Claim]: {
        name: 'Claim',
        selector: i => i,
        cell: i => 
            i.claim ? 
                <ClaimLinkRenderer value={i.claim}/> 
            : 
                <EditPaymentLink payment={i} label="Assign"/>
        ,
    },

    [Column.BankDetailStatus]: {
        name: 'Bank details',
        selector: i => formatBankDetailsStatusLabel(i),
    },

    [Column.PayoutStatus]: {
        name: "Client payment",
        cell: i => formatPayoutStatusLabel(i),
    }
}
    


const DEFAULT_COLUMNS = [
    Column.Id,
    Column.FromParty,
    Column.ToParty,
    Column.Amount,
    Column.Currency,
    Column.CreatedAt,
    Column.SettledAt,
    Column.Status,
    Column.Claim,
]


const SET_CONFIG = {
    received: {
        columns: [
            Column.Id,
            Column.Amount,
            Column.Currency,
            Column.CreatedAt,
            Column.SettledAt,
            Column.BankDetailStatus,
            Column.PayoutStatus,
            Column.Claim,
        ]
    }
    // incoming: {
    //     columns: [
    //         Column.Status,
    //         Column.AirlineIATA
    //     ]
    // },
}

function formatDateColumn(value) {
    if (!value) {
        return 'N/A'
    }

    return moment.utc(value).format('YYYY.MM.DD')
}

function formatBankDetailsStatusLabel(payment: Payment) : string {
    let req: PaymentDetailsRequest = null
    if (payment.claim?.payment_details_requests?.length) {
        req = payment.claim.payment_details_requests[0]
    }

    if (!req) {
        return "Not requested"
    } else if (req.payment_details) {
        return "Available"
    } else {
        return "Requested"
    }
}


function formatPayoutStatusLabel(payment: Payment) : string {
    let havePaymentsOut = payment.claim?.payments.filter(i => i.from_party == Party.SERVICE).length

    return havePaymentsOut ? "Yes" : "No"
}


function getColumnDefs(paymentSet): IDataTableColumn<Payment>[] {
    let columns = DEFAULT_COLUMNS
    let sort_ = null

    if (SET_CONFIG[paymentSet]) {
        columns = SET_CONFIG[paymentSet].columns
        sort_ = SET_CONFIG[paymentSet].sort
    }

    return columns.map((i) => {
        let colDef = {...COLUMN_DEFS[i]}
        // if (sort_ && i == sort_.column) {
        //     colDef.sort = sort_.direction
        // }
        return colDef
    })
}


@inject('uiState')
class EditPaymentLink extends React.Component<{uiState?: UIState, payment:Payment, label:string}> {
    render () {
        return <a href="#" onClick={this.onClick}>{this.props.label}</a>
    }

    @autobind
    onClick (e) {
        e.preventDefault()

        this.props.uiState.showModal(
            <RegisterPaymentModal
                fromParty={this.props.payment.from_party}
                close={this.props.uiState.closeModal}
                initial={this.props.payment}
            />
        )
    }
}


interface Props {
    appState?:AppState, 
    uiState?: UIState, 
    paymentSet?:PaymentSet, 
    location: Location,
}

@inject('appState', 'uiState')
@observer
export class PaymentList extends React.Component<Props>
{
    componentDidMount () {
        let store = this.props.appState.paymentListStore
        store.paymentSet = this.props.paymentSet
        store.resetFilters()
        this.setFiltersFromURL().then(() => {
            store.load()
        })
    }

    onGridReady (params) {
        params.columnApi.autoSizeAllColumns()
    }

    @autobind
    onClearFilters () {
        let store = this.props.appState.paymentListStore
        this.props.appState.paymentListStore.resetFilters()
        this.props.uiState.routing.push(`/payments${'/' + store.paymentSet || ''}`)
        this.props.appState.paymentListStore.load()
    }

    async setFiltersFromURL () {
        if (this.props.location.search) {
            let urlSearchFilters = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            let filters = this.props.appState.paymentListStore.filters.$

            if (urlSearchFilters['claim']) {
                let url = `/admin-api/claims?search=id:${urlSearchFilters['claim']}&random=${Math.random()}`
                let claim = (await (await fetch(url)).json())[0]
                filters.claim.onChange(claim)
            }

            for (let key of ['created_at_before', 'created_at_after', 'settled_at_before', 'settled_at_after']) {
                if (urlSearchFilters[key]) {
                    console.log("DATE FILTER", key, urlSearchFilters[key], filters[key].onChange)
                    filters[key].onChange(new Date(urlSearchFilters[key] as string))
                }
            }
        }
    }

    getTitle () {
        switch (this.props.paymentSet) {
            case PaymentSet.RECEIVED:
                return "Received payments"

            case PaymentSet.PENDING:
                return "Pending payments"

            case PaymentSet.SENT:
                return "Sent payments"
        }
    }

    @autobind
    onShowFilters() {
        this.props.uiState.showModal(<PaymentListFiltersModal/>)
    }

    @autobind
    onRegisterClick (e) {
        e.preventDefault()

        this.props.uiState.showModal(
            <RegisterPaymentModal
                fromParty={
                    this.props.paymentSet == PaymentSet.RECEIVED ?
                        Party.AIRLINE
                        :
                        Party.SERVICE
                }
                close={this.props.uiState.closeModal}
            />
        )
    }

    @autobind
    onSort(column: ColumnDef<any>, direction: "asc" | "desc") {
        let store = this.props.appState.paymentListStore
        store.setSort(column.sortField, direction == "asc")
    }

    render () {
        let store = this.props.appState.paymentListStore

        return <div className="payment-list ag-theme-balham">
            <Row className="mb-2">
                <Col sm={6}>
                    <h1 className="title-main">{this.getTitle()} <Button onClick={this.onRegisterClick}>Register</Button></h1>
                </Col>

                <Col sm={6} className="text-right">
                    { store.isFiltered ?
                        <>
                            <Button outline size="sm" onClick={this.onClearFilters}>
                                Clear filters
                            </Button>
                            {" "}
                        </>
                        :
                        null
                    }
                    <Button
                        outline
                        size="sm"
                        onClick={() => this.onShowFilters()}
                    >
                        Filter
                    </Button>
                </Col>
            </Row>

            <div className="grid-container">
                <DataTable
                    columns={getColumnDefs(this.props.paymentSet)}
                    data={this.props.appState.paymentListStore.payments}
                    dense={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={"calc(100vh - 11rem)"}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    paginationComponentOptions={{noRowsPerPage: true}}
                    paginationPerPage={20}
                    paginationDefaultPage={store.page+1}
                    paginationTotalRows={store.count}
                    sortServer={true}
                    onSort={this.onSort}
                    progressPending={store.loading}
                    onChangePage={store.changePage}
                />
            </div>
        </div>
    }
}

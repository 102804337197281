import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {ModuleSidebar} from '../common/ModuleSidebar'
import {Button} from 'reactstrap'
import {NavLink} from '../NavLink'
import {AppState} from '../../state/app'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {UIState} from '../../state/ui'


@inject('appState')
@inject('uiState')
@observer
export class TemplatesSidebar extends React.Component<{appState?: AppState, uiState?: UIState}> {
    render () {
        return <ModuleSidebar>
            <div className="sidebar-title">Templates</div>
            <div className="level-2">
                <Button
                    size="sm"
                    onClick={() => this.props.uiState.routing.push(`/templates/new`)}
                >
                    <FontAwesomeIcon icon={faPlus} className="mr-1"/>
                    New template
                </Button>
                {
                    this.props.appState.templateStore.templates.map(
                        (t) => <NavLink to={`/templates/${t.id}`} key={t.id}>
                            {t.name}
                        </NavLink>
                    )
                }
            </div>
        </ModuleSidebar>
    }

    componentDidMount() {
        this.props.appState.templateStore.load()
    }
}

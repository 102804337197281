import autobind from 'autobind-decorator'
import {ApiClient} from '../api/client'
import {computed, observable, observe, reaction} from 'mobx'
import {Claim, ClaimDocumentType} from './entities/Claim'
import {Passenger} from './entities/Passenger'
import {EmailAttachment} from './entities/EmailMessage'
import {TripSegment} from './entities/TripSegment'
import {Flight} from './entities/Flight'
import {AppState} from './app'

interface ManualCompensationChangeProps {
    amountPerPassenger?: string;
    comment?: string;
    isAutoAmount: boolean;
}

@autobind
export class ClaimDetailsStore {
    parent: AppState
    apiClient: ApiClient
    @observable claim: Claim
    @observable loading: boolean = false
    @observable activeAPICalls: number = 0

    @observable dirty: boolean = false

    constructor(parent: AppState, apiClient: ApiClient) {
        this.parent = parent
        this.claim = null
        this.apiClient = apiClient
    }

    @computed get APICallInProgress () {
        return this.activeAPICalls > 0
    }

    async load(id) {
        this.loading = true
        this.activeAPICalls++
        this.claim = new Claim((await this.apiClient.makeCall('GET', `/claims/${id}`)).data)
        this.loading = false
        this.activeAPICalls--

        observe(this.claim, this.onClaimChange)

        reaction(
            () => {
                if (!this.claim) {
                    return null
                }

                let passengers = ([this.claim.passengers.length] as any[]).concat(
                    this.claim.passengers.map(p => p.remove)
                ).concat(
                    this.claim.passengers.map(p => p.process_claim)
                ).concat(
                    this.claim.passengers.map(p => p.name)
                ).concat(
                    this.claim.passengers.map(p => p.email)
                ).concat(
                    this.claim.passengers.map(p => p.guardian)
                ).concat(
                    this.claim.documents.map(d => d.attach)
                )

                let tripSegments = ([this.claim.trip_segments.length] as any[])
                .concat(
                    this.claim.trip_segments.map(ts => [
                        ts.flight.airline.iata_code,
                        ts.flight.departure_airport.iata_code,
                        ts.flight.arrival_airport.iata_code,
                        ts.flight.flight_number,
                        ts.flight.departure_date
                    ])
                )
                .concat(
                    this.claim.trip_segments.map(ts => ts.flight_issue)
                ).concat(
                    this.claim.trip_segments.map(ts => ts.flight_number)
                ).concat(
                    this.claim.trip_segments.map(ts => ts.position)
                ).concat(
                    this.claim.trip_segments.map(ts => ts.id)
                )

                return [passengers, tripSegments]
            },
            this.onClaimChange
        )
    }

    async reload() {
        await this.load(this.claim.id)
        this.dirty = false
    }

    async save() {
        this.activeAPICalls++

        let result = await this.apiClient.makeCall(
            'PUT',
            `/claims/${this.claim.id}`,
            {data: this.claim}
        )
        this.activeAPICalls--
        this.parent.claimStatusCounts.reload()
        await this.reload()
    }

    onClaimChange(change) {
        this.dirty = true
    }

    addPassenger(pax: Passenger) {
        this.claim.passengers.push(pax)
    }

    initPassenger() {
        return new Passenger({
            name: 'Unnamed passenger',
            documents: [],
            process_claim: true
        })
    }

    addTripSegment(ts: TripSegment) {
        if (ts.position < 0) {
            this.claim.trip_segments.unshift(ts)
        } else {
            this.claim.trip_segments.push(ts)
        }
    }

    initTripSegment(isPrepend: boolean) {
        let departureDate = new Date()
        departureDate.setHours(12, 0, 0, 0)

        const tripSegments = this.claim.trip_segments
        let departure_airport = tripSegments[tripSegments.length-1].flight.arrival_airport
        let arrival_airport = null
        let position = tripSegments[tripSegments.length-1].position + 1

        if (isPrepend) {
            departure_airport = null
            arrival_airport = tripSegments[0].flight.departure_airport
            position = tripSegments[0].position - 1
        }

        let flight = new Flight({
            airline: null,
            departure_airport: departure_airport,
            arrival_airport: arrival_airport,
            departure_date: departureDate,
            flight_number: null,
        })

        return new TripSegment({
            flight: flight,
            flight_number: null,
            flight_issue: null,
            position: position,
            update_flight: false
        })
    }

    async addAttachments(uploads: string[]) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/attachments`,
            {data: {uploads: uploads}}
        )
        this.activeAPICalls--
        await this.reload()
    }

    async performAction(action, args: object = {}) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/perform_action`,
            {data: {action, args}}
        )
        this.activeAPICalls--
        await this.reload()
        this.parent.claimStatusCounts.reload()
    }

    async addComment(comment) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/comments`,
            {data: {comment}}
        )
        this.activeAPICalls--
        await this.reload()
    }

    async requestClaimDocumentSignature(passenger: Passenger) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/poa-requests`,
            {data: {passenger: passenger.id}}
        )
        this.activeAPICalls--
        await this.reload()
    }

    async cancelSignatureRequests(passenger: Passenger) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/passengers/${passenger.id}/cancel_signature_requests`,
        )
        this.activeAPICalls--
        await this.reload()
    }

    async discardSignedClaimDocuments(passenger: Passenger) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/passengers/${passenger.id}/discard_signed_documents`,
        )
        this.activeAPICalls--
        await this.reload()
    }

    async checkPoaStatus(sigId) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/signatures/${sigId}/check`,
        )
        this.activeAPICalls--
        await this.reload()
    }

    async setActivateAt(activateAt: Date | null, memo: string | null) {
        this.claim.activate_at = activateAt
        this.claim.activate_memo = memo
        this.save()
    }

    getValidGuardians(p: Passenger): Passenger[] {
        // Already a guardian themselves, cannot be attached to others
        if (p.wards && p.wards.length) return []

        return this.claim.passengers.filter((i) => !i.guardian && i.id != p.id)
    }

    async addClaimDocumentFromEmailAttachment(
        attachment: EmailAttachment, type: ClaimDocumentType, passenger: Passenger
    ) {
        this.activeAPICalls++
        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/claim-documents`,
            {
                data: {
                    claim: this.claim.id,
                    passenger: passenger ? passenger.id : null,
                    type: type,
                    from_email_attachment: attachment.id,
                }
            }
        )
        this.activeAPICalls--
        await this.reload()
    }

    async changeAllowEditingPaymentDetails(paymentDetailsId: number, allowEditing: boolean) {
        this.activeAPICalls++

        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/payment-details-editable`,
            {
                data: {
                    payment_details_id: paymentDetailsId,
                    new_allow_editing: !allowEditing,
                }
            }
        )

        this.activeAPICalls--
        await this.reload()
    }

    async requestBankDetails() {
        this.activeAPICalls++

        await this.apiClient.makeCall('POST', `/claims/${this.claim.id}/request-bank-details`)

        this.activeAPICalls--
        await this.reload()
    }

    async manualStatusChange(
        new_status: string, comment: string
    ) {
        this.activeAPICalls++

        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/status`,
            {
                data: {
                    claim: this.claim.id,
                    new_status: new_status,
                    comment: comment,
                }
            }
        )
        this.activeAPICalls--
        this.parent.claimStatusCounts.reload()
        await this.reload()
    }

    async manualCompensationChange(
        {
            amountPerPassenger, 
            comment, 
            isAutoAmount
        }: ManualCompensationChangeProps
    ) {
        this.activeAPICalls++

        await this.apiClient.makeCall(
            'POST',
            `/claims/${this.claim.id}/compensation`,
            {
                data: {
                    claim: this.claim.id,
                    new_passenger_claim_amount: amountPerPassenger,
                    comment: comment,
                    is_auto_amount: isAutoAmount,
                }
            }
        )
        this.activeAPICalls--
        await this.reload()
    }
}

import * as React from 'react'
import {observer} from 'mobx-react'
import {Col} from 'reactstrap'
import {Switch, Route} from 'react-router'

import {AirlineList} from './AirlineList'
import {AirlineDetails} from './AirlineDetails'

function renderAirlineList (props) {
    return <AirlineList key={props.match.params.set} airlineSet={props.match.params.set}/>
}

interface Props {
    match: {
        params: {
            id: string
            tab?: string
        },
        path: string
        url: string
    }
}

@observer
export class AirlineModule extends React.Component<Props> {
    render () {
        return <>
            <Col xs="12" className="content">
                <Switch>
                    <Route exact path="/airlines" component={AirlineList} />
                    <Route path="/airlines/:set([a-z\-_]+)" render={renderAirlineList} />
                    <Route path="/airlines/:id" component={AirlineDetails} />
                </Switch>
            </Col>
        </>
    }
}

import * as React from 'react'
import {Button} from 'reactstrap'
import { Spinner } from 'react-activity'

import 'react-activity/dist/react-activity.css';


const SPINNER_WRAPPER_STYLE = {
    display: 'inline-block',
    paddingRight: '8px',
    verticalAlign: 'top',
    marginBottom: '-5px'
}


interface ActionButtonProps {
    onInvoke: () => void
    disabled?: boolean
    executing?: boolean
    outline?: boolean
    size?: string
    label: string
    color?: string
}


export default class ActionButton extends React.Component<ActionButtonProps> {
    render () {
        return <Button
            outline={this.props.outline}
            size={this.props.size}
            color={this.props.color}
            className="mr-2"
            disabled={this.props.disabled || this.props.executing}
            onClick={this.props.onInvoke}
        >
            {
                this.props.executing ?
                    <div style={SPINNER_WRAPPER_STYLE}>
                        <Spinner color="#fff" size={12}/>
                    </div>
                    :
                    null
            }

            <span>{this.props.label}</span>

        </Button>
    }
}

import {Entity} from './Entity'
import {observable} from 'mobx'
import {Flight} from './Flight'


export class TripSegment extends Entity {
    @observable flight: Flight
    @observable flight_number: string
    @observable flight_issue: number
    position: number

    constructor(data) {
        super(data)

        this.flight = new Flight(data.flight)
    }
}

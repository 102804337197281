import * as React from 'react'
import * as moment from 'moment'
import {Claim} from '../../state/entities/Claim'
import {Card} from 'reactstrap'
import ClaimLink from '../common/ClaimLink'


export class ClaimStub extends React.Component<{claim:Claim}> {
    render () {
        let c = this.props.claim

        let flightDate = moment.utc(c.departure_time).format('DD.MM.YYYY')

        return <div className="mt-2">
            {c.airline.name} {" "}
            {c.departureAirport.iata_code} → {c.arrivalAirport.iata_code} {" "}
            {flightDate} €{c.total_claim_amount}
            {" "}
            <ClaimLink claim={c}/>
        </div>
    }
}
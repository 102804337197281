import * as React from 'react'

import './Field.scss'


interface FieldProps {
    label: string
}

export default class Field extends React.Component<FieldProps> {
    render () {
        return <div className='field'>
            <div className='label'>{this.props.label}</div>
            <div className='value'>
                {this.props.children ? this.props.children : <div className='na'>N/A</div>}
            </div>
        </div>
    }
}

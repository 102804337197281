import * as React from 'react'
import * as qs  from 'qs'
import autobind from 'autobind-decorator'
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap'
import {DatePicker} from 'react-widgets'
import {observer, inject} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from "../../state/ui"


@inject('appState')
@inject('uiState')
@observer
export class AirlineListFilters extends React.Component<{appState?: AppState, uiState?: UIState}> {
    @autobind
    onNameChange (value) {
        this.props.appState.airlineListStore.filters.$.name.onChange(value)
        this.props.appState.airlineListStore.load()
    }

    @autobind
    onIATACodeChange (value: string) {
        this.props.appState.airlineListStore.filters.$.iata_code.onChange(value)
        this.props.appState.airlineListStore.load()
    }

    @autobind
    onICAOCodeChange (value: string) {
        this.props.appState.airlineListStore.filters.$.icao_code.onChange(value)
        this.props.appState.airlineListStore.load()
    }

    render () {
        let form = this.props.appState.airlineListStore.filters.$

        return <div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Name: </Label>
                        <Input
                            type="text"
                            name="name"
                            value={form.name.value || ''}
                            onChange={(e) => this.onNameChange(e.target.value)}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">IATA code:</Label>
                        <Input
                            type="text"
                            name="iata-code"
                            value={form.iata_code.value || ''}
                            onChange={(e) => this.onIATACodeChange(e.target.value)}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">ICAO code:</Label>
                        <Input
                            type="text"
                            name="icao-code"
                            value={form.icao_code.value || ''}
                            onChange={(e) => this.onICAOCodeChange(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    }
}

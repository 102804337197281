import * as React from 'react'
import * as qs  from 'qs'
import autobind from 'autobind-decorator'
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap'
import {observer, inject} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from "../../state/ui"
import CountryAutocomplete from '../common/CountryAutocomplete'
import AirlineAutocomplete from '../common/AirlineAutocomplete'
import {DatePicker} from 'react-widgets'
import {Modal} from '../common/Modal'
import {ClaimAutocomplete} from "./ClaimAutocomplete";
import {Claim} from "../../state/entities/Claim";


@inject('appState')
@inject('uiState')
@observer
export class PaymentListFiltersModal extends React.Component<{appState?: AppState, uiState?: UIState}> {

    @autobind
    onCreatedAtBeforeChange(value: Date) {
        this.props.appState.paymentListStore.filters.$.created_at_before.onChange(value)
    }

    @autobind
    onCreatedAtAfterChange(value: Date) {
        this.props.appState.paymentListStore.filters.$.created_at_after.onChange(value)
    }

    @autobind
    onSettledAtBeforeChange(value: Date) {
        this.props.appState.paymentListStore.filters.$.settled_at_before.onChange(value)
    }

    @autobind
    onSettledAtAfterChange(value: Date) {
        this.props.appState.paymentListStore.filters.$.settled_at_after.onChange(value)
    }

    @autobind
    onClaimChange (value: Claim) {
        this.props.appState.paymentListStore.filters.$.claim.onChange(value)
    }

    @autobind
    async onApplyFilters () {
        this.setURLFromFilters()
        this.props.appState.paymentListStore.load()
        this.props.uiState.closeModal()

        return true
    }

    setURLFromFilters() {
        let store = this.props.appState.paymentListStore
        let urlSearchFilters = this.props.appState.paymentListStore.flattenFilters()

        let query = qs.stringify(urlSearchFilters)

        if (query.length) {
            this.props.uiState.routing.push(`/payments${'/' + store.paymentSet || ''}?${query}`)
        }
    }

    render () {
        let form = this.props.appState.paymentListStore.filters.$

        return <Modal title="Filter payments" actionLabel="Apply" onAction={this.onApplyFilters}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Registered from:</Label>
                        <DatePicker
                            name="created_at_after"
                            format="DD.MM.YYYY"
                            onChange={this.onCreatedAtAfterChange}
                            value={form.created_at_after.value}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Registered to:</Label>
                        <DatePicker
                            name="created_at_before"
                            format="DD.MM.YYYY"
                            onChange={this.onCreatedAtBeforeChange}
                            value={form.created_at_before.value}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Settled from:</Label>
                        <DatePicker
                            name="settled_at_after"
                            format="DD.MM.YYYY"
                            onChange={this.onSettledAtAfterChange}
                            value={form.settled_at_after.value}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Settled to:</Label>
                        <DatePicker
                            name="settled_at_before"
                            format="DD.MM.YYYY"
                            onChange={this.onSettledAtBeforeChange}
                            value={form.settled_at_before.value}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Claim number:</Label>
                        <ClaimAutocomplete
                            selected={form.claim.value}
                            onChange={this.onClaimChange}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </Modal>
    }
}

import * as React from 'react'
import autobind from 'autobind-decorator'
import {inject} from 'mobx-react'
import {NavItem} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {AppState} from '../state/app'
import LOGOUT_IMAGE from "../assets/img/logout.svg"

import './CurrentUser.scss'

import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons'


@inject('appState')
export default class CurrentUser extends React.Component<{appState?: AppState}> {
    render () {
        const userName = this.props.appState.auth.email.split('@')[0]
        return <div className="current-user">
            {userName}
            <a href="#" onClick={this.onLogoutClick} className="current-user-logout-link">
                <img src={LOGOUT_IMAGE} alt="Logout"/>
            </a>
        </div>
    }

    @autobind
    onLogoutClick (e) {
        e.preventDefault()

        this.props.appState.auth.logout()
    }
}

import * as React from 'react'
import {Claim} from "../../state/entities/Claim";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faFileSignature, faCheckSquare, faPaperPlane, faEnvelope, faMoneyBillAlt, faPiggyBank,
    faMoneyCheckAlt
} from '@fortawesome/free-solid-svg-icons'

function statusColor (status:boolean) {
    return status ? '#333' : '#ddd'
}

export default class StatusFlags extends React.Component<{claim: Claim}> {
    render () {
        let {
            is_signed, is_accepted, is_delivered, has_unread_messages, has_payment_details,
            is_airline_comp_received, is_client_comp_transferred
        } = this.props.claim

        return <span>
            <FontAwesomeIcon icon={faFileSignature} color={statusColor(is_signed)}/>
            {" "}
            <FontAwesomeIcon icon={faCheckSquare} color={statusColor(is_accepted)}/>
            {" "}
            <FontAwesomeIcon icon={faPaperPlane} color={statusColor(is_delivered)}/>
            {" "}
            <FontAwesomeIcon icon={faEnvelope} color={statusColor(has_unread_messages)}/>
            {" "}
            <FontAwesomeIcon icon={faMoneyCheckAlt} color={statusColor(has_payment_details)}/>
            {" "}
            <FontAwesomeIcon icon={faPiggyBank} color={statusColor(is_airline_comp_received)}/>
            {" "}
            <FontAwesomeIcon icon={faMoneyBillAlt} color={statusColor(is_client_comp_transferred)}/>
        </span>
    }

}
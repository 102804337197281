import * as React from 'react'
import {observer} from 'mobx-react'
import { FieldState } from 'formstate'
import { FormGroup, Label } from 'reactstrap'
import {TextInput} from './TextInput'
import {FieldFeedBack} from './FieldFeedBack'


@observer
export class TextInputGroup extends React.Component
<{ field: FieldState<string>, label: string, autoFocus?: boolean }>
{
    render () {
        return <FormGroup>
            <Label>{this.props.label}</Label>
            <TextInput field={this.props.field} autoFocus={this.props.autoFocus}/>
            <FieldFeedBack field={this.props.field}/>
        </FormGroup>
    }
}

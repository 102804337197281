import * as React from 'react'
import autobind from 'autobind-decorator'
import {observer} from 'mobx-react'
import {FieldState} from 'formstate'
import {Input} from 'reactstrap'


interface Props {
    options: {value: string|number, label: string}[]
    field: FieldState<string|number>
}


@observer
export class Select extends React.Component<Props> {
    render () {
        return <Input type="select"
            value={this.props.field.value || ''}
            onChange={this.onChange}
        >
            {this.renderOptions()}
        </Input>
    }

    renderOptions() {
        return this.props.options.map((v) =>
            <option key={v.value} value={v.value}>
                {v.label}
            </option>
        )
    }

    @autobind
    onChange(e) {
        this.props.field.onChange(e.target.value)
    }
}

import * as React from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Alert} from 'reactstrap'


export enum CloseClaimReason {
    INVALID_CLAIM = 'invalid_claim',
    DOCUMENTS_NOT_PROVIDED = 'documents_not_provided',
    EXTRAORDINARY_CIRCUMSTANCES = 'extraordinary_circumstances',
    NEB_DECISION_NEGATIVE = 'neb_decision_negative',
    LACK_OF_NEB_CAPACITY = 'lack_of_neb_capacity',
    LACK_OF_COURT_CAPACITY = 'lack_of_court_capacity',
    PARTIALLY_COMPLETED = 'partially_completed',
    DUPLICATE = 'duplicate_claim',
}

function getCloseClaimReasonLabel(reason: CloseClaimReason) {
    switch(reason) {
        case CloseClaimReason.INVALID_CLAIM:
            return `Invalid claim`

        case CloseClaimReason.DOCUMENTS_NOT_PROVIDED:
            return `Client has not provided documents`

        case CloseClaimReason.EXTRAORDINARY_CIRCUMSTANCES:
            return `Extraordinary circumstances proved`

        case CloseClaimReason.NEB_DECISION_NEGATIVE:
            return `NEB decision negative`

        case CloseClaimReason.LACK_OF_NEB_CAPACITY:
            return `No capacity to turn to NEB and court`

        case CloseClaimReason.LACK_OF_COURT_CAPACITY:
            return `No capacity to turn to court`

        case CloseClaimReason.PARTIALLY_COMPLETED:
            return `Partially completed (missing some info / documents)`

        case CloseClaimReason.DUPLICATE:
            return `Claim is duplicated`
    }
}

export const CLOSE_CLAIM_REASON_CHOICES : [CloseClaimReason, string][] = [
    [CloseClaimReason.INVALID_CLAIM, `Invalid claim`],
    [CloseClaimReason.DOCUMENTS_NOT_PROVIDED, `Client has not provided documents`],
    [CloseClaimReason.EXTRAORDINARY_CIRCUMSTANCES, `Extraordinary circumstances proved`],
    [CloseClaimReason.NEB_DECISION_NEGATIVE, `NEB decision negative`],
    [CloseClaimReason.LACK_OF_NEB_CAPACITY, `No capacity to turn to NEB and court`],
    [CloseClaimReason.LACK_OF_COURT_CAPACITY, `No capacity to turn to court`],
    [CloseClaimReason.PARTIALLY_COMPLETED, `Partially completed (missing some info / documents)`],
    [CloseClaimReason.DUPLICATE, `Claim is duplicated`],
]

interface Props {
    closeClaim: (reason, comment) => void
    cancel: Function
}

export default class CloseClaimActionModal extends React.Component<Props> {
    state = {
        reason: null,
        comment: '',
        error: null
    }

    onCloseClaimClick = () => {
        if (this.state.comment.trim().length == 0) {
            this.setState({error: 'Please enter the reason to close the claim'})
        } else {
            this.props.closeClaim(this.state.reason, this.state.comment)
        }
    }

    render () {
        return <Modal isOpen={true}>
            <ModalHeader toggle={ () => this.props.cancel() }>Close claim</ModalHeader>
            <ModalBody>

                Reason:
                <Input 
                    type="select"
                    className="mb-3"
                    onChange={(e) => this.setState({reason: e.target.value ? e.target.value : null})}
                >
                    <option key={0} value=''> Unspecified </option>
                    {CLOSE_CLAIM_REASON_CHOICES.map(
                        ([id]) => <option key={id} value={id}>{getCloseClaimReasonLabel(id)}</option>
                    )}
                </Input>

                Comment:
                <Input type="textarea" onChange={(e) => this.setState({comment: e.target.value})}/>
                { this.state.error ? <Alert color="danger">{this.state.error}</Alert> : null}
                
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.onCloseClaimClick}>Close claim</Button>{' '}
                <Button color="secondary" onClick={() => this.props.cancel() }>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

import * as React from 'react'
import autobind from 'autobind-decorator'
import { Col, Row, FormGroup, Input } from 'reactstrap'
import { observer, inject } from 'mobx-react'
import { AppState } from '../../state/app'
import { UIState } from "../../state/ui"
import { Modal } from '../common/Modal'
import {
    ClaimColumnNames,
    ClaimColumnValues,
    DEFAULT_COLUMNS,
    SET_CONFIG,
} from '../claim-list/ClaimList'
import './ClaimListDownloadModal.scss'

function getColumns(claimSet) {
    if (SET_CONFIG[claimSet]) {
        return SET_CONFIG[claimSet].columns
    }
    return DEFAULT_COLUMNS
}

@inject('appState')
@inject('uiState')
@observer
export class ClaimListDownloadModal extends React.Component
<{appState?: AppState, uiState?: UIState}>
{

    @autobind
    onCheckboxChange (event: React.ChangeEvent<HTMLInputElement>) {
        const store = this.props.appState.claimListStore
        const value = event.target.value
        const selectedColumns = store.exportClaimListColumns
        
        if (selectedColumns.includes(value)) {
            store.setExportClaimListColumns(selectedColumns.filter((column) => column !== value))
        } else {
            selectedColumns.push(value)
            store.setExportClaimListColumns(selectedColumns)
        }
    }

    @autobind
    async onExportClick () {
        await this.props.appState.claimListStore.exportClaims()
        return true
    }

    render () {
        let store = this.props.appState.claimListStore
        let selectedColumns = store.exportClaimListColumns
        let columns = getColumns(store.claimSet)

        return (
            <Modal
                title="Export claims"
                actionLabel="Export"
                onAction={this.onExportClick}
            >
                <Row className="checkbox-list">
                    <Col>
                        <FormGroup>
                            {columns.map((i) => (
                                <div key={i.id}>
                                    <Input
                                        type="checkbox"
                                        value={ClaimColumnValues[i]}
                                        checked={selectedColumns.includes(ClaimColumnValues[i])}
                                        onChange={this.onCheckboxChange}
                                    />
                                    <span>{ClaimColumnNames[i]}</span>
                                </div>
                            ))}
                        </FormGroup>
                    </Col>
                </Row>
            </Modal>
        )
    }
}

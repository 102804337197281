import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'

import {Currency, Payment, PaymentStatus} from '../../state/entities/Payment'
import {Party} from '../../state/entities/Common'
import {AppState} from '../../state/app'
import {numeric, positive, required, validateIBAN} from '../../util/validation'
import {
    Alert,
    Button,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap'
import * as Moment from "moment"
import {DatePicker} from 'react-widgets'
import {FieldState, FormStateLazy} from 'formstate'
import 'react-widgets/dist/css/react-widgets.css'
import {BankAccountSelect} from './BankAccountSelect'
import {ClaimAutocomplete} from './ClaimAutocomplete'
import {Claim} from '../../state/entities/Claim'
import {ClaimStub} from './ClaimStub'
import {ValidationError} from '../../api/client'
import {UIState} from '../../state/ui'

Moment.locale('en')
Moment.updateLocale('en', {week : {dow : 1, doy : 4}});  // Start week on Monday
const momentLocalizer = require('react-widgets-moment')
momentLocalizer()



class PaymentFormState {
    fromParty = Party.AIRLINE

    settled_at = new FieldState<Date|null>(new Date()).validators(
        required("Enter the payment settle date")
    )
    status = new FieldState<PaymentStatus>(PaymentStatus.PENDING).validators(
        required("Select payment status")
    )
    from_account_number = new FieldState<string|null>(null).validators(
        required("Please enter the source account number"),
        (value) => this.from_account_is_iban.value ? validateIBAN(value) : null
    )
    from_account_is_iban = new FieldState<boolean>(true)
    from_account_bic = new FieldState<string|null>(null).validators(
        (value) => {
            return this.from_account_is_iban.value ?
                null
                :
                required("Please enter the bank code")(value)
        }
    )
    to_account_number = new FieldState<string|null>(null).validators(
        required("Please enter the destination account number"),
        (value) => this.to_account_is_iban.value ? validateIBAN(value) : null
    )
    to_account_is_iban = new FieldState<boolean>(true)
    to_account_bic = new FieldState<string|null>(null).validators(
        (value) => {
            return this.to_account_is_iban.value ?
                null
                :
                required("Please enter the bank code")(value)
        }
    )
    to_name = new FieldState<string|null>(null).validators(
        required("Please enter the beneficiary name")
    )
    to_address = new FieldState<string|null>(null).validators(
        required("Please enter the beneficiary address")
    )
    details = new FieldState<string|null>(null).validators(
        required()
    )
    amount = new FieldState<number|null>(null).validators(
        required("Please enter the amount"),
        numeric(),
        positive(),
    )
    currency = new FieldState<Currency>(Currency.EUR)
    claim = new FieldState<Claim>(null)

    form = new FormStateLazy(this.getActiveFields)

    constructor (fromParty: Party, payment?: Payment) {
        this.fromParty = fromParty

        if (payment) {
            this.settled_at.reset(payment.settled_at)
            this.from_account_number.reset(payment.from_account_number)
            this.from_account_is_iban.reset(payment.from_account_is_iban)
            this.from_account_bic.reset(payment.from_account_bic)
            this.to_account_number.reset(payment.to_account_number)
            this.to_account_is_iban.reset(payment.to_account_is_iban)
            this.to_account_bic.reset(payment.to_account_bic)
            this.to_name.reset(payment.to_name)
            this.to_address.reset(payment.to_address)
            this.details.reset(payment.details)
            this.amount.reset(payment.amount)
            this.currency.reset(payment.currency)
            this.claim.reset(payment.claim)
        }
    }

    @autobind
    getActiveFields () {
        let fields: FieldState<any>[] = [
            this.status,
            this.from_account_number,
            this.to_account_number,
            this.amount,
            this.currency,
            this.claim,
        ]

        if (this.status.value == PaymentStatus.SETTLED) {
            fields.push(this.settled_at)
            fields.push(this.details)
        }

        if (this.fromParty == Party.AIRLINE) {
            // Incoming payment
            fields.push(this.from_account_is_iban)
            fields.push(this.from_account_bic)
        } else {
            // Outgoing payment
            fields.push(this.to_account_is_iban)
            fields.push(this.to_account_bic)

            if (this.status.value == PaymentStatus.SETTLED) {
                fields.push(this.to_name)
            }
        }

        return fields
    }

    getValues () {
        let fields

        if (this.fromParty == Party.AIRLINE) {
            fields = {
                settled_at: this.settled_at,
                from_account_number: this.from_account_number,
                from_account_is_iban: this.from_account_is_iban,
                from_account_bic: this.from_account_bic,
                to_account_number: this.to_account_number,
                amount: this.amount,
                currency: this.currency,
                details: this.details,
                claim: this.claim,
            }
        }
        else {
            fields = {
                status: this.status,
                settled_at: this.settled_at,
                from_account_number: this.from_account_number,
                to_account_number: this.to_account_number,
                to_account_is_iban: this.to_account_is_iban,
                to_account_bic: this.to_account_bic,
                to_name: this.to_name,
                to_address: this.to_address,
                amount: this.amount,
                currency: this.currency,
                details: this.details,
                claim: this.claim,
            }
        }

        let result = {}
        for (let k of Object.keys(fields)) {
            let value = fields[k].value
            if (typeof value == 'string') {
                value = value.trim()
            }
            result[k] = value
        }

        return result
    }
}


interface Props {
    appState?: AppState
    uiState?: UIState
    fromParty: Party
    initial?: Payment
    isClaimPage?: boolean
    close: () => void
}

@inject('appState', 'uiState')
@observer
export class RegisterPaymentModal extends React.Component<Props> {
    formState: PaymentFormState

    state = {
    }

    constructor (props) {
        super(props)

        this.formState = new PaymentFormState(this.props.fromParty, this.props.initial)
    }

    @autobind
    onSettledAtChange (date) {
        let m = Moment(date)

        // Shift to UTC noon of the selected date
        m.hour(12)
        m.minute(0)
        m.second(0)
        m.millisecond(0)

        m.add(m.utcOffset(), 'minutes')

        this.formState.settled_at.onChange(m.toDate())
    }

    getTitle () {
        let action = 'Register'
        if (this.props.initial) {
            action = 'Edit'
        }

        let direction = 'incoming'
        if (this.props.fromParty == Party.SERVICE) {
            direction = 'outgoing'
        }

        return `${action} ${direction} payment`
    }

    getSaveButtonLabel () {
        if (this.props.initial) {
            return 'Update payment'
        }

        return 'Register payment'
    }

    @autobind
    async onSubmit (e) {
        e && e.preventDefault()

        let res = await this.formState.form.validate()

        if (res.hasError === false) {
            let payment = new Payment(this.formState.getValues())

            if (this.props.initial && this.props.initial.id) {
                payment.id = this.props.initial.id
            }

            if (this.props.fromParty == Party.AIRLINE) {
                payment.from_party = Party.AIRLINE
                payment.to_party = Party.SERVICE
                payment.status = PaymentStatus.SETTLED

            } else {
                payment.from_party = Party.SERVICE
                payment.to_party = Party.CLIENT
            }

            if (payment.claim) {
                payment.claim = payment.claim.id as any
            }

            try {
                await this.props.appState.paymentListStore.save(payment)

                this.props.appState.paymentListStore.load()
                this.props.appState.paymentStatusCounts.reload()

                if (this.props.isClaimPage) {
                    this.props.appState.claimDetailsStore.reload()
                }
                

                this.props.uiState.showNotification("Payment registered")

                this.props.close()

            } catch (exc) {
                if (exc instanceof ValidationError) {
                    for (let k in exc.details) {
                        if (this.formState[k] instanceof FieldState) {
                            this.formState[k].setError(exc.details[k])
                        }
                    }
                } else {
                    throw exc
                }
            }
        }
    }

    render () {
        let data = this.formState
        let isIncoming = this.props.fromParty == Party.AIRLINE

        return <Modal isOpen={true}>
            <ModalHeader toggle={this.props.close}>{this.getTitle()}</ModalHeader>

            <ModalBody>
                <Form onSubmit={this.onSubmit}>
                    <FormGroup>
                        <Label>Date:</Label>

                        <DatePicker
                            defaultValue={data.settled_at.value}
                            name="settled_at"
                            format="DD.MM.YYYY"
                            onChange={this.onSettledAtChange}

                        />
                        <FieldError field={data.settled_at}/>
                    </FormGroup>

                    { isIncoming ?
                        <BankAccountInput
                            label="From account:"
                            accountField={data.from_account_number}
                            ibanStatusField={data.from_account_is_iban}
                            bicField={data.from_account_bic}
                        />
                        :
                        <FormGroup>
                            <Label>From account:</Label>
                            <BankAccountSelect
                                value={data.from_account_number.value || ''}
                                onChange={(ba) => data.from_account_number.onChange(ba.account_number)}
                            />
                            <FieldError field={data.from_account_number}/>
                        </FormGroup>
                    }

                    { isIncoming ?
                        <FormGroup>
                            <Label>To account:</Label>
                            <BankAccountSelect
                                value={data.to_account_number.value || ''}
                                onChange={(ba) => data.to_account_number.onChange(ba.account_number)}
                            />
                            <FieldError field={data.to_account_number}/>
                        </FormGroup>
                        :
                        <BankAccountInput
                            label="To account:"
                            accountField={data.to_account_number}
                            ibanStatusField={data.to_account_is_iban}
                            bicField={data.to_account_bic}
                        />
                    }

                    { !isIncoming ?
                        <FormGroup>
                            <Label>Beneficiary name:</Label>
                            <Input
                                value={data.to_name.value || ''}
                                onChange={(e) => data.to_name.onChange(e.target.value)}
                            />
                            <FieldError field={data.to_name}/>
                        </FormGroup>
                        :
                        null
                    }

                    { !isIncoming ?
                        <FormGroup>
                            <Label>Beneficiary address:</Label>
                            <Input
                                value={data.to_address.value || ''}
                                onChange={(e) => data.to_address.onChange(e.target.value)}
                            />
                            <FieldError field={data.to_address}/>
                        </FormGroup>
                        :
                        null
                    }

                    <Row form>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>Amount:</Label>
                                <Input
                                    value={data.amount.value || ''}
                                    onChange={(e) => data.amount.onChange(Number(e.target.value))}
                                />
                                <FieldError field={data.amount}/>
                            </FormGroup>
                        </Col>

                        <Col xs={2}>
                            <FormGroup>
                                <Label>Currency:</Label>
                                <Input
                                    type="select"
                                    value={data.currency.value}
                                    onChange={(e) => data.currency.onChange(e.target.value as Currency)}
                                >
                                    { Object.keys(Currency).map((i) => <option key={i}>{i}</option>)}
                                </Input>
                                <FieldError field={data.currency}/>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label>Payment details:</Label>
                        <Input
                            type="textarea"
                            value={data.details.value || ''}
                            onChange={(e) => data.details.onChange(e.target.value)}
                        />
                        <FieldError field={data.details}/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Claim:</Label>
                        <ClaimAutocomplete onChange={data.claim.onChange}/>
                        {data.claim.value ? <ClaimStub claim={data.claim.value}/> : null}
                    </FormGroup>

                    {!isIncoming ?
                        <FormGroup tag="fieldset">
                            <Label>Status:</Label>

                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="payment_status"
                                        value={PaymentStatus.PENDING}
                                        checked={data.status.value == PaymentStatus.PENDING}
                                        onChange={() => data.status.onChange(PaymentStatus.PENDING)}
                                    />
                                    {' '} Pending
                                </Label>
                            </FormGroup>

                            <FormGroup check>
                                <Label check disabled={!data.claim.value}>
                                    <Input
                                        type="radio"
                                        name="payment_status"
                                        disabled={!data.claim.value}
                                        value={PaymentStatus.SETTLED}
                                        checked={data.status.value == PaymentStatus.SETTLED}
                                        onChange={() => data.status.onChange(PaymentStatus.SETTLED)}
                                    />
                                    {' '} Settled {!data.claim.value ?
                                    <span>(assign claim to enable)</span> : null}
                                </Label>
                            </FormGroup>

                        </FormGroup>
                        :
                        null
                    }

                </Form>

            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={this.onSubmit}>{this.getSaveButtonLabel()}</Button>{' '}
                <Button color="secondary" onClick={this.props.close}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}


@observer
class FieldError extends React.Component<{field:FieldState<any>}> {
    render () {
        let field = this.props.field

        if (!field.hasError) {
            return null
        }

        return <Alert color="danger">{field.error}</Alert>
    }
}


interface BankAccountInputProps {
    label: string
    accountField: FieldState<string>
    bicField: FieldState<string>
    ibanStatusField: FieldState<boolean>
}


@observer
class BankAccountInput extends React.Component<BankAccountInputProps>
{
    render () {
        let fieldCols = [11, 0, 1]
        let isIban = this.props.ibanStatusField.value
        if (!isIban) {
            fieldCols = [7, 4, 1]
        }

        return <Row form>
            <Col xs={fieldCols[0]}>
                <FormGroup>
                    <Label>{this.props.label}</Label>
                    <Input
                        value={this.props.accountField.value || ''}
                        onChange={(e) => this.props.accountField.onChange(e.target.value)}
                    />
                    <FieldError field={this.props.accountField}/>
                </FormGroup>
            </Col>

            {!isIban ?
                <Col xs={fieldCols[1]}>
                    <FormGroup>
                        <Label>BIC / sort code</Label>
                        <Input
                            value={this.props.bicField.value || ''}
                            onChange={(e) => this.props.bicField.onChange(e.target.value)}
                        />
                        <FieldError field={this.props.bicField}/>
                    </FormGroup>
                </Col>
                :
                null
            }

            <Col xs={fieldCols[2]}>
                <FormGroup>
                    <Label>IBAN</Label>
                    <CustomInput
                        id="account-is-iban"
                        type="switch"
                        checked={this.props.ibanStatusField.value}
                        onChange={this.onIBANStatusChange}
                    />
                </FormGroup>
            </Col>

        </Row>
    }

    @autobind
    onIBANStatusChange(e) {
        this.props.ibanStatusField.onChange(e.target.checked)
        this.props.accountField.validate()
    }
}

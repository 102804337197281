import * as qs from 'qs'
import {ApiClient} from '../api/client'
import {observable} from 'mobx'
import autobind from 'autobind-decorator'
import {Airline} from './entities/Airline'
import {AppState} from './app'
import {FormState, FieldState, ValidatableMapOrArray} from 'formstate'


interface PaginatedData {
    results: Airline[]
    count: number
}

type AirlineListFilterFormProps = {
    name: FieldState<string>
    iata_code: FieldState<string>
    icao_code: FieldState<string>
}

export class AirlineListFilterState extends FormState<AirlineListFilterFormProps> {
    constructor () {
        super({
            name: new FieldState<string>(null),
            iata_code: new FieldState<string>(null),
            icao_code: new FieldState<string>(null),
        })
    }
}

export class AirlineListStore {
    apiClient: ApiClient
    @observable airlineSet: string | null
    @observable airlines: Airline[]
    @observable loading = false
    parent: AppState
    @observable page = 0
    @observable pageSize = 100
    @observable count = 0
    @observable sortField = null
    @observable sortAscending = true    

    @observable filters: AirlineListFilterState = new AirlineListFilterState()

    constructor(parent: AppState, apiClient: ApiClient) {
        this.parent = parent
        this.airlines = []
        this.apiClient = apiClient
    }

    resetFilters () {
        this.filters = new AirlineListFilterState()
    }

    get isFiltered () {
        for (let k in this.filters.$) {
            if (this.filters.$[k].value != null) {
                return true
            }
        }

        return false
    }

    flattenFilters () {
        let result = {}
        for (let k in this.filters.$) {
            let val = this.filters.$[k].value

            if (val == null) {
                continue
            }
            
            result[k] = val
        }
        return result
    }

    async load() {
        let sorting = ''
        if (this.sortField) {
            let direction = this.sortAscending ? '' : '-'
            sorting = `&order=${direction}${this.sortField}`
        }

        let query = qs.stringify(this.flattenFilters())
        if (query.length) {
            query = `&${query}`
        }

        this.loading = true
        const response = await this.apiClient.makeCall(
            'GET',
            `/airlines?set=${this.airlineSet || ''}&limit=100&offset=${this.page * this.pageSize}${sorting}${query}`
        )

        let {results, count} = response.data as PaginatedData
        this.airlines = results.map(a => new Airline(a))
        this.count = count
        this.loading = false
    }

    async reload() {
        this.airlines = []
        this.page = 0
        this.load()
    }

    @autobind
    changePage(page) {
        this.page = page-1
        this.load()
    }

    setSort(field: string | null, ascending: boolean = true) {
        this.sortField = field
        this.sortAscending = ascending
        this.load()
    }
}

import * as React from 'react'
import * as qs  from 'qs'
import autobind from 'autobind-decorator'
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap'
import AirlineAutocomplete from '../common/AirlineAutocomplete'
import AirportAutocomplete from '../common/AirportAutocomplete'
import {DatePicker} from 'react-widgets'
import {observer, inject} from 'mobx-react'
import {AppState} from '../../state/app'
import {UIState} from "../../state/ui"


@inject('appState')
@inject('uiState')
@observer
export class FlightListFilters extends React.Component<{appState?: AppState, uiState?: UIState}> {
    @autobind
    onAirlineChange (selection: object[]) {
        let selValue = null
        if (selection.length) {
            selValue = selection[0]
        }
        this.props.appState.flightListStore.filters.$.airline.onChange(selValue)
        this.setURLFromFilters()
        this.props.appState.flightListStore.load()
    }

    @autobind
    onDepartureAirportChange (selection: object[]) {
        let selValue = null
        if (selection.length) {
            selValue = selection[0]
        }
        this.props.appState.flightListStore.filters.$.departure_airport.onChange(selValue)
        this.setURLFromFilters()
        this.props.appState.flightListStore.load()
    }

    @autobind
    onArrivalAirportChange (selection: object[]) {
        let selValue = null
        if (selection.length) {
            selValue = selection[0]
        }
        this.props.appState.flightListStore.filters.$.arrival_airport.onChange(selValue)
        this.setURLFromFilters()
        this.props.appState.flightListStore.load()
    }

    @autobind
    onDateFromChange (value: Date) {
        this.props.appState.flightListStore.filters.$.departure_date_after.onChange(value)
        this.setURLFromFilters()
        this.props.appState.flightListStore.load()
    }

    @autobind
    onDateToChange (value: Date) {
        this.props.appState.flightListStore.filters.$.departure_date_before.onChange(value)
        this.setURLFromFilters()
        this.props.appState.flightListStore.load()
    }

    @autobind
    onClaimActivityChange () {
        let isActiveOnly = this.props.appState.flightListStore.filters.$.is_active_only.value
        this.props.appState.flightListStore.filters.$.is_active_only.onChange(!isActiveOnly)
        this.setURLFromFilters()
        this.props.appState.flightListStore.load()
    }

    setURLFromFilters() {
        let filters = this.props.appState.flightListStore.filters
        let filterNames = filters.$
        let urlSearchFilters = this.props.appState.flightListStore.flattenFilters()

        for (let filterName in filterNames) {
            if (filters.$[filterName].value) {
                if (filters.$[filterName].value.iata_code) {
                    urlSearchFilters[filterName] = filters.$[filterName].value.iata_code
                }
            }
        }

        let query = qs.stringify(urlSearchFilters)

        if (query.length) {
            this.props.uiState.routing.push(`/flights?${query}`)
        }
    }

    render () {
        let form = this.props.appState.flightListStore.filters.$

        return <div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="mb-0">Airline: </Label>
                        <AirlineAutocomplete
                            key={form.airline.value ? form.airline.value.id : 'empty'}
                            selected={form.airline.value}
                            onChange={this.onAirlineChange}
                            defaultValue={null}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">From:</Label>
                        <AirportAutocomplete
                            key={form.departure_airport.value ? form.departure_airport.value.id : 'empty'}
                            selected={form.departure_airport.value}
                            onChange={this.onDepartureAirportChange}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">To:</Label>
                        <AirportAutocomplete
                            key={form.arrival_airport.value ? form.arrival_airport.value.id : 'empty'}
                            selected={form.arrival_airport.value}
                            onChange={this.onArrivalAirportChange}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">Date from:</Label>
                        <DatePicker
                            name="settled_at"
                            format="DD.MM.YYYY"
                            onChange={this.onDateFromChange}
                            value={form.departure_date_after.value}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label className="mb-0">Date to:</Label>
                        <DatePicker
                            name="settled_at"
                            format="DD.MM.YYYY"
                            onChange={this.onDateToChange}
                            value={form.departure_date_before.value}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup className="mb-3" check>
                        <Label check>
                            <Input 
                                type="checkbox"
                                name="is_active_only"
                                checked={form.is_active_only.value}
                                onChange={this.onClaimActivityChange}
                            />{' '}
                            Active only
                        </Label>
                    </FormGroup>
                </Col>
            </Row>

        </div>
    }
}

import * as React from 'react'
import {Airline} from "../../state/entities/Airline";
import {Input} from 'reactstrap'


export default class ContactEmailField extends React.Component<{airline:Airline}> {
    state = {
        editing: false,
        value: ''
    }
    render () {
        const a = this.props.airline

        return <span>
            {
                this.state.editing ?
                    <Input
                        value={this.state.value}
                        onBlur={this.onAbortEdit}
                        onChange={this.onChange}
                        onKeyUp={this.onInputKeyUp}
                    />
                    :
                    <span>{a.contact_email ? a.contact_email : "-"}</span>
            }
            {" "}
            <a href="#" onClick={this.onEditClick}>Edit</a>
        </span>
    }

    onEditClick = (e) => {
        e.preventDefault()
        this.setState({editing: true, value: this.props.airline.contact_email})
    }

    onFinishEdit = () => {
        this.props.airline.contact_email = this.state.value
        this.setState({editing: false, value: null})
    }

    onAbortEdit = () => {
        this.setState({editing: false, value: null})
    }

    onChange = (e) => {
        this.setState({value: e.target.value})
    }

    onInputKeyUp = (e) => {
        if (e.keyCode == 27) {
            e.preventDefault()
            this.onAbortEdit()
        }
        else if (e.keyCode == 13) {
            e.preventDefault()
            this.onFinishEdit()
        }
    }
}
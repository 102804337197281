import * as React from 'react'
import {inject, observer} from 'mobx-react'
import autobind from 'autobind-decorator'

import {AppState} from '../../state/app'
import {FormState, FieldState} from 'formstate'
import {required, validateEmail, validatePhoneNumber} from '../../util/validation'
import {Passenger} from '../../state/entities/Passenger'

import {Modal} from '../common/Modal'
import {Form, FormGroup, Label} from 'reactstrap'
import {TextInputGroup} from '../common/TextInputGroup'
import {CheckboxInputGroup} from '../common/CheckboxInputGroup'
import {EntitySelect} from '../common/form-input/EntitySelect'


function validateFullName (value) {
    if (value.split(' ').length < 2) {
        return "Please enter the full passenger name (including family name)"
    }
    return null
}


type PassengerFields = {
    name: FieldState<string>
    email: FieldState<string>
    phone_number: FieldState<string>
    process_claim: FieldState<boolean>
    guardian: FieldState<number>
}

class PassengerFormState extends FormState<PassengerFields> {
    constructor (passenger: Passenger, requireContactDetails = true) {
        super(
            {
                name: new FieldState<string>(passenger.name).validators(
                    required(),
                    validateFullName
                ),
                email: new FieldState<string>(passenger.email).validators(
                    (value) => this.validateEmail(value)
                ),
                phone_number: new FieldState<string>(passenger.phone_number).validators(
                    validatePhoneNumber
                ),
                process_claim: new FieldState<boolean>(passenger.process_claim),
                guardian: new FieldState<number>(passenger.guardian)
            }
        )
    }

    validateEmail (value) {
        let err: string = null

        if (!this.$.guardian.value) {
            err = required()(value)
        }

        err = err || validateEmail(value)

        return err
    }
}


@inject('appState')
@observer
export class EditPassengerModal extends React.Component
<{passenger: Passenger, appState?: AppState, create?: boolean}>
{
    formState: PassengerFormState

    constructor (props) {
        super(props)

        let editingSubmitter = (
            this.props.passenger.id == this.props.appState.claimDetailsStore.claim.submitter.id
        )

        this.formState = new PassengerFormState(
            this.props.passenger,
            (!this.props.create || editingSubmitter) && this.props.passenger.guardian == null
        )
    }

    @autobind
    async onUpdateClick () {
        let result = await this.formState.validate()

        if (result.hasError) {
            return false
        }

        let pax = this.props.passenger
        let cdStore = this.props.appState.claimDetailsStore

        for (let k of Object.keys(this.formState.$)) {
            pax[k] = this.formState.$[k].value
        }

        if (this.props.create) {
            cdStore.addPassenger(pax)
        }

        cdStore.claim.connectGuardiansWards()

        return true
    }

    render () {
        let fields = this.formState.$
        let guardians = this.props.appState.claimDetailsStore.getValidGuardians(this.props.passenger)

        let title = this.props.create ? "Add passenger" : "Edit passenger"

        return <Modal title={title} actionLabel="Save" onAction={this.onUpdateClick}>
            <Form onSubmit={(e) => e.preventDefault()}>
                <TextInputGroup field={fields.name} label="Full name" autoFocus={true}/>
                <TextInputGroup field={fields.email} label="Email"/>
                <TextInputGroup field={fields.phone_number} label="Phone number"/>
                {guardians.length ?
                    <FormGroup>
                        <Label>Guardian</Label>
                        <EntitySelect
                            options={guardians}
                            idField={fields.guardian}
                            getLabel={g => g.name}
                            emptyLabel="No guardian"
                        />
                    </FormGroup> : null
                }
                <CheckboxInputGroup field={fields.process_claim} label="Include in claim"/>
            </Form>
        </Modal>
    }
}

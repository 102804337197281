import {action, observable, when} from 'mobx'

import * as qs from 'qs'
import * as moment from 'moment'

import {AppState} from './app'
import {ApiClient} from '../api/client'
import {Payment} from './entities/Payment'
import autobind from 'autobind-decorator'
import {FormState, FieldState} from 'formstate'
import {Claim} from './entities/Claim'

interface PaginatedData {
    results: Payment[]
    count: number
}

export enum PaymentSet {
    RECEIVED = 'received',
    PENDING = 'pending',
    SENT = 'sent'
}

type FilterFormProps = {
    claim: FieldState<Claim>
    created_at_after: FieldState<Date>,
    created_at_before: FieldState<Date>,
    settled_at_after: FieldState<Date>,
    settled_at_before: FieldState<Date>,
}

export class FilterState extends FormState<FilterFormProps> {
    constructor () {
        super({
            claim: new FieldState<Claim>(null),
            created_at_after: new FieldState<Date>(null),
            created_at_before: new FieldState<Date>(null),
            settled_at_after: new FieldState<Date>(null),
            settled_at_before: new FieldState<Date>(null),
        })
    }
}

export class PaymentListStore {
    parent: AppState
    apiClient: ApiClient

    @observable paymentSet: PaymentSet|null
    @observable payments: Payment[] = []
    @observable loading: boolean = false
    @observable page = 0
    @observable pageSize = 20
    @observable count = 0
    @observable sortField = null
    @observable sortAscending = true

    @observable filters: FilterState = new FilterState()

    constructor (parent, apiClient) {
        this.parent = parent
        this.apiClient = apiClient
    }

    resetFilters () {
        this.filters = new FilterState()
    }

    get isFiltered () {
        for (let k in this.filters.$) {
            if (this.filters.$[k].value != null) {
                return true
            }
        }

        return false
    }

    flattenFilters () {
        let result = {}
        for (let k in this.filters.$) {
            let val = this.filters.$[k].value

            if (val == null) {
                continue
            }

            if (val.constructor == Date) {
                val = moment(val).format('YYYY-MM-DD')
            } else if (typeof val === 'object' && val.id != null) {
                val = val.id
            }
            result[k] = val
        }

        return result
    }

    @action
    async load () {
        let sorting = ''
        if (this.sortField) {
            let direction = this.sortAscending ? '' : '-'
            sorting = `&order=${direction}${this.sortField}`
        }

        let query = qs.stringify(this.flattenFilters())
        if (query.length) {
            query = `&${query}`
        }

        this.loading = true
        const response = await this.apiClient.makeCall(
            'GET',
            `/payments?set=${this.paymentSet || ''}&limit=20&offset=${this.page * this.pageSize}${sorting}${query}`
        )

        let {results, count} = response.data as PaginatedData
        this.payments = results.map(c => new Payment(c))
        this.count = count
        this.loading = false
    }

    @action
    async save (payment: Payment) {
        let method = 'POST'
        let url = '/payments'

        if (payment.id) {
            method = 'PUT'
            url = `/payments/${payment.id}`
        }

        this.loading = true

        let response = await this.apiClient.makeCall(method, url, {data: payment})
        if (response.status == 201) {
            payment.id = (response.data as Payment).id
        }

        this.loading = false

        return payment
    }

    @autobind
    changePage(page) {
        this.page = page-1
        this.load()
    }

    setSort(field: string | null, ascending: boolean = true) {
        this.sortField = field
        this.sortAscending = ascending
        this.load()
    }
}


@autobind
export class PaymentStatusCountStore {
    apiClient: ApiClient

    @observable received: number = 0
    @observable pending: number = 0
    @observable sent: number = 0

    constructor (apiClient: ApiClient) {
        this.apiClient = apiClient
    }

    async reload() {
        let data = (
            await this.apiClient.makeCall('GET', `/payments/status-counts`)
        ).data

        this.received = data['received']
        this.pending = data['pending']
        this.sent = data['sent']
    }
}

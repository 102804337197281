import * as React from 'react'
import {Nav, NavItem, Badge} from 'reactstrap'
import {NavLink} from '../NavLink'

import './ModuleSidebar.scss'

export class ModuleSidebar extends React.Component {

    render () {
        return <Nav vertical className='sidebar-navigation'>

            <NavItem>
                {this.props.children}
            </NavItem>
        </Nav>
    }
}
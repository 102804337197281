import * as React from 'react'
import * as moment from 'moment'

import {Action, ActionType} from "../../state/app";

import GenericTaskDetails from './GenericTaskDetails'
import PrepareDemandLetterDetails from './PrepareDemandLetterDetails'
import FlightDataCheckDetails from './FlightDataCheckDetails'
import {ProcessAirlineEmailDetails} from "./ProcessAirlineEmailTaskDetails"
import {SendEmailToAirlineDetails} from "./SendEmailToAirlineDetails"

import './TaskDetails.scss'
import DeliverClaimNoticeDetails from "./SendClaimNoticeDetails";
import ReviewNewClaimDetails from './ReviewNewClaimDetails'
import CloseClaimDetails from './CloseClaimDetails'
import CommentDetails from './CommentDetails'


const TASK_DETAIL_COMPONENTS = {
    [ActionType.REVIEW_NEW_CASE]: ReviewNewClaimDetails,
    [ActionType.PREPARE_DEMAND_LETTER]: PrepareDemandLetterDetails,
    [ActionType.PROCESS_AIRLINE_EMAIL]: ProcessAirlineEmailDetails,
    [ActionType.CHECK_FLIGHT_DATA]: FlightDataCheckDetails,
    [ActionType.SEND_EMAIL_TO_AIRLINE]: SendEmailToAirlineDetails,
    [ActionType.SEND_DEMAND_LETTER]: DeliverClaimNoticeDetails,
    [ActionType.CLOSE_CLAIM]: CloseClaimDetails,
    [ActionType.COMMENT]: CommentDetails,
}


function getComponentClass(taskType: ActionType) {
    if (taskType in TASK_DETAIL_COMPONENTS) {
        return TASK_DETAIL_COMPONENTS[taskType]
    }

    return GenericTaskDetails
}


export class TaskDetails extends React.Component<{task: Action}> {
    render () {
        const DetailsClass = getComponentClass(this.props.task.type)
        const task = this.props.task

        return <div className="task-details">
            { task.finished_at ?
                <div className="date">
                    {moment.utc(this.props.task.finished_at).format('DD.MM.YYYY HH:mm')}
                </div>
                :
                null
            }

            <DetailsClass task={this.props.task}/>
        </div>
    }
}

import * as React from 'react'
import {SendEmailToAirline, AppState} from "../../state/app";
import {Form, FormGroup, Label, Input} from 'reactstrap'
import TaskActions from "./TaskActions";
import {inject, observer} from "mobx-react";


@inject('appState')
@observer
export class SendEmailToAirlineDetails extends React.Component<
    {task:SendEmailToAirline, appState: AppState}
> {
    state = {
        selectedContentClass: null
    }

    render () {
        let task = this.props.task

        return <div>
            <div>Send email to airline</div>
            <a href={task.view_url} target="_blank">View message</a>

             <TaskActions task={this.props.task}/>
        </div>
    }
}
import * as React from 'react'
import {Input} from 'reactstrap'


export default class TextField extends React.Component
<{value: string, updateValue: (string) => void}>
{
    state = {
        editing: false,
        value: ''
    }

    render () {
        return <span>
            {
                this.state.editing ?
                    <Input
                        value={this.state.value}
                        onBlur={this.onAbortEdit}
                        onChange={this.onChange}
                        onKeyUp={this.onInputKeyUp}
                    />
                    :
                    <span>{this.props.value}</span>
            }
            {" "}
            <a href="#" onClick={this.onEditClick}>Edit</a>
        </span>
    }

    onEditClick = (e) => {
        e.preventDefault()
        this.setState({editing: true, value: this.props.value})
    }

    onFinishEdit = () => {
        if (this.state.value != this.props.value) {
            this.props.updateValue(this.state.value)
        }
        this.setState({editing: false, value: null})
    }

    onAbortEdit = () => {
        this.setState({editing: false, value: null})
    }

    onChange = (e) => {
        this.setState({value: e.target.value})
    }

    onInputKeyUp = (e) => {
        if (e.keyCode == 27) {
            e.preventDefault()
            this.onAbortEdit()
        }
        else if (e.keyCode == 13) {
            e.preventDefault()
            this.onFinishEdit()
        }
    }
}
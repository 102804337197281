import autobind from 'autobind-decorator'
import { ApiClient, ServerError } from '../api/client'
import { Airline } from './entities/Airline'
import { observable, observe, reaction } from 'mobx'
import { AppState } from './app'
import { BankAccount } from './entities/Payment';


@autobind
export class AirlineDetailsStore {
    parent: AppState
    apiClient: ApiClient
    @observable airline: Airline
    @observable loading: boolean = false
    @observable activeAPICalls: number = 0

    @observable dirty: boolean = false

    constructor(parent: AppState, apiClient: ApiClient) {
        this.parent = parent
        this.airline = null
        this.apiClient = apiClient
    }

    async load (id) {
        this.loading = true
        this.activeAPICalls++
        this.airline = new Airline((await this.apiClient.makeCall('GET', `/airlines/${id}`)).data)
        this.loading = false
        this.activeAPICalls--

        observe(this.airline, this.onAirlineChange)

        reaction(
            () => {
                if (!this.airline) {
                    return null
                }

                let bankAccounts = ([this.airline.bank_accounts.length] as any[]).concat(
                    this.airline.bank_accounts.map(ba => ba.name)
                ).concat(
                    this.airline.bank_accounts.map(ba => ba.account_number)
                ).concat(
                    this.airline.bank_accounts.map(ba => ba.currency)
                ).concat(
                    this.airline.bank_accounts.map(ba => ba.party)
                )

                return [bankAccounts]
            },
            this.onAirlineChange
        )
    }

    async reload() {
        await this.load(this.airline.id)
        this.dirty = false
    }

    async save() {
        this.activeAPICalls++
        let result = await this.apiClient.makeCall(
            'PUT',
            `/airlines/${this.airline.id}`,
            {data: this.airline}
        )
        this.activeAPICalls--
        await this.reload()
    }

    onAirlineChange(change) {
        this.dirty = true
    }

    addBankAccount(ba: BankAccount) {
        this.airline.bank_accounts.push(ba)
    }

    initBankAccount(airline: Airline) {
        return new BankAccount({
            name: 'Unnamed bank account',
            account_number: '',
            currency: 'EUR',
            party: 'airline',
            airline: airline.id
        })
    }
}
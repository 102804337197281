import * as React from 'react'
import {observer} from 'mobx-react'
import { FieldState } from 'formstate'
import { FormGroup, Label } from 'reactstrap'
import {TextInput} from './TextInput'
import {FieldFeedBack} from './FieldFeedBack'
import {CheckboxInput} from './CheckboxInput'


@observer
export class CheckboxInputGroup extends React.Component<{
    field: FieldState<boolean>,
    label: string,
    hasMarginBottom?: boolean
}> {
    render () {
        let className = this.props.hasMarginBottom ? 'form-check_mb_1' : ''
        return <FormGroup className={className} check>
            <Label>
                <CheckboxInput field={this.props.field}/>
                {this.props.label}
            </Label>
            <FieldFeedBack field={this.props.field}/>
        </FormGroup>
    }
}

import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { AppState } from "../../state/app"
import ContactEmailField from "./ContactEmail"
import SavePrompt from "./SavePrompt"
import { BankAccountModal } from "./BankAccountModal"
import autobind from "autobind-decorator"
import { UIState } from "../../state/ui"
import {
    Button, Input, Card, CardText, UncontrolledButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { BankAccount } from '../../state/entities/Payment'
import Field from '../case-details/Field'

import './AirlineDetails.scss'


interface AirlineDetailsProps {
    appState: AppState,
    uiState: UIState,
    match: {
        params: {
            id: string
        },
        path: string
        url: string
    }
}


@inject('uiState', 'appState')
@observer
export class AirlineDetails extends React.Component<AirlineDetailsProps> {

    componentDidMount () {
        this.props.appState.airlineDetailsStore.load(this.props.match.params.id)
    }

    @autobind
    onAddBankAccountClick () {
        let newBa = this.props.appState.airlineDetailsStore.initBankAccount(
            this.props.appState.airlineDetailsStore.airline
        )
        this.props.uiState.showModal(<BankAccountModal bankAccount={newBa} create={true} />)
    }

    render () {
        const airline = this.props.appState.airlineDetailsStore.airline

        if (!airline) {
            return <div>
                Loading...
            </div>
        }

        let address = [
            airline.address_line1, 
            airline.address_line2, 
            airline.address_line3, 
            airline.address_line4
        ].filter(address_line => typeof address_line === 'string' && address_line.length > 0).join(", ")

        return <div className="airline-details">

            <h1 className="title-main">
                Airline {airline.name} {" "}
            </h1>

            <div className="iata-code mb-2 mt-1">
                <span className="label">IATA code: </span>
                <span>{airline.iata_code}</span>
            </div>

            <div className="icao-code mb-2">
                <span className="label">ICAO code: </span>
                <span>{airline.icao_code}</span>
            </div>

            <div className="contact-email mb-2">
                <span className="label">Contact email: </span>
                <ContactEmailField airline={airline}/>
            </div>

            <div className="address mb-2">
                <span className="label">Address: </span>
                { address ?
                    <span>{address}</span>
                    :
                    <span>-</span>
                }
            </div>

            <div className="accounts mb-2">
                <BankAccountCards bankAccounts={airline.bank_accounts} />
                <Button className='add-trip-segment mb-3' onClick={this.onAddBankAccountClick}>
                    Add bank account
                </Button>
            </div>

            { this.props.appState.airlineDetailsStore.dirty ? <SavePrompt/> : null }
        </div>
    }
}

class BankAccountCards extends React.Component<{bankAccounts: BankAccount[], uiState?:UIState}> {
    render () {
        const bankAccounts = this.props.bankAccounts

        return <div>
            <h3 className="mt-4 mb-3">Bank accounts</h3>

            {bankAccounts.map((ba, key) => {
                return (
                    <Card body className="mb-4" style={{ width: '36rem' }} key={key}>
                        <CardText tag="div">
                            <Field label={'Bank account #'.concat(String(key+1))}>
                                <span>
                                    {ba.name} ({ba.account_number})
                                </span>
                                {" "}
                                <span>{ba.currency}</span>
                                {" - "}
                                <span>{ba.party}</span>
                            </Field>
                            <BankAccountActionMenu bankAccount={ba} position={key} />
                        </CardText>
                    </Card>
                )
            })}
        </div>
    }
}

interface BankAccountActionMenuProps {
    uiState?: UIState,
    appState?: AppState,
    bankAccount: BankAccount,
    position: number
}

@inject('uiState', 'appState')
@observer
class BankAccountActionMenu extends React.Component<BankAccountActionMenuProps>
{
    @autobind
    onRemoveClick (e, bankAccount: BankAccount) {
        e.preventDefault()
        let adStore = this.props.appState.airlineDetailsStore
        adStore.airline.bank_accounts.splice(adStore.airline.bank_accounts.indexOf(bankAccount), 1)
    }

    @autobind
    onEditClick () {
        this.props.uiState.showModal(
            <BankAccountModal bankAccount={this.props.bankAccount}/>
        )
    }

    render () {
        let ts = this.props.bankAccount

        return <UncontrolledButtonDropdown>
            <DropdownToggle caret>Action</DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={this.onEditClick}>Edit</DropdownItem>
                { this.props.position > 0 ?
                    <DropdownItem onClick={(e) => this.onRemoveClick(e, ts)}>
                        Remove
                    </DropdownItem>
                    :
                    null
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    }
}

import * as React from "react"
import * as moment from 'moment'
import {AppState, Action, TaskStatus, ActionType} from "../../state/app"
import {inject, observer} from 'mobx-react'
import TaskActions from "./TaskActions"
import TaskNotices from "./TaskNotices";
import {ActionAssigneeIcon} from './ActionAssigneeIcon'

import './GenericTaskDetails.scss'


function getTaskLabel (taskType: ActionType) {
    switch (taskType) {
        case ActionType.REVIEW_NEW_CASE:
            return "Review new case details"

        case ActionType.PREPARE_DEMAND_LETTER:
            return "Prepare initial demand letter"

        case ActionType.SEND_DEMAND_LETTER:
            return "Send initial demand letter"

        case ActionType.WAIT_DEMAND_LETTER_RESPONSE:
            return "Wait for airline response to claim notice"

        case ActionType.PREPARE_CLIENT_AGREEMENT:
            return "Prepare client agreement"

        case ActionType.SEND_CLIENT_AGREEMENT:
            return "Request client agreement signing"

        case ActionType.WAIT_CLIENT_AGREEMENT_SIGNATURE:
            return "Wait for client agreement to be signed"

        case ActionType.PROCESS_AIRLINE_EMAIL:
            return "Process email from airline"

        case ActionType.CHECK_FLIGHT_DATA:
            return "Check flight data"

        case ActionType.SEND_EMAIL_TO_AIRLINE:
            return "Send email to airline"

        case ActionType.PAYMENT_DETAILS_REQUEST:
            return "Requested payment details"

        case ActionType.REQUEST_CLAIM_DOCUMENT_SIGNATURE:
            return "Requested claim document signature"

        case ActionType.MANUAL_STATUS_CHANGE:
            return "Set claim status manually"

        case ActionType.MANUAL_COMPENSATION_CHANGE:
            return "Set compensation amount manually"

    }
}


@inject('appState')
@observer
export default class GenericTaskDetails extends React.Component<{task: Action, appState?: AppState}> {
    render () {
        const task = this.props.task

        if (!task) {
            return <div>Loading...</div>
        }

        return <div className="generic-task-details">
            <ActionAssigneeIcon user={task.assignee}/>
            {getTaskLabel(task.type)}
            {this.renderDueAt()}
            <TaskNotices task={task}/>
            <TaskActions task={task}/>
        </div>;
    }

    renderDueAt() {
        const task = this.props.task

        if (task.status != TaskStatus.PENDING || !task.due_at) {
            return null
        }

        let now = moment.utc()
        let dueAt = moment.utc(task.due_at)
        let isDue = dueAt.isBefore(now)
        let humanDelta = dueAt.from(now, true)

        let dueText = `Due in ${humanDelta} (${dueAt.format('DD.MM.YYYY')})`
        if (isDue) {
            dueText = `${humanDelta} overdue (${dueAt.format('DD.MM.YYYY')})`
        }

        return <div className={ isDue ? 'due-time is-due text-danger' : 'due-time' }>
            {dueText}
        </div>
    }
}

import * as React from 'react'
import {FlightDataCheckTask, Action, TaskStatus} from "../../state/app";
import TaskActions from "./TaskActions";


export default class FlightDataCheckDetails extends React.Component<{task:FlightDataCheckTask}> {
    render () {
        let task = this.props.task
        let check = this.props.task.check

        if (task.status == TaskStatus.PENDING) {
            return <div>
                Flight data check
                <TaskActions task={task}/>
            </div>
        } else if (!check) {
            return <div>Flight data check failed</div>
        }

        return <div>
            <span>Check flight data</span>
            <div>
                Schedule check: {check.schedule_check_done ? "Done" : "Not done"}
            </div>
            <div>
                Flight ID: {check.get_flight_id_done ? "Done" : "Not done"}
            </div>
            <div>
                Flight details: {check.get_flight_details_done ? "Done" : "Not done"}
            </div>
            <div>
                Scheduled flight found: {check.schedule_found ? "Yes" : "No"}
            </div>
            <div>
                Actual flight found: {check.flight_found ? "Yes" : "No"}
            </div>
            { check.cancelled ?
                <div>Flight cancelled</div>
                :
                <div>
                    <div>Departure delay (seconds): {check.departure_delay}</div>
                    <div>Arrival delay (seconds): {check.arrival_delay}</div>
                </div>
            }
        </div>
    }
}

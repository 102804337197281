import * as React from 'react'
import {observer} from 'mobx-react'
import {FieldState} from 'formstate'
import {FormFeedback} from 'reactstrap'


@observer
export class FieldFeedBack extends React.Component<{ field: FieldState<any> }> {
    render() {
        return <FormFeedback className={this.props.field.error ? 'd-block' : null}>
            {this.props.field.error}
        </FormFeedback>
    }
}

import autobind from 'autobind-decorator'
import {ApiClient} from '../api/client'
import {observable} from 'mobx'
import {FieldState, FormState} from 'formstate'
import {Claim} from './entities/Claim'
import {Party} from './entities/Common'
import {EmailAttachment, EmailMessage} from './entities/EmailMessage'
import {AirlineEmailContentClass, AppState} from './app'
import {required, validateEmail} from '../util/validation'


function initFormState () {
    return new FormState({
        toAddress: new FieldState('').validators(required(), validateEmail),
        ccAddress: new FieldState('').validators(validateEmail),
        subject: new FieldState('').validators(required()),
        body: new FieldState('').validators(required()),
    })
}

@autobind
export class EmailComposerStore {
    apiClient: ApiClient
    @observable loading: boolean = false
    @observable changed: boolean = false

    claim: Claim
    draftId: number = null

    toParty: Party
    @observable replyToMessage: EmailMessage
    @observable attachments: EmailAttachment[]

    @observable formState = initFormState()

    constructor(apiClient) {
        this.apiClient = apiClient
    }

    newMessage(claim: Claim, toParty: Party) {
        this.clear()

        this.claim = claim
        this.toParty = toParty

        this.formState.$.toAddress.onChange(this.getToAddress(claim, toParty))

        this.formState.$.subject.onChange(this.makeSubject(claim, toParty))
    }

    initReply(claim: Claim, replyToMessage: EmailMessage) {
        this.clear()

        let toAddress = replyToMessage.from_address
        if (replyToMessage.from_party == Party.SERVICE) {
            toAddress = replyToMessage.to_address
        }

        this.claim = claim
        this.replyToMessage = replyToMessage
        this.toParty = replyToMessage.from_party
        this.formState.$.subject.onChange(this.makeReplySubject(replyToMessage))
        this.formState.$.toAddress.onChange(toAddress)
    }

    initForward(claim: Claim, forwardMessage: EmailMessage) {
        this.clear()
        this.claim = claim
        this.formState.$.body.onChange(
            `
            ---------- Forwarded message ---------
            From: ${forwardMessage.from_address}
            To: ${forwardMessage.to_address}
            
            ${forwardMessage.last_reply_content}
            `
        )
        this.toParty = forwardMessage.from_party
        this.formState.$.subject.onChange(`Fwd: ${forwardMessage.subject}`)
    }

    makeSubject(claim: Claim, toParty: Party) {
        switch (toParty) {
            case Party.CLIENT:
                return `Compensation claim ${claim.case_id_human}`

            case Party.AIRLINE:
                return `Passenger’s Claim No. ${claim.case_id_human}`

            default:
                return ''
        }
    }

    makeReplySubject(replyToMessage: EmailMessage) {
        if (!replyToMessage.subject.toLowerCase().match(/^\s*re:/)) {
            return `Re: ${replyToMessage.subject}`
        }

        return replyToMessage.subject
    }

    clear() {
        this.claim = null
        this.replyToMessage = null
        this.toParty = null
        this.attachments = []
        this.draftId = null
        this.changed = false
        this.formState = initFormState()
    }

    async loadDraft(claim, draftId) {
        this.clear()

        this.loading = true
        let response = await this.apiClient.makeCall('GET', `/drafts/${draftId}`)
        this.loading = false

        let data: EmailMessage = response.data as any

        this.draftId = data.id
        this.toParty = data.to_party
        if (!data.to_address) {
            this.formState.$.toAddress.onChange(this.getToAddress(claim, this.toParty))
        } else {
            this.formState.$.toAddress.onChange(data.to_address)
        }
        this.formState.$.ccAddress.onChange(data.cc_address)
        this.formState.$.subject.onChange(data.subject)
        this.formState.$.body.onChange(data.text)
        this.replyToMessage = data.reply_to_message
        this.attachments = data.attachments

        this.claim = claim
    }

    async saveDraft() {
        let method = 'POST'
        let url = '/drafts'
        let data = {
            claim: this.claim.id,
            subject: this.formState.$.subject.value,
            text: this.formState.$.body.value,
            to_party: this.toParty,
            to_address: this.formState.$.toAddress.value,
            cc_address: this.formState.$.ccAddress.value || null,
            attachments: this.attachments,
            reply_to_message: undefined,
        }
        
        if (this.draftId) {
            method = 'PUT'
            url = `/drafts/${this.draftId}`
        }

        if (!this.draftId && this.replyToMessage) {
            data.reply_to_message = this.replyToMessage.id
        }

        this.loading = true
        let response = await this.apiClient.makeCall(method, url, {data})
        this.draftId = (response.data as EmailMessage).id
        this.loading = false
    }

    async send(draftId = null) {
        if (!draftId) {
            await this.saveDraft()
        }

        draftId = draftId || this.draftId

        this.loading = true
        let response = await this.apiClient.makeCall('PUT', `/drafts/${draftId}/send`)
        this.loading = false

        return response.status == 204
    }

    private getToAddress(claim: Claim, toParty: Party) {
        switch (toParty) {
            case Party.AIRLINE:
                return claim.airline.contact_email

            case Party.CLIENT:
                return claim.submitter.email
        }

        return ''
    }
}


@autobind
export class MessageListStore {
    parent: AppState
    apiClient: ApiClient
    claimId: number = null
    party: Party = null
    @observable messages: EmailMessage[] = []
    @observable loading = false

    constructor(parent, apiClient) {
        this.parent = parent
        this.apiClient = apiClient
    }

    getMessageById(id) {
        for (let m of this.messages) {
            if (m.id == id) {
                return m
            }
        }

        return null
    }

    async load(claimId, party) {
        this.claimId = claimId
        this.party = party

        this.loading = true
        this.messages = []
        const response = await this.apiClient.makeCall(
            'GET',
            `/claims/${this.claimId}/messages/${this.party}`
        )
        this.messages = (response.data as object[]).map(c => new EmailMessage(c))
        this.loading = false
    }

    reload() {
        console.log("Reloading message list")
        return this.load(this.claimId, this.party)
    }

    async toggleRead(msg) {
        let newRead = !msg.read

        let response = await this.apiClient.makeCall(
            'PUT',
            `/messages/${msg.id}/read`,
            {data: {read: newRead}}
        )

        if (response.status == 204) {
            msg.read = newRead
        }
    }

    async classify(msg, classification: AirlineEmailContentClass) {
        await this.apiClient.makeCall(
            'PUT',
            `/messages/${msg.id}/classification`,
            {data: {content_class: classification}}
        )

        await this.parent.claimDetailsStore.reload()
        await this.parent.claimStatusCounts.reload()
    }
}

import {observable} from "mobx";
import {Entity} from "./Entity";
import {AirlineEmailContentClass, Document} from "../app";
import {Party} from './Common'


export class EmailAttachment extends Entity {
    document: Document
}

export class EmailMessage extends Entity {
    created_at: Date
    from_party: Party
    to_party: Party
    from_address: string
    to_address: string
    cc_address?: string
    view_url: string
    body_url: string
    subject: string
    text: string
    last_reply_content: string
    @observable read: boolean
    @observable draft: boolean
    reply_to_message: EmailMessage
    attachments: EmailAttachment[]
    content_class: AirlineEmailContentClass|null
    auto_sent: boolean

    constructor (data) {
        super(data)

        this.parseDates(data, ['created_at'])
    }
}

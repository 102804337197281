import * as React from 'react'
import {observer} from 'mobx-react'
import {Input} from 'reactstrap'
import {FieldState} from 'formstate'


interface Props {
    field: FieldState<string>
    autoFocus?: boolean
    type?: 'text' | 'email' | 'password' | 'search'
    name?: string
    placeholder?: string
}


@observer
export class TextInput extends React.Component<Props> {
    render () {
        return <Input
            type={this.props.type || 'text'}
            value={this.props.field.value || ''}
            onChange={(e) => this.props.field.onChange(e.target.value)}
            autoFocus={this.props.autoFocus}
            name={this.props.name}
            placeholder={this.props.placeholder}
        />
    }
}

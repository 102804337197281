import * as React from 'react'
import autobind from 'autobind-decorator'
import {FormState, FieldState} from 'formstate'
import {inject, observer} from 'mobx-react'
import {AppState} from "../../state/app"
import {FormGroup, Label, Input} from 'reactstrap'
import {Modal} from '../common/Modal'
import {required} from '../../util/validation'
import {FieldFeedBack} from '../common/FieldFeedBack'
import {CheckboxInputGroup} from '../common/CheckboxInputGroup'
import {Claim as Case} from '../../state/entities/Claim'
import './ManualCompensationChangeModal.scss'

type CompensationModalFields = {
    amountPerPassenger: FieldState<string>
    comment: FieldState<string>
    isAutoAmount: FieldState<boolean>
}

class CompensationModalFormState extends FormState<CompensationModalFields> {
    constructor (claim: Case) {

        function validateAmount (amount: string) {
            var regex  = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/
            if (!regex.test(amount)) {
                return 'Amount is not correct'
            }
        }

        super(
            {
                amountPerPassenger: new FieldState<string>(null).validators(
                    required(`Please enter the compensation amount`),
                    validateAmount
                ),
                comment: new FieldState<string>(null).validators(
                    required(`Please enter the comment`)
                ),
                isAutoAmount: new FieldState<boolean>(claim.is_auto_amount).validators(),
            }
        )
    }
}
@inject('appState')
@observer
export class ManualCompensationChangeModal extends React.Component<{appState: AppState}> {
    state = {
        isDropdownOpen: false,
    }

    formState = new CompensationModalFormState(
        this.props.appState.claimDetailsStore.claim
    )

    render () {
        let fields = this.formState.$

        return <Modal 
            title={'Manual compensation change'} 
            actionLabel="Save"
            onAction={this.manualCompensationChange}
        >
            <CheckboxInputGroup
                field={fields.isAutoAmount}
                label="Calculate compensation automatically"
                hasMarginBottom={true}
            />
            <FormGroup>
                <Label for="amount">Compensation amount per passenger</Label>
                <Input 
                    type="text"
                    disabled={fields.isAutoAmount.value}
                    invalid={!!fields.amountPerPassenger.error}
                    value={fields.amountPerPassenger.value || ''}
                    onChange={(e) => fields.amountPerPassenger.onChange(
                        e.target.value
                    )}
                    onBlur={fields.amountPerPassenger.validate}
                />
                <FieldFeedBack field={fields.amountPerPassenger}/>
            </FormGroup>
            <FormGroup>
                <Label for="comment">Reason for compensation change</Label>
                <Input
                    type="textarea"
                    disabled={fields.isAutoAmount.value}
                    invalid={!!fields.comment.error}
                    value={fields.comment.value || ''}
                    onChange={(e) => fields.comment.onChange(
                        e.target.value
                    )}
                    onBlur={fields.comment.validate}
                />
                <FieldFeedBack field={fields.comment}/>
            </FormGroup>
        </Modal>
    }
    
    dropdownToggle () {
        this.setState({isDropdownOpen: !this.state.isDropdownOpen})
    }
    
    @autobind
    async manualCompensationChange () {
        let result = await this.formState.validate()

        if (result.hasError) {
            return false
        }

        await this.props.appState.claimDetailsStore.manualCompensationChange({
            amountPerPassenger: this.formState.$.amountPerPassenger.value,
            comment: this.formState.$.comment.value,
            isAutoAmount: this.formState.$.isAutoAmount.value,
        })

        return true
    }
}
